/* eslint-disable react-hooks/exhaustive-deps */
import * as React from "react";
import Divider from "@mui/material/Divider";
import Stack from "@mui/material/Stack";
import { IoOpen } from "react-icons/io5";
import Button from "@mui/material/Button";

import {
  getFirestore,
  doc,
  updateDoc,
  arrayUnion,
  arrayRemove,
  increment,
  deleteDoc,
  Timestamp,
} from "firebase/firestore";
import { IconButton, Typography } from "@mui/material";
import { DeleteForever } from "@mui/icons-material";
import { HiPlusCircle } from "react-icons/hi2";
import NewNote from "./new_note";
import { styled } from "@mui/material/styles";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { ClickAwayListener } from "@mui/base";

const HtmlTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    background: "white",
    maxWidth: 500,
    width: 350,
    fontSize: theme.typography.pxToRem(12),
    boxShadow: "5px 5px 24px 1px rgba(0, 0, 0, 0.25)",
    color: "black",
    padding: 16,
    borderRadius: 12,
  },
}));

export default function UserNotes({ user }) {
  const db = getFirestore();

  const [saving, setSaving] = React.useState(false);

  const [note, setNote] = React.useState("");

  const [openTooltip, setOpenTooltip] = React.useState({
    item: null,
  });

  const addNote = async () => {
    setSaving(true);
    let admin = JSON.parse(window.localStorage.getItem("user"));
    const docRef = doc(db, "clients", user.userID);
    await updateDoc(docRef, {
      notes: arrayUnion({
        content: note,
        writer:
          (admin.firstName || "Unkown ") + " " + (admin.lastName || "Admin"),
        date: new Date().toDateString(),
        time: Timestamp.fromDate(new Date()),
      }),
    });
    setNote("");
    setSaving(false);
  };

  const deleteNote = async (note) => {
    const docRef = doc(db, "clients", user.userID);
    await updateDoc(docRef, {
      notes: arrayRemove(note),
    });
  };

  return (
    <Stack sx={{}}>
      <Stack
        sx={{ alignItems: "center", justifyContent: "space-between" }}
        direction="row"
      >
        <Stack>
          <h4 style={{ margin: 0 }}>Client Notes</h4>
          {(!user.notes || (user.notes && user.notes.length === 0)) && (
            <Typography variant="caption">Note list is empty</Typography>
          )}
        </Stack>
        <ClickAwayListener
          onClickAway={() => {
            if (openTooltip.item === "new_note") setOpenTooltip({ item: null });
          }}
        >
          <div>
            <HtmlTooltip
              placement="bottom-start"
              open={openTooltip.item === "new_note"}
              title={
                <NewNote
                  handleTooltipClose={() => {
                    setOpenTooltip({ item: null });
                  }}
                  userID={user.userID}
                />
              }
            >
              <IconButton
                onClick={() => {
                  setOpenTooltip({ item: "new_note" });
                }}
                color="info"
              >
                <HiPlusCircle />
              </IconButton>
            </HtmlTooltip>
          </div>
        </ClickAwayListener>
      </Stack>
      <Stack sx={{ mt: 2, flexWrap: "wrap" }} direction="row">
        {(user.notes || []).map((note, index) => {
          return (
            <Stack
              sx={{
                background: "rgb(255, 159, 14, 0.1)",
                borderRadius: 2,
                p: 2,
                mr: 1,
              }}
              key={index.toString()}
            >
              <p style={{ fontSize: 15, maxWidth: 220, marginBottom: 8 }}>
                {note.content}
              </p>
              <p style={{ maxWidth: 200, fontWeight: "100", fontSize: 14 }}>
                {"By " + note.writer + " on " + note.date}
              </p>
              <IconButton
                sx={{ mt: 1, alignSelf: "flex-start" }}
                onClick={() => {
                  deleteNote(note);
                }}
              >
                <DeleteForever size={14} color="red" />
              </IconButton>
            </Stack>
          );
        })}
      </Stack>

      <Divider sx={{ mt: 2, mb: 2, opacity: 0.3 }} />
    </Stack>
  );
}
