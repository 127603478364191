import * as React from "react";
import Chip from "@mui/material/Chip";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { HiCamera } from "react-icons/hi2";
import { FaFileAudio } from "react-icons/fa";
import { MdReply } from "react-icons/md";
import { SiAdobeacrobatreader } from "react-icons/si";
import { truncateMessage } from "../../../../../utils/functions";
import { constants } from "../../../../../config/constants";

const TextMsg = ({ txt }) => {
  return (
    <Typography
      sx={{
        opacity: 0.7,
        fontSize: 15,
        display: "-webkit-box",
        overflow: "hidden",
        WebkitBoxOrient: "vertical",
        WebkitLineClamp: 1,
        mr: "10px",
        textAlign: "left",
        flex: 1,
      }}
      variant="caption"
    >
      {truncateMessage(txt)}
    </Typography>
  );
};

const AudioMsg = ({}) => {
  return (
    <Stack sx={{ alignItems: "center", flex: 1 }} direction="row">
      <FaFileAudio style={{ opacity: 0.5 }} size={14} />
      <Typography
        sx={{
          fontSize: 14,
          display: "-webkit-box",
          overflow: "hidden",
          WebkitBoxOrient: "vertical",
          WebkitLineClamp: 1,
          mr: "10px",
          textAlign: "left",
          opacity: 0.6,
          ml: 1,
        }}
        variant="caption"
      >
        Audio
      </Typography>
    </Stack>
  );
};

const UnreadCount = ({ unread }) => {
  return (
    <div
      style={{
        background: "rgba(11, 42, 71, 0.64)",
        color: "white",
        alignSelf: "flex-end",
        height: 20,
        width: 20,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        borderRadius: 10,
      }}
    >
      <p style={{ fontSize: 12 }}>{unread}</p>
    </div>
  );
};

export default function MessageContent({ message, sender }) {
  let whatsapp = message;
  let email = message.email;
  let messenger = message.messenger;
  let comment = message.comment;

  return (
    <Stack
      direction="row"
      sx={{
        width: "100%",
        alignItems: "center",
      }}
    >
      {whatsapp && (
        <Stack sx={{ alignItems: "center", flex: 1 }} direction="row">
          <Stack sx={{ alignItems: "center", flex: 1 }} direction="row">
            {constants.adminPhones.includes(sender) && (
              <MdReply size={12} style={{ marginRight: 8, opacity: 0.5 }} />
            )}
            <TextMsg txt={message.message[0].content[0].text.value} />
            {whatsapp.type === "document" && (
              <Stack sx={{ alignItems: "center", flex: 1 }} direction="row">
                <SiAdobeacrobatreader
                  style={{ opacity: 0.7, marginRight: 8 }}
                />
                <TextMsg txt={whatsapp.document.filename} />
              </Stack>
            )}
            {whatsapp.type === "audio" && <AudioMsg />}
            {whatsapp.type === "image" && (
              <Stack sx={{ alignItems: "center" }} direction="row">
                <HiCamera style={{ opacity: 0.6, marginRight: 8 }} size={15} />
                {whatsapp.image.caption && whatsapp.image.caption !== "" ? (
                  <TextMsg txt={whatsapp.image.caption} />
                ) : (
                  <TextMsg txt={"Image"} />
                )}
              </Stack>
            )}
          </Stack>
          {message.unread > 0 && <UnreadCount unread={message.unread} />}
        </Stack>
      )}

      {messenger && (messenger.recipient || messenger.sender) && (
        <Stack sx={{ alignItems: "center", flex: 1 }} direction="row">
          <Stack sx={{ alignItems: "center", flex: 1 }} direction="row">
            {messenger.sender &&
              constants.adminPageIDs.includes(messenger.sender.id) && (
                <MdReply size={12} style={{ marginRight: 8, opacity: 0.5 }} />
              )}
            {messenger.message && messenger.message.text && (
              <TextMsg txt={messenger.message.text} />
            )}
            {messenger.message && messenger.message.attachments.length > 0 && (
              <>
                {messenger.message.attachments[0].type !== "image" && (
                  <Stack sx={{ alignItems: "center", flex: 1 }} direction="row">
                    <SiAdobeacrobatreader
                      style={{ opacity: 0.7, marginRight: 8 }}
                    />
                    <TextMsg txt="Document" />
                  </Stack>
                )}
                {messenger.message.attachments[0].type === "image" && (
                  <Stack sx={{ alignItems: "center" }} direction="row">
                    <HiCamera
                      style={{ opacity: 0.6, marginRight: 8 }}
                      size={15}
                    />
                    <TextMsg txt={"Image"} />
                  </Stack>
                )}
              </>
            )}
          </Stack>
          {message.unread > 0 && <UnreadCount unread={message.unread} />}
        </Stack>
      )}
      {comment && (
        <Stack direction="row" sx={{ alignItems: "center", width: "100%" }}>
          {comment.from && constants.adminPageIDs.includes(comment.from.id) && (
            <MdReply size={12} style={{ marginRight: 8, opacity: 0.5 }} />
          )}
          <TextMsg txt={comment.message} />
          {message.read === false &&
            comment.from &&
            !constants.adminPageIDs.includes(comment.from.id) && (
              <div
                style={{
                  height: 8,
                  width: 8,
                  borderRadius: 4,
                  backgroundColor: "orange",
                  marginRight: 2,
                }}
              />
            )}
        </Stack>
      )}
      {email && (
        <Stack direction="row" sx={{ alignItems: "center", width: "100%" }}>
          {sender === "admin" && (
            <MdReply size={12} style={{ marginRight: 8, opacity: 0.5 }} />
          )}
          <TextMsg txt={email.subject} />
          {message.read === false && sender !== "admin" && (
            <div
              style={{
                height: 8,
                width: 8,
                borderRadius: 4,
                backgroundColor: "orange",
              }}
            />
          )}
        </Stack>
      )}
    </Stack>
  );
}
