const constants = {
  apiUrl: "https://app.swiftly.africa/api",
  adminPhones: ["15550282933", "27658932664"],
  adminEmails: [
    "admin@drivetoown.co.za",
    "mailing@drivetoown.co.za",
    "sales@drivetoown.co.za",
  ],
  adminPageIDs: ["114370986869351"],
  socketIp: "server.drivetoown.co.za",
};
//,
export { constants };
