import Stack from "@mui/material/Stack";
import { Typography } from "@mui/material";
import * as React from "react";

export default function TimeComponent({ date, senderType }) {
  // Convert Unix timestamp to Date object
  const dt = new Date(date * 1000);

  // Adjust for SAST (UTC+2) by considering timezone offset
  const localDate = new Date(dt.getTime()); 

  let time = localDate.getHours().toString() + ":";
  time += localDate.getMinutes().toString().length < 2 ? "0" + localDate.getMinutes().toString() : localDate.getMinutes().toString();

  // Get current time and calculate yesterday's date
  const now = new Date();
  const yesterday = new Date();
  yesterday.setDate(now.getDate() - 1); // Adjusts 'yesterday' to one day before today

  // Compare date part only
  const isToday = now.toDateString() === localDate.toDateString();
  const isYesterday = yesterday.toDateString() === localDate.toDateString();

  const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
  const days = ["Sat","Sun", "Mon", "Tue", "Wed", "Thu", "Fri"];

  return (
    <Stack sx={{ alignSelf: senderType === "admin" ? "flex-end" : "flex-start" }}>
      {isToday && (
        <Typography sx={{ mb: 0.5 }} variant="caption">
          {time}
        </Typography>
      )}
      {isYesterday && (
        <Typography sx={{ mb: 0.5 }} variant="caption">
          Yesterday at {time}
        </Typography>
      )}
      {!isToday && !isYesterday && (
        <Typography sx={{ mb: 0.5 }} variant="caption">
          {days[localDate.getDay()]} {months[localDate.getMonth()]} {localDate.getDate()}, {time}
        </Typography>
      )}
      {localDate.getFullYear() !== now.getFullYear() && (
        <Typography sx={{ mb: 0.5 }} variant="caption">
          {localDate.toDateString()}
        </Typography>
      )}
    </Stack>
  );
}
