import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import working from "../../../../../assets/illustrations/data-points.svg";
import React from "react";
import { styled } from "@mui/material/styles";
const Widget = styled(Stack)(({ theme }) => ({
  padding: 16,
  borderRadius: 16,
  zIndex: 1,
  backgroundColor:
    theme.palette.mode === "dark" ? "rgba(0,0,0,0.7)" : "rgba(255,255,255,0.6)",
  backdropFilter: "blur(40px)",
  marginTop: 64,
}));

const Sidebar = () => {
  return (
    <Stack
      sx={{
        background: "rgb(0, 196, 80)",

        p: 4,
        width: "300px",
        overflow: "hidden",
        background:
          "linear-gradient(rgb(26, 145, 53) 0%, rgb(0, 232, 185) 100%)",
        transition: "all 500ms cubic-bezier(0.175, 0.885, 0.32, 1.275) 0s",
        m: 2,
        borderRadius: 3,
      }}
    >
      <Typography sx={{ color: "white" }}>Wiftli.</Typography>
      <Typography
        variant="h3"
        sx={{
          maxWidth: "400px",
          color: "white",
          mt: 8,
        }}
      >
        Start your <br />
        journey with us.
      </Typography>

      <Typography
        sx={{
          color: "white",
          mt: 2,
          mb: 3,
        }}
      >
        We help businesses like yours transform the way procurement processes
        are managed. Setup your account today to get started.
      </Typography>
      {false && (
        <Widget>
          <Typography>
            Wiftli helped my business reduce approval time from 20 days to only
            6 hours!
          </Typography>
        </Widget>
      )}
      <img style={{ height: "30vh" }} src={working} alt="illustration" />
    </Stack>
  );
};
export default Sidebar;
