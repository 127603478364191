/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import Stack from "@mui/material/Stack";
import Chip from "@mui/material/Chip";
import Grow from "@mui/material/Grow";
import Divider from "@mui/material/Divider";
import { MdCancel } from "react-icons/md";

const HeaderCC = ({ setCc }) => {
  const checked = true;
  const [store, setStore] = React.useState({
    items: [],
    value: "",
    error: null,
  });

  React.useEffect(() => {
    setCc([...store.items]);
  }, [store.items]);

  const handleKeyDown = (evt) => {
    if (["Enter", "Tab", ","].includes(evt.key)) {
      evt.preventDefault();

      var value = store.value.trim();

      if (value && isValid(value)) {
        setStore((store) => ({
          ...store,
          value: "",
          items: [...store.items, store.value],
        }));
      }
    }
  };

  const handleChange = (evt) => {
    setStore((store) => ({
      ...store,
      value: evt.target.value,
      error: null,
    }));
  };

  const handleDelete = (item) => {
    setStore((store) => ({
      ...store,
      items: store.items.filter((i) => i !== item),
    }));
  };

  const handlePaste = (evt) => {
    evt.preventDefault();

    var paste = evt.clipboardData.getData("text");
    var emails = paste.match(/[\w\d\.-]+@[\w\d\.-]+\.[\w\d\.-]+/g);

    if (emails) {
      var toBeAdded = emails.filter((email) => !isInList(email));

      setStore((store) => ({
        ...store,
        items: [...store.items, ...toBeAdded],
      }));
    }
  };

  function isValid(email) {
    let error = null;

    if (isInList(email)) {
      error = `${email} has already been added.`;
    }

    if (!isEmail(email)) {
      error = `${email} is not a valid email address.`;
    }

    if (error) {
      setStore((store) => ({
        ...store,
        error,
      }));

      return false;
    }

    return true;
  }

  function isInList(email) {
    return store.items.includes(email);
  }

  function isEmail(email) {
    return /[\w\d.-]+@[\w\d.-]+\.[\w\d.-]+/.test(email);
  }

  return (
    <>
      <Stack
        sx={{
          // boxShadow: "5px 5px 24px 1px rgba(0,0, 0, 0.04)",
          pr: 2,
          borderRadius: 1,
          pl: 2,
          pt: 1,
          pb: 1,
        }}
      >
        <Stack sx={{}} direction="row">
          <p
            style={{
              margin: 0,
              marginRight: 8,
              marginTop: store.items.length > 0 ? 3 : 0,
            }}
          >
            Cc:{" "}
          </p>
          <Stack direction="row" sx={{ flexWrap: "wrap", flex: 1 }}>
            {store.items.map((item) => (
              <Grow in={checked}>
                <Chip
                  sx={{ mr: 1, background: "black", color: "white", mb: 0.5 }}
                  deleteIcon={<MdCancel color="white" />}
                  key={item}
                  onDelete={() => {
                    handleDelete(item);
                  }}
                  label={item}
                />
              </Grow>
            ))}

            <input
              style={{ outline: "none", border: "0px", flex: 1 }}
              className={"input " + (store.error && " has-error")}
              value={store.value}
              placeholder=""
              onKeyDown={handleKeyDown}
              onChange={handleChange}
              onPaste={handlePaste}
            />
          </Stack>
        </Stack>
        <Divider sx={{ mt: 1, mb: 2, opacity: 0 }} />
      </Stack>

      {store.error && <p className="error">{store.error}</p>}
    </>
  );
};

export { HeaderCC };
