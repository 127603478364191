import Stack from "@mui/material/Stack";
import { Typography } from "@mui/material";
import * as React from "react";
import { BsDownload } from "react-icons/bs";
import Skeleton from "@mui/material/Skeleton";
import IconButton from "@mui/material/IconButton";
import { constants } from "../../../../../../config/constants";

import Zoom from "react-medium-image-zoom";
import "react-medium-image-zoom/dist/styles.css";

export default function ImageComponent({
  message,
  senderType,
  image = { caption: null },
  filePath,
  url,
}) {
  const style = {
    background: senderType === "admin" ? "orange" : "white",
    borderRadius: 3,
    borderTopLeftRadius: senderType === "client" ? 0 : null,
    borderTopRightRadius: senderType === "client" ? null : 0,
    boxShadow: "5px 5px 24px 1px rgba(0, 0, 0, 0.01)",
    color: senderType === "admin" ? "white" : "black",
  };

  const [loaded, setLoaded] = React.useState(false);
  let img_link =
    senderType === "admin"
      ? url
        ? url
        : `${constants.apiUrl}/documents/${image.id}`
      : url
      ? url
      : filePath !== null
      ? `${constants.apiUrl}/documents/` + filePath
      : `${constants.apiUrl}/documents/` + image.id;
  return (
    <>
      <div
        style={{ width: loaded ? 320 : 0, display: loaded ? "block" : "none" }}
      >
        {senderType === "admin" && (
          <Stack>
            <Stack
              sx={{
                mb: 1,
                background: "white",
                boxShadow: "5px 5px 24px 1px rgba(0, 0, 0, 0.06)",
                p: 1.2,
                width: "320px",
                borderRadius: 3,
                borderTopRightRadius: 0,
              }}
            >
              <Zoom>
                <img
                  onLoad={() => {
                    setLoaded(true);
                  }}
                  style={{ width: "310px", borderRadius: "16px" }}
                  alt="Attachment"
                  src={img_link}
                />
              </Zoom>
            </Stack>

            {image.caption && image.caption !== "" && (
              <Stack sx={{ ...style, p: 2, mt: 1 }}>
                <Typography sx={{ maxWidth: "200px" }}>
                  {image.caption}
                </Typography>
              </Stack>
            )}
          </Stack>
        )}
        {senderType === "client" && (
          <Stack>
            <Stack
              sx={{
                mb: 1,
                background: "white",
                boxShadow: "5px 5px 24px 1px rgba(0, 0, 0, 0.06)",
                p: 1.2,
                width: "320px",
                borderRadius: 3,
                borderTopLeftRadius: 0,
              }}
            >
              <Zoom>
                <img
                  onLoad={() => {
                    setLoaded(true);
                  }}
                  style={{ width: "310px", borderRadius: "16px" }}
                  alt="Attachment"
                  src={img_link}
                />
              </Zoom>
            </Stack>

            {image.caption && image.caption !== "" && (
              <Stack sx={{ ...style, p: 2, mt: 1 }}>
                <Typography sx={{ maxWidth: "200px" }}>
                  {image.caption}
                </Typography>
              </Stack>
            )}
          </Stack>
        )}
      </div>
      {!loaded && (
        <Skeleton variant="rounded" sx={{}} width={320} height={160} />
      )}
      <IconButton
        size="small"
        sx={{ alignSelf: "flex-start" }}
        onClick={() => {
          fetch(img_link).then((response) => {
            response.blob().then((blob) => {
              // Creating new object of PDF file
              const fileURL = window.URL.createObjectURL(blob);

              let alink = document.createElement("a");
              alink.href = fileURL;
              alink.download = new Date().getTime().toString();
              alink.click();
            });
          });
        }}
      >
        <BsDownload size={16} />
      </IconButton>
    </>
  );
}
