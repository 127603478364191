import * as React from "react";
import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import { LinearProgress } from "@mui/material";
import Collapse from "@mui/material/Collapse";
import { HiMinusCircle, HiPlusCircle } from "react-icons/hi2";
import { IoPencil } from "react-icons/io5";
import { doc, updateDoc, getFirestore } from "firebase/firestore";
import IconButton from "@mui/material/IconButton";
import EditStartDate from "./set_start_date";
import AssignCar from "./assign_car";
import SubscriptionExpires from "./subsctiption_expires";

import { styled } from "@mui/material/styles";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { ClickAwayListener } from "@mui/base";

const HtmlTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    background: "white",
    width: 250,
    fontSize: theme.typography.pxToRem(12),
    boxShadow: "5px 5px 24px 1px rgba(0, 0, 0, 0.25)",
    color: "black",
    padding: 16,
    borderRadius: 12,
  },
}));

function days_between(date1, date2) {
  const ONE_DAY = 1000 * 60 * 60 * 24;

  const differenceMs = Math.abs(date1 - date2);

  return Math.round(differenceMs / ONE_DAY);
}

function numberWithCommas(x) {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export default function CarDetails({ user }) {
  const [last30, setLast30] = React.useState(0);

  let [car, setCar] = React.useState({
    odometer: null,
    last30: null,
  });

  const getLast30 = async () => {
    if (user.carReg === "" || user.carReg === "Unassigned") {
      setCar({
        odometer: null,
        last30: null,
      });
      return;
    }
    const url = `https://server.drivetoown.co.za/cartrack/odometer_difference/${user.carReg}`;

    try {
      const response = await fetch(url, {
        method: "get",
      });
      let d = await response.json();
      setCar((car) => ({
        ...car,
        last30: numberWithCommas(d[0].o_odo_diff / 1000),
      }));
    } catch (e) {
      console.log(e.message);
    }
  };

  const getOdometer = async () => {
    if (user.carReg === "" || user.carReg === "Unassigned") {
      setCar({
        odometer: null,
        last30: null,
      });
      return;
    }
    var requestOptions = {
      method: "GET",
      redirect: "follow",
    };

    const url = `https://server.drivetoown.co.za/cartrack/status/${user.carReg}`;

    fetch(url, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result.data != null) {
          setCar((car) => ({
            ...car,
            odometer: numberWithCommas(
              result.data.current_odometer_value / 1000
            ),
          }));
        }
      })
      .catch((error) => console.log("error", error));
  };

  const db = getFirestore();

  const [loading, setLoading] = React.useState(false);

  const unassignCar = async () => {
    if (
      // eslint-disable-next-line no-restricted-globals
      confirm(
        "Are you sure you would like to unassign the car " +
          user.car +
          " from the client?"
      ) === true
    ) {
      setLoading(true);
      const docRef = doc(db, "clients", user.userID);

      await updateDoc(docRef, {
        carReg: "Unassigned",
        car: "Unassigned",
        carActive: false,
      });
      setLoading(false);
    }
  };

  const [openTooltip, setOpenTooltip] = React.useState({
    item: null,
  });

  React.useEffect(() => {
    getLast30();
    getOdometer();
  }, [user]);

  return (
    <Stack sx={{}}>
      <Divider sx={{ mt: 3, mb: 2, opacity: 0.3 }} />
      <h4 style={{ margin: 0 }}>Car Details</h4>
      <Typography sx={{ mt: 2 }}>Car</Typography>
      <Typography sx={{ fontSize: 13 }} variant="caption">
        {user.car}
      </Typography>
      <Stack sx={{ mt: 2 }} direction="row">
        <Stack>
          <Typography>Registration</Typography>
          <Typography sx={{ fontSize: 13 }} variant="caption">
            {user.carReg}
          </Typography>
        </Stack>
        <Stack sx={{ mr: 3, ml: 3 }}>
          <Typography>Odometer</Typography>
          <Typography sx={{ fontSize: 13 }} variant="caption">
            {car.odometer && car.odometer !== "" ? car.odometer : "--"} km
          </Typography>
        </Stack>
        <Stack>
          <Typography>Last 30</Typography>
          <Typography sx={{ fontSize: 13 }} variant="caption">
            {car.last30 && car.last30 !== "" ? car.last30 : "--"} km
          </Typography>
        </Stack>
      </Stack>
      <Stack sx={{ mt: 2, alignItems: "center" }} direction="row">
        <ClickAwayListener
          onClickAway={() => {
            if (openTooltip.item === "start_date")
              setOpenTooltip({ item: null });
          }}
        >
          <div>
            <HtmlTooltip
              placement="bottom-start"
              open={openTooltip.item === "start_date"}
              title={
                <EditStartDate
                  handleTooltipClose={() => {
                    setOpenTooltip({ item: null });
                  }}
                  userID={user.userID}
                />
              }
            >
              <IconButton
                onClick={() => {
                  setOpenTooltip({ item: "start_date" });
                }}
                color="info"
                disabled={loading}
                sx={{ textTransform: "none", mr: 1 }}
              >
                <IoPencil size={15} />
              </IconButton>
            </HtmlTooltip>
          </div>
        </ClickAwayListener>
        <Stack>
          <Typography sx={{ mt: 2 }}>Start Date</Typography>
          <Typography sx={{ fontSize: 13 }} variant="caption">
            {user.startDate
              ? user.startDate.date +
                "/" +
                user.startDate.month +
                "/" +
                user.startDate.year
              : "Not set"}
          </Typography>
        </Stack>
        <Stack sx={{ mr: 3, ml: 3 }}>
          <Typography sx={{ mt: 2 }}>Start Time</Typography>
          <Typography sx={{ fontSize: 13 }} variant="caption">
            {user.startDate && user.startDate.time
              ? user.startDate.time.slice(0, 5)
              : "Not set"}
          </Typography>
        </Stack>
      </Stack>
      <Stack sx={{ mt: 2, alignItems: "center" }} direction="row">
        <ClickAwayListener
          onClickAway={() => {
            if (openTooltip.item === "sub_expires")
              setOpenTooltip({ item: null });
          }}
        >
          <div>
            <HtmlTooltip
              placement="bottom-start"
              open={openTooltip.item === "sub_expires"}
              title={
                <SubscriptionExpires
                  handleTooltipClose={() => {
                    setOpenTooltip({ item: null });
                  }}
                  userID={user.userID}
                />
              }
            >
              <IconButton
                onClick={() => {
                  setOpenTooltip({ item: "sub_expires" });
                }}
                color="info"
                disabled={loading}
                sx={{ textTransform: "none", mr: 1 }}
              >
                <IoPencil size={15} />
              </IconButton>
            </HtmlTooltip>
          </div>
        </ClickAwayListener>
        <Stack>
          <Typography sx={{ mt: 2 }}>Subscription expires</Typography>

          <Typography sx={{ fontSize: 13 }} variant="caption">
            {user.subscriptionExpires ? (
              <p
                style={{
                  color:
                    new Date(user.subscriptionExpires.date) < new Date()
                      ? "red"
                      : "black",
                }}
              >
                {new Date(user.subscriptionExpires.date) < new Date()
                  ? days_between(
                      new Date(user.subscriptionExpires.date),
                      new Date()
                    ) +
                    " days late - " +
                    "\n" +
                    new Date(user.subscriptionExpires.date).toDateString()
                  : new Date(user.subscriptionExpires.date).toDateString()}
              </p>
            ) : (
              <p style={{ color: "orange" }}>Not set</p>
            )}
          </Typography>
        </Stack>
      </Stack>

      <Collapse in={loading} sx={{ mt: 2 }}>
        <LinearProgress color="info" />
      </Collapse>
      <Stack direction="row">
        {user.carReg === "Unassigned" || unassignCar.car === "" ? (
          <ClickAwayListener
            onClickAway={() => {
              if (openTooltip.item === "assign_car")
                setOpenTooltip({ item: null });
            }}
          >
            <div>
              <HtmlTooltip
                placement="bottom-start"
                open={openTooltip.item === "assign_car"}
                title={
                  <AssignCar
                    handleTooltipClose={() => {
                      setOpenTooltip({ item: null });
                    }}
                    userID={user.userID}
                  />
                }
              >
                <Button
                  onClick={() => {
                    setOpenTooltip({ item: "assign_car" });
                  }}
                  disableElevation
                  color="info"
                  disabled={loading}
                  startIcon={<IoPencil size={14} />}
                  sx={{ textTransform: "none", mt: 2 }}
                >
                  Assign Car
                </Button>
              </HtmlTooltip>
            </div>
          </ClickAwayListener>
        ) : (
          <Button
            disableElevation
            color="info"
            onClick={() => {
              unassignCar();
            }}
            disabled={loading}
            startIcon={
              user.carReg === "Unassigned" || user.car === "" ? (
                <HiPlusCircle />
              ) : (
                <HiMinusCircle />
              )
            }
            sx={{ textTransform: "none", mt: 2, mr: 2 }}
          >
            Unassign Car
          </Button>
        )}
      </Stack>
      <Divider sx={{ mt: 2, mb: 2, opacity: 0.3 }} />
    </Stack>
  );
}
