import { constants } from "../../../../../config/constants";
import {
  doc,
  getDoc,
  getFirestore,
  onSnapshot,
  updateDoc,
} from "firebase/firestore";

export async function updateUserData(userId, updatedData) {
  const db = getFirestore();
    try {
      if (updatedData.Account_status) {
        const docRef = doc(db, 'drivers', userId, 'profile', 'profileDocument');

        await updateDoc(docRef, {
          verified: true,
          //approvedBy: `${admin.firstName} ${admin.lastName}`,
          approvedAt:
            new Date().toDateString() + " at " + new Date().toTimeString(),
        });

      }
      
      // Include userId in the request body
      const response = await fetch(`${constants.apiUrl}/update_contact`, {
        method: 'POST', // HTTP method for updating data
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer YOUR_AUTH_TOKEN`, // Optional: Add if you use authentication
        },
        body: JSON.stringify({
          userId, // Add userId to the request body
          ...updatedData // Spread the updated data
        }),
      });
  
      if (!response.ok) {
        throw new Error('Failed to update user data');
      }
  
      const result = await response.json();
      return result;
    } catch (error) {
      console.error('Error updating user data:', error);
      throw error;
    }
  }
  
  