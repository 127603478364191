/* eslint-disable react-hooks/exhaustive-deps */
import * as React from "react";
import Stack from "@mui/material/Stack";
import LinearProgress from "@mui/material/LinearProgress";
import Button from "@mui/material/Button";
import { Divider, Typography } from "@mui/material";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import { useDispatch, useSelector } from "react-redux";
import { addMessage } from "../../../../../../data/slices/conversation";
import { FiSend } from "react-icons/fi";
import { constants } from "../../../../../../config/constants";
import Grow from "@mui/material/Grow";

const searchTemplateStrings = (str) => {
  var rxp = /{{([^}}]+)}/g,
    curMatch;
  let temp = [];
  while ((curMatch = rxp.exec(str))) {
    temp.push(curMatch[1]);
  }
  return temp;
};

export default function MessageInput({ template, found, msgSuccess }) {
  const checked = true;

  const dispatch = useDispatch();

  const conversation = useSelector((state) => state.conversation);

  const [message, setMessage] = React.useState({
    phone: "",
    templateValues: {},
  });

  React.useEffect(() => {
    if (conversation.newWATemplateMsgPhone === true) {
      setMessage({
        phone: conversation.wa_id,
        templateValues: {},
      });
    } else {
      setMessage({
        phone: "",
        templateValues: {},
      });
    }
  }, [conversation.newWATemplateMsgPhone]);

  const [preview, setPreview] = React.useState([...template.components]);

  React.useEffect(() => {
    let temp = [];
    template.components.forEach((item) => {
      let itemTmp = { ...item };
      let keys = Object.keys(message.templateValues);
      keys.forEach((key) => {
        if (message.templateValues[key] !== "") {
          if (itemTmp.text) {
            itemTmp.text = itemTmp.text.replace(
              key,
              `<span style='color: blue;'>${message.templateValues[key]}</span>`
            );
          }
        } else {
          if (itemTmp.text) {
            itemTmp.text = itemTmp.text.replace(
              key,
              `<span style='color: blue;'>${key}</span>`
            );
          }
        }
      });
      temp.push(itemTmp);
    });
    setPreview(temp);
  }, [message.templateValues]);

  const [loading, setLoading] = React.useState(false);

  const sendMsg = () => {
    setLoading(true);
    let txtContent = preview
      .map((e) => {
        if (e.text) {
          return e.text
            .replace("<span style='color: blue;'>", "")
            .replace("</span>", "");
        }
        return "";
      })
      .join("\n\n");

    let msg = {
      template: {
        name: template.name,
        language: {
          code: template.language,
        },
        components: [
          ...template.components.map((component, i) => {
            let strMatches = searchTemplateStrings(component.text);
            if (component.type !== "FOOTER" && component.type !== "BUTTONS") {
              return {
                type: component.type,
                parameters: strMatches.map((e, i) => {
                  return {
                    type: "text",
                    text: message.templateValues["{{" + e + "}}"],
                  };
                }),
              };
            }
          }),
        ],
      },
    };

    var requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        message: msg,
        isTemplate: true,
        preview: txtContent,
        phone: message.phone,
        profileName: conversation.newWATemplateMsgPhone === true ? conversation.name : "",
        thread_id: conversation.match.thread_id,
        type : conversation.match.type
      }),
      redirect: "follow",
    };

    console.log('request options',requestOptions)

    fetch(`${constants.apiUrl}/template/send`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result.error) {
          throw Error(result.error);
        }
        dispatch(addMessage(result));

        setLoading(false);
        msgSuccess();
      })
      .catch((error) => {
        setLoading(false);
        alert(error.message);
      });
  };

  return (
    <Grow
      in={checked}
      style={{ transformOrigin: "0 0 0" }}
      {...(checked ? { timeout: 1000 } : {})}
    >
      <Stack sx={{ mt: 2, width: 390 }}>
        <TextField
          label="Phone Number"
          fullWidth
          sx={{ m: 1 }}
          onChange={(v) => {
            setMessage((message) => ({
              ...message,
              phone: v.target.value,
            }));
          }}
          value={message.phone}
          color="info"
          InputProps={{
            startAdornment: <InputAdornment position="start">+</InputAdornment>,
          }}
        />
        <h4>Populate</h4>
        {found.map((e) => {
          return (
            <Stack direction="row" sx={{ alignItems: "center" }}>
              <TextField
                onChange={(v) => {
                  setMessage((message) => ({
                    ...message,
                    templateValues: {
                      ...message.templateValues,
                      ["{{" + e + "}}"]: v.target.value,
                    },
                  }));
                }}
                value={message.templateValues["{{" + e + "}}"] || ""}
                label={"Add a replacement value for {{" + e + "}}"}
                sx={{ m: 1, flex: 1 }}
                color="info"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      {"{{"}
                      {e}
                      {"}}"}
                    </InputAdornment>
                  ),
                }}
              />
            </Stack>
          );
        })}

        <Stack sx={{}}>
          <h4>Preview</h4>
          {preview.map((e) => {
            if (e.type === "HEADER") {
              return (
                <div
                  dangerouslySetInnerHTML={{
                    __html: `<p>
                        <b>${e.text}</b>
                        <br />
                        <br />
                      </p>`,
                  }}
                />
              );
            } else if (e.type === "BODY") {
              return (
                <div
                  dangerouslySetInnerHTML={{
                    __html: ` <p>
                        ${e.text}
                        <br />
                        <br />
                      </p>`,
                  }}
                />
              );
            } else if (e.type === "FOOTER") {
              return <Typography variant="caption">{e.text}</Typography>;
            }
            return null;
          })}
        </Stack>
        {!loading && <Divider sx={{ mt: 2, mb: 2, opacity: 0.5 }} />}

        {loading && (
          <LinearProgress color="info" sx={{ mt: 2, mb: 2, opacity: 0.5 }} />
        )}
        <Button
          color="info"
          disabled={
            message.phone.trim().length < 11 ||
            Object.keys(message.templateValues).length !== found.length ||
            found
              .map((e) => {
                if (
                  !message.templateValues["{{" + e + "}}"] ||
                  message.templateValues["{{" + e + "}}"].trim() === ""
                ) {
                  return true;
                }
                return false;
              })
              .includes(true)
          }
          startIcon={<FiSend />}
          onClick={sendMsg}
          disableElevation
          sx={{ alignSelf: "end", borderRadius: 32, textTransform: "none" }}
          variant="contained"
        >
          Send Message
        </Button>
      </Stack>
    </Grow>
  );
}
