import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import React from "react";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import Button from "@mui/material/Button";
import { FcGoogle } from "react-icons/fc";
import { FaFacebook } from "react-icons/fa";
import { useState } from "react";
import Alert from "@mui/material/Alert";
import { EmailRounded, Visibility, VisibilityOff } from "@mui/icons-material";
import IconButton from "@mui/material/IconButton";

import LinearProgress from "@mui/material/LinearProgress";

const FormContainer = ({
  profile,
  setProfile,
  loading,
  handleRegister,
  error,
}) => {
  const styles = {
    formContainer: {
      backgroundColor: "white",
      "& fieldset": {
        borderRadius: 2,
      },
      "& .MuiOutlinedInput-root": {
        "& fieldset": {
          borderColor: "rgba(0, 0, 0, 0.03)",
        },
        "&:hover fieldset": {
          borderColor: "black",
        },
        "&.Mui-focused fieldset": {
          borderColor: "#1A9134",
        },
      },
      flex: 2,
      justifyContent: "center",
      alignItems: "center",
    },
  };

  const [passwordVisible, setPasswordVisible] = useState(false);
  return (
    <Stack sx={styles.formContainer}>
      <Stack sx={{ width: "400px" }}>
        <Typography variant="h4">
          <b>
            Start your
            <span style={{ color: "#DDC33E" }}> 14-day </span>
            free trial now.
          </b>
        </Typography>
        <Typography sx={{ mt: 1 }}>
          Already have an account?{" "}
          <span style={{ color: "#009834" }}>Sign in</span>
        </Typography>
        {false && (
          <div>
            <Stack sx={{ mt: 2 }} direction="row">
              <div
                style={{
                  padding: 12,
                  borderColor: "#D1D7D6",
                  borderRadius: 32,
                  alignItems: "center",
                  justifyContent: "center",
                  flex: 1,
                  borderWidth: 1,
                  borderStyle: "solid",
                  display: "flex",
                  marginRight: 8,
                }}
              >
                <FcGoogle style={{ marginRight: 16 }} />
                <Typography>Sign up</Typography>
              </div>
              <div
                style={{
                  padding: 12,
                  borderColor: "#D1D7D6",
                  borderRadius: 32,
                  alignItems: "center",
                  justifyContent: "center",
                  flex: 1,
                  borderWidth: 1,
                  borderStyle: "solid",
                  display: "flex",
                }}
              >
                <FaFacebook color="blue" style={{ marginRight: 16 }} />
                <Typography>Sign up</Typography>
              </div>
            </Stack>
            <Stack sx={{ alignItems: "center", mt: 3 }} direction="row">
              <hr style={{ flex: 1, opacity: 0.3 }} />
              <Typography sx={{ mr: 1, ml: 1 }} variant="caption">
                or sign up with email
              </Typography>
              <hr style={{ flex: 1, opacity: 0.3 }} />
            </Stack>
          </div>
        )}

        {loading && <LinearProgress sx={{ mt: 2 }} />}

        {error && (
          <Alert sx={{ mt: 2 }} severity="error">
            {error}
          </Alert>
        )}
        <Stack sx={{ mt: 1 }} direction="row">
          <TextField
            value={profile.firstName}
            onChange={(e) => {
              setProfile((profile) => ({
                ...profile,
                firstName: e.target.value,
              }));
            }}
            sx={{ mt: 3, flex: 1, mr: 1 }}
            size="medium"
            label="First name"
          ></TextField>
          <TextField
            value={profile.lastName}
            onChange={(e) => {
              setProfile((profile) => ({
                ...profile,
                lastName: e.target.value,
              }));
            }}
            sx={{ mt: 3, flex: 1 }}
            size="medium"
            label="Last name"
          ></TextField>
        </Stack>

        <TextField
          sx={{ mt: 3 }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <EmailRounded size={10} />
              </InputAdornment>
            ),
          }}
          value={profile.email}
          onChange={(e) => {
            setProfile((profile) => ({
              ...profile,
              email: e.target.value,
            }));
          }}
          type="email"
          size="medium"
          fullWidth
          placeholder="Email address"
        ></TextField>

        <TextField
          sx={{ mt: 3 }}
          type={passwordVisible ? "text" : "password"}
          value={profile.password}
          onChange={(e) => {
            setProfile((profile) => ({
              ...profile,
              password: e.target.value,
            }));
          }}
          InputProps={{
            endAdornment: (
              <InputAdornment position="start">
                <IconButton
                  onClick={() => {
                    setPasswordVisible((passwordVisible) => !passwordVisible);
                  }}
                  size="small"
                >
                  {passwordVisible ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            ),
          }}
          placeholder="Password"
          size="medium"
          fullWidth
        ></TextField>

        {false && (
          <Stack>
            <TextField
              sx={{ flex: 1, mr: 1 }}
              label="Fist name"
              variant="outlined"
            />
            <TextField
              sx={{ flex: 1, mt: 2 }}
              label="Email address"
              variant="outlined"
            />
          </Stack>
        )}

        <Button
          disabled={loading}
          onClick={() => {
            handleRegister();
          }}
          sx={{ mt: 2, height: 55, borderRadius: 2, textTransform: "none" }}
          disableElevation
          variant="contained"
          size="large"
        >
          Create Account
        </Button>
      </Stack>
    </Stack>
  );
};
export default FormContainer;
