/* eslint-disable react-hooks/exhaustive-deps */
"use client";

import { ErrorBoundary } from "react-error-boundary";

import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import Slide from "@mui/material/Slide";
import { useSelector } from "react-redux";
import Collapse from "@mui/material/Collapse";
import UserProfile from "./profile";
import LinearProgress from "@mui/material/LinearProgress";
import { HiCheckCircle } from "react-icons/hi2";
import Box from "@mui/material/Box";
import UserDocuments from "./documents";
import {
  doc,
  getDoc,
  getFirestore,
  onSnapshot,
  updateDoc,
} from "firebase/firestore";
import UserNotes from "./notes";
import NewInvoice from "./invoice";
import CollectionDetails from "./collection_details";
import AuditTrail from "./audi_trail";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { updateUserData } from "../../home/conversation/client-data/updateUserData";
import { constants } from "../../../../config/constants";


const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
};
// eslint-disable-next-line react-hooks/exhaustive-deps

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function numberWithCommas(x) {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export default function ClientDialog({ open, setOpen, docId }) {
  const handleClose = () => {
    setOpen(false);
  };
  const db = getFirestore();

  const [loading, setLoading] = React.useState(false);

  const [showInvoice, setShowInvoice] = React.useState(false);

  const [user, setUser] = React.useState(null);

  const [success, setSuccess] = React.useState(false);

  const [buttonText, setButtonText] = React.useState("Notify");

  const getUser = async () => {
    setUser(null);
    setLoading(true);
    const docRefProfile = doc(db, 'drivers', docId, 'profile', 'profileDocument');
    const docRefUploads = doc(db, 'drivers', docId, 'profile', 'uploadedDocuments');
    const docRefToken = doc(db, 'drivers',docId)
    const docSnapUploads = await getDoc(docRefUploads);
    const docSnapProfile = await getDoc(docRefProfile);
    const docSnapToken = await getDoc(docRefToken);

    if (docSnapProfile.exists() && docSnapUploads.exists()) {
      setUser({ ...docSnapProfile.data(), ...docSnapUploads.data(), ...docSnapToken.data(), applicationId: docId });
    } else if (docSnapProfile.exists()) {
      setUser({ ...docSnapProfile.data(), ...docSnapToken.data(), applicationId: docId });
    } else if (docSnapUploads.exists()) {
      setUser({ ...docSnapUploads.data(), ...docSnapToken.data(), applicationId: docId });
    }else {
      // doc.data() will be undefined in this case
      console.log("No such document!");
    }
    setLoading(false);
  };
  const sendAgreement = async () => {
    setLoading(true);
    let params = {
      email: user.email,
      CLIENT: user.name,
      CAR: user.car.name + " " + user.car.model,
      freeKM: numberWithCommas(user.car.freeKM),
      ID: user.id.toUpperCase(),
      ACTIVATIONFEE: "R" + numberWithCommas(user.car.activationFee),
      SERVICEFEE: "R" + numberWithCommas(user.car.subscription),
      TOTAL:
        "R" +
        numberWithCommas(
          parseInt(user.car.activationFee) + parseInt(user.car.subscription)
        ),
      COSTPERKM: "R" + user.car.pricePerKm,
      COLLECTIONDATE: user.collectionDate,
      DATE: new Date().toDateString(),
      link: "https://drivetoown.co.za/Agreement?id=" + docId,
    };
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify(params);

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    let result = await fetch(
      "http://localhost:4000/email/agreement",
      requestOptions
    );
    setLoading(false);
    return result;
  };

  //const admin = useSelector((state) => state.auth);

  const approveApplication = async () => {
    setLoading(true);
    // Perform the update
    await updateUserData(docId, {Account_status:"verified", balance: 100});
    setLoading(false);
    //sendAgreement();
    handleClose();
  };

  const sendExpoPushNotifications = async (token) => {
    const msg = "🚗 You're missing ride requests because your Swiftly Driver profile isn't completed. Please complete your profile to start receiving ride requests! 🎯";
    try {
      const message = {
        to: token,
        priority: 'high',
        channelId: 'default',
        title: 'Complete Your Profile to Receive Rides',
        body: msg,
        data: { notificationType: 'reminder' },
      };
  
      const response = await fetch(`${constants.apiUrl}/send-notification`, {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify([message]), // Sending a single message wrapped in an array
      });
  
      const result = await response.json();
      console.log('Notification sent successfully:', result);
    } catch (error) {
      console.error('Error sending notification:', error.message);
    }
  };

  const handleClick = () => {
    if (user && user.fcmToken) {
      sendExpoPushNotifications(user.fcmToken);
      setButtonText("Sent"); // Change button text to "Sent"
    }
  };
  


  const moveToDelivered = async () => {
    setLoading(true);

    let url =
      "https://spring-ship-233716.uc.r.appspot.com/user/setup?applicationId=" +
      docId;

    fetch(url)
      .then((response) => response.json())
      .then(async (json) => {
        setLoading(false);
        if (json.result_code === "created") {
          setLoading(false);
          setSuccess(true);
          setTimeout(() => {
            setSuccess(false);
            handleClose();
          }, 1400);
        }
      })
      .catch((error) => {
        alert("Error creating client - " + error.message);
        setLoading(false);
      });

    //
  };

  React.useEffect(() => {
    const unsubApp = onSnapshot(doc(db, 'drivers', docId, 'profile', 'profileDocument'), async (appDoc) => {
      if (appDoc.exists()) {
        const appData = appDoc.data();

        // Fetch related driver data
        const driverDocRef = doc(db, 'drivers', docId, 'profile', 'uploadedDocuments');
        const driverDocSnap = await getDoc(driverDocRef);

        // Fetch driver token data
        const docRefToken = doc(db, 'drivers', docId);
        const docSnapToken = await getDoc(docRefToken);

        // Merge data from both snapshots
        const driverData = {
          ...(driverDocSnap.exists() ? driverDocSnap.data() : {}),
          ...(docSnapToken.exists() ? docSnapToken.data() : {})
        };
        

        // Combine and set data
        setUser({
          ...appData,
          ...driverData,
          applicationId: docId
        });
      } else {
        console.log("No such application document!");
        setUser(null); // Clear user state if no document found
      }
      setLoading(false);
    });


    return () => {
      unsubApp();
    };
  }, [docId]);

  React.useEffect(() => {
    getUser();
  }, []);

  return (
    <>
      <Dialog
        PaperProps={{
          sx: {
            background: "#EDFCFF",
            borderRadius: 2,
            p: 0,
          },
        }}
        maxWidth="md"
        TransitionComponent={Transition}
        open={open}
        onClose={handleClose}
      >
        <Collapse in={loading}>
          <LinearProgress />
        </Collapse>

        <DialogContent sx={{ minWidth: 550, p: 2 }}>
          <Collapse in={user}>
            {user ? (
              <div>
                <ErrorBoundary fallback={<div>Something went wrong</div>}>
                  <UserProfile user={user} />
                  <UserDocuments user={user} />
                </ErrorBoundary>
              </div>
            ) : (
              <div />
            )}
          </Collapse>
        </DialogContent>
        <DialogActions sx={{}}>
          {user && (
            <>
              {!user.verified && (
                <>
                  <Button
                    disabled={loading || !user}
                    color="info"
                    onClick={() => {
                      approveApplication();
                    }}
                    sx={{ textTransform: "none" }}
                  >
                    Approve
                  </Button>

                  <Button
                    disabled={loading || !user}
                    color="error"
                    onClick={handleClick}
                    sx={{ textTransform: "none" }}
                  >
                    {buttonText}
                  </Button>
                </>
              )}
              
              {user.status === "awaitingDelivery" && (
                <>
                  <Button
                    disabled={loading || !user}
                    color="info"
                    onClick={() => {
                      moveToDelivered();
                    }}
                    sx={{ textTransform: "none" }}
                  >
                    Car Collected
                  </Button>
                  <Button
                    disabled={loading || !user}
                    color="info"
                    onClick={() => {
                      setShowInvoice(true);
                    }}
                    sx={{ textTransform: "none" }}
                  >
                    New Invoice
                  </Button>
                </>
              )}
            </>
          )}
          <Button
            color="inherit"
            sx={{ width: 80, textTransform: "none" }}
            onClick={handleClose}
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
      <Modal
        open={success}
        onClose={() => {
          setSuccess(false);
          handleClose();
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Client Created
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 1, mb: 1 }}>
            Applicant has been moved to the client list successfully.
          </Typography>
          <HiCheckCircle color="#009B21" size={24} />
        </Box>
      </Modal>
      {showInvoice && (
        <NewInvoice
          applicationId={docId}
          setOpen={() => {
            setShowInvoice(false);
          }}
          user={user}
          open={showInvoice}
        />
      )}
    </>
  );
}
