import { createSlice } from "@reduxjs/toolkit";
import { constants } from "../../config/constants";

const chatsSlice = createSlice({
  name: "chats",
  initialState: [],
  reducers: {
    bulkAddChats(state, action) {
      return [...action.payload];
    },
    addChat(state, action) {
      return [action.payload, ...state];
    },
    updateToRead(state, action) {
      if (action.payload.type === "whatsapp") {
        let index = state.findIndex((msg) => {
          if (msg.whatsapp) {
            return msg.whatsapp.wa_id === action.payload._id;
          } else {
            return false;
          }
        });
        if (index !== -1) {
          state[index] = {
            ...state[index],
            unread: 0,
          };
        }
      }
      if (action.payload.type === "comment") {
        let index = state.findIndex((msg) => {
          if (msg.comment) {
            return msg.comment.comment_id === action.payload._id;
          } else {
            return false;
          }
        });
        if (index !== -1) {
          state[index] = {
            ...state[index],
            unread: 0,
            read: true,
          };
        }
      }
      if (action.payload.type === "messenger") {
        let index = state.findIndex((msg) => {
          if (msg.messenger) {
            return (
              msg.messenger.recipient.id === action.payload._id ||
              msg.messenger.sender.id === action.payload._id
            );
          } else {
            return false;
          }
        });
        if (index !== -1) {
          state[index] = {
            ...state[index],
            unread: 0,
          };
        }
      }

      if (action.payload.type === "email") {
        let index = state.findIndex((msg) => {
          if (msg.email) {
            return msg._id === action.payload._id;
          } else {
            return false;
          }
        });
        if (index !== -1) {
          state[index] = {
            ...state[index],
            read: true,
          };
        }
      }
    },
    updateChat(state, action) {
      let person_id = "";
      if (
        action.payload.type === "whatsapp" ||
        action.payload.type === "messenger" ||
        action.payload.type === "facebook"
      ) {
        let index = state.findIndex((msg) => {
          if (msg.whatsapp && action.payload.type === "whatsapp") {
            return msg.whatsapp.wa_id === action.payload.message.whatsapp.wa_id;
          } else if (msg.messenger && action.payload.type === "messenger") {
            if (
              constants.adminPageIDs.includes(
                action.payload.message.messenger.recipient.id
              )
            ) {
              person_id = action.payload.message.messenger.sender.id;
            } else {
              person_id = action.payload.message.messenger.recipient.id;
            }
            return (
              msg.messenger.recipient.id === person_id ||
              msg.messenger.sender.id === person_id
            );
          } else if (msg.comment && action.payload.type === "facebook") {
            let comment_id = action.payload.message.comment.comment_id;
            let parent_id = action.payload.message.comment.parent_id;

            return (
              msg.comment.comment_id === parent_id ||
              msg.comment.parent_id === parent_id
            );
          } else {
            return false;
          }
        });
        if (index !== -1) {
          if (action.payload.type === "whatsapp") {
            state[index] = {
              ...state[index],
              whatsapp: action.payload.message.whatsapp,
              unread:
                action.payload.inChat ||
                constants.adminPhones.includes(
                  action.payload.message.whatsapp.from
                )
                  ? 0
                  : state[index].unread + 1,
              date: action.payload.message.date,
            };
          } else if (action.payload.type === "messenger") {
            state[index] = {
              ...state[index],
              messenger: action.payload.message.messenger,
              unread:
                action.payload.inChat ||
                constants.adminPageIDs.includes(
                  action.payload.message.messenger.sender.id
                )
                  ? 0
                  : state[index].unread + 1,
              date: action.payload.message.date,
            };
          } else if (action.payload.type === "facebook") {
            state[index] = {
              ...state[index],
              comment: action.payload.message.comment,
              unread: action.payload.inChat ? 0 : state[index].unread + 1,
              date: action.payload.message.date,
            };
          }

          state.sort((y, x) => {
            return new Date(x.date) - new Date(y.date);
          });
        } else {
          return [
            {
              ...action.payload.message,
              unread: 1,
              date: action.payload.message.date,
            },
            ...state,
          ];
        }
      } else {
        let index = state.findIndex(
          (msg) => msg._id === action.payload.message._id
        );
        if (index === -1) {
          return [
            {
              ...action.payload.message,
              unread: 1,
              date: action.payload.message.date,
            },
            ...state,
          ];
        }
      }
    },
  },
});

export const { bulkAddChats, addChat, updateToRead, updateChat } =
  chatsSlice.actions;
export default chatsSlice.reducer;
