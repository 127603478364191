import * as React from "react";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import LinearProgress from "@mui/material/LinearProgress";
import { getFirestore, doc, updateDoc } from "firebase/firestore";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DateField } from "@mui/x-date-pickers/DateField";

export default function SubscriptionExpires({ handleTooltipClose, userID }) {
  const [start, setStart] = React.useState(null);

  const [error, setError] = React.useState(null);

  const [loading, setLoading] = React.useState(false);

  const db = getFirestore();

  const saveSubscriptionExpires = async () => {
    setLoading(true);
    const docRef = doc(db, "clients", userID);

    const selected = new Date(start);

    await updateDoc(docRef, {
      renewalDay: selected.getDate(),
      subscriptionExpires: {
        day: selected.getDate(),
        month: selected.getMonth(),
        year: selected.getFullYear(),
        date: selected.toLocaleString("en-US", {
          timeZone: "Africa/Johannesburg",
        }),
      },
    });
    setLoading(false);
    handleTooltipClose();
  };

  return (
    <React.Fragment>
      <Typography variant="h6">
        <b>Set client start date</b>
      </Typography>
      {loading && <LinearProgress sx={{ mt: 1, borderRadius: 32 }} />}
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DateField
          sx={{
            "& fieldset": {
              borderRadius: 32,
            },
            "& .MuiOutlinedInput-root": {
              "& fieldset": {
                borderColor: "rgba(0, 0, 0, 0.05)",
              },
            },
            mt: 2,
          }}
          fullWidth
          format="DD/MM/YYYY"
          onError={(newError) => setError(newError)}
          value={start}
          onChange={(newValue) => setStart(newValue)}
          label="Subscription expires"
        />
      </LocalizationProvider>

      <Stack sx={{ mt: 2, mb: 2 }}>
        <Button
          onClick={saveSubscriptionExpires}
          disabled={!start || loading || error}
          disableElevation
          variant="contained"
          color="info"
          sx={{ mr: 1, borderRadius: 32, height: 45 }}
        >
          Save
        </Button>
        <Button
          onClick={handleTooltipClose}
          disableElevation
          color="warning"
          variant="outlined"
          sx={{ mr: 1, borderRadius: 32, mt: 1, height: 45 }}
        >
          Cancel
        </Button>
      </Stack>
    </React.Fragment>
  );
}
