/* eslint-disable react-hooks/exhaustive-deps */
import Stack from "@mui/material/Stack";
import * as React from "react";
import { Divider, Typography } from "@mui/material";
import LinearProgress from "@mui/material/LinearProgress";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import { useAddNewUserMutation } from "../../../../services/api-endpoints/user";
import RadioGroup from "@mui/material/RadioGroup";
import Radio from "@mui/material/Radio";

const NewUser = ({ setOpen, handleTooltipClose }) => {
  const [userEdit, setEditUser] = React.useState({
    firstName: "",
    lastName: "",
    role: "admin",
    email: "",
    password: "",
  });

  const [addUser, { isLoading }] = useAddNewUserMutation();

  const saveuser = async () => {
    try {
      let result = await addUser(userEdit);
      if (result.error) {
        throw new Error(result.error.data.error);
      }
      handleTooltipClose();
    } catch (e) {
      alert("Error => " + e.message);
    }
  };

  return (
    <Stack>
      <h3 style={{ margin: 0 }}>New User</h3>
      <form
        onSubmit={(e) => {
          e.preventDefault();
          saveuser();
        }}
        action="/"
      >
        <TextField
          size="small"
          color="info"
          required
          sx={{
            "& fieldset": {
              borderRadius: 3,
            },
            "& .MuiOutlinedInput-root": {
              "& fieldset": {
                borderColor: "rgba(0, 0, 0, 0.05)",
              },
            },
            mt: 2,
          }}
          defaultValue={userEdit.firstName}
          fullWidth
          label="First name"
          onChange={(e) => {
            setEditUser((user) => ({
              ...user,
              firstName: e.target.value,
            }));
          }}
        />
        <TextField
          required
          size="small"
          color="info"
          onChange={(e) => {
            setEditUser((user) => ({
              ...user,
              lastName: e.target.value,
            }));
          }}
          sx={{
            "& fieldset": {
              borderRadius: 3,
            },
            "& .MuiOutlinedInput-root": {
              "& fieldset": {
                borderColor: "rgba(0, 0, 0, 0.05)",
              },
            },
            mt: 2,
          }}
          fullWidth
          label="Last name"
        />
        <TextField
          size="small"
          required
          color="info"
          onChange={(e) => {
            setEditUser((user) => ({
              ...user,
              email: e.target.value,
            }));
          }}
          sx={{
            "& fieldset": {
              borderRadius: 3,
            },
            "& .MuiOutlinedInput-root": {
              "& fieldset": {
                borderColor: "rgba(0, 0, 0, 0.05)",
              },
            },
            mt: 2,
          }}
          fullWidth
          label="Email address"
        />
        <Stack>
          <Typography variant="caption" sx={{ mt: 1, ml: 1 }}>
            User role
          </Typography>
          <RadioGroup
            defaultValue={userEdit.role}
            value={userEdit.role}
            onChange={(e) => {
              setEditUser((user) => ({
                ...user,
                role: e.target.value,
              }));
            }}
          >
            <Stack sx={{ alignItems: "center" }} direction="row">
              <Radio color="info" value="admin" />
              <Typography variant="caption">Admin</Typography>
              <Radio color="info" sx={{ ml: 2 }} value="agent" />
              <Typography variant="caption">Agent</Typography>
            </Stack>
          </RadioGroup>
        </Stack>

        <Divider sx={{ opacity: 0.5, mt: 1, mb: 1 }} />

        <Typography variant="caption" sx={{ mt: 1 }}>
          Create a default password of minimum length 7 characters.
        </Typography>
        <TextField
          size="small"
          color="info"
          required
          onChange={(e) => {
            setEditUser((user) => ({
              ...user,
              password: e.target.value,
            }));
          }}
          sx={{
            "& fieldset": {
              borderRadius: 3,
            },
            "& .MuiOutlinedInput-root": {
              "& fieldset": {
                borderColor: "rgba(0, 0, 0, 0.05)",
              },
            },
            mt: 1,
          }}
          fullWidth
          value={userEdit.password}
          label="Password"
        />
        {isLoading && (
          <LinearProgress color="info" sx={{ mt: 1, borderRadius: 32 }} />
        )}

        <Stack direction="row" sx={{ mt: 2, justifyContent: "flex-end" }}>
          <Button
            onClick={handleTooltipClose}
            disableElevation
            color="warning"
            variant="outlined"
            sx={{ mr: 1, borderRadius: 32 }}
          >
            Cancel
          </Button>
          <Button
            type="submit"
            color="info"
            disabled={isLoading}
            disableElevation
            variant="contained"
            sx={{ mr: 1, borderRadius: 32 }}
          >
            Save
          </Button>
        </Stack>
      </form>
    </Stack>
  );
};

export default NewUser;
