import { createSlice } from "@reduxjs/toolkit";

const conversationSlice = createSlice({
  name: "conversation",
  initialState: {
    type: null,
    wa_id: null,
    email: null,
    messages: [],
    name: null,
    messageId: null,
    _id: null,
    sentFiles: [],
    match: {
      type: null,
      data: null,
    },
    sender: null,
    person_id: null,
    newWATemplateMsgPhone: false,
    working_id: null,
  },
  reducers: {
    updateConversation(state, action) {
      return { ...state, ...action.payload };
    },
    addMessage(state, action) {
      console.log('Current State:', state);
      console.log('Incoming Payload:', action.payload);
    
      if (
        action.payload.whatsapp ||
        action.payload.messenger ||
        action.payload.comment
      ) {
        if (state.sentFiles.findIndex((x) => x === action.payload._id) === -1) {
          let index = state.messages.findIndex((msg) => {
            if (!msg.comment && !action.payload.comment) {
              return msg._id === action.payload._id;
            } else {
              return (
                msg.comment.comment_id === action.payload.comment.comment_id
              );
            }
          });
    
          if (index === -1) {
            console.log('Adding new message:', action.payload);
            return {
              ...state,
              messages: [...state.messages, action.payload],
            };
          } else {
            console.log('Duplicate message found, not adding:', action.payload);
          }
        }
      } else {
        console.log('Adding message directly:', action.payload);
        return {
          ...state,
          messages: [action.payload, ...state.messages],
        };
      }
    },
    
    updateMessageStatus(state, action) {
      let index = state.messages.findIndex(
        (msg) => msg._id === action.payload._id
      );

      if (index !== -1) {
        state.messages[index] = {
          ...state.messages[index],
          whatsapp: {
            ...state.messages[index].whatsapp,
            status: action.payload.status,
          },
        };
      }
    },
    updateSentFile(state, action) {
      let index = state.messages.findIndex((msg) => msg._id === action.payload);

      if (index !== -1) {
        state.messages[index] = {
          ...state.messages[index],
          sent: true,
        };
      }
    },
    addSentFile(state, action) {
      return {
        ...state,
        sentFiles: [...state.sentFiles, action.payload],
      };
    },
    resetSentFiles(state, action) {
      state.sentFiles = [];
    },
  },
});

export const {
  updateConversation,
  addMessage,
  updateMessageStatus,
  addSentFile,
  resetSentFiles,
  updateSentFile,
} = conversationSlice.actions;
export default conversationSlice.reducer;
