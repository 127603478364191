const filter = (data, search) => {
  if (search.trim() === "") {
    return true;
  }
  if (data.name.toLowerCase().indexOf(search.toLowerCase()) !== -1) return true;
  if (data.phone.toLowerCase().indexOf(search.toLowerCase()) !== -1)
    return true;

  if (data.email.toLowerCase().indexOf(search.toLowerCase()) !== -1)
    return true;

  return false;
};

export { filter };
