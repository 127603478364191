/* eslint-disable react-hooks/exhaustive-deps */
import * as React from "react";
import { Stack } from "@mui/system";
import { IconButton, Typography } from "@mui/material";
import { MdClose, MdRefresh } from "react-icons/md";
import { HiDocument } from "react-icons/hi2";
import PropTypes from "prop-types";
import LinearProgress from "@mui/material/LinearProgress";
import Box from "@mui/material/Box";
import { useSelector, useDispatch } from "react-redux";
import { BsFileEarmarkPdfFill, BsFileExcel, BsFileWord } from "react-icons/bs";
import {
  addSentFile,
  updateSentFile,
} from "../../../../../../data/slices/conversation";
import { constants } from "../../../../../../config/constants";
import Chip from "@mui/material/Chip";

const {
  collection,
  addDoc,
  getFirestore,
  setDoc,
} = require("firebase/firestore");

function LinearProgressWithLabel(props) {
  return (
    <Box sx={{ display: "flex", alignItems: "center" }}>
      <Box sx={{ width: "100%", mr: 1 }}>
        <LinearProgress variant="determinate" {...props} />
      </Box>
      <Box sx={{ minWidth: 35 }}>
        <Typography variant="body2" color="text.secondary">{`${Math.round(
          props.value
        )}%`}</Typography>
      </Box>
    </Box>
  );
}

LinearProgressWithLabel.propTypes = {
  value: PropTypes.number.isRequired,
};

function ListItemFile({ file, sent, _id }) {
  const db = getFirestore();
  const dispatch = useDispatch();
  const [progress, setProgress] = React.useState(0);

  const [error, setError] = React.useState(false);

  const conversation = useSelector((state) => state.conversation);

  let endpoint = "";

  if (conversation.type === "whatsapp") {
    endpoint = "whatsapp";
  } else if (conversation.type === "messenger") {
    endpoint = "facebook";
  }

  const progressHandler = (event) => {
    let percent = Math.round((event.loaded / event.total) * 100);
    setProgress(percent);
  };

  const completeHandler = async (e) => {
    if (e.target.status !== 200) {
      setProgress(0);
      setError(true);

      await addDoc(collection(db, "media_errors"), {
        logs: e.target.responseText,
      });
    } else {
      dispatch(addSentFile(JSON.parse(e.target.responseText)._id));
      dispatch(updateSentFile(_id));
    }
  };

  const errorHandler = async (e) => {
    await addDoc(collection(db, "media_errors"), {
      logs: e.message,
    });
    console.log(e.message);
  };

  const abortHandler = () => {};

  const saveFile = () => {
    var formdata = new FormData();
    formdata.append("phone", conversation.wa_id);
    formdata.append("recipient", conversation.person_id);
    formdata.append("profileName", conversation.name || "No name");
    formdata.append("file", file, file.name);
    var ajax = new XMLHttpRequest();
    ajax.upload.addEventListener("progress", progressHandler, false);
    ajax.addEventListener("load", completeHandler, false);
    ajax.addEventListener("error", errorHandler, false);
    ajax.addEventListener("abort", abortHandler, false);
    ajax.open("POST", `${constants.apiUrl}/${endpoint}/send/document`);

    ajax.send(formdata);
  };

  const [fileData, setFileData] = React.useState(null);

  React.useEffect(() => {
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onloadend = function () {
      setFileData(reader.result);
    };
    if (!sent) {
      saveFile();
    } else {
      setProgress(100);
    }
  }, [file]);

  function truncateMessage(str) {
    return str.length > 20 ? str.substring(0, 20) + "..." : str;
  }

  const splits = file.name.split(".");
  const formart = splits[splits.length - 1];

  return (
    <Stack
      // key={index.toString() + file.name}
      sx={{
        mb: 1,
        background: "white",
        boxShadow: "5px 5px 24px 1px rgba(0, 0, 0, 0.06)",
        p: 1.2,
        width: "260px",
        borderRadius: 3,
        borderTopRightRadius: 0,
      }}
    >
      {file.type.indexOf("image") !== -1 && (
        <img
          src={fileData}
          alt=""
          style={{ width: "250px", borderRadius: "16px" }}
        />
      )}
      <Stack
        direction="row"
        sx={{
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Stack
          sx={{
            alignItems: "center",
          }}
          direction="row"
        >
          {file.type.indexOf("image") === -1 && (
            <Stack
              sx={{
                mr: 1,
                borderColor: "rgba(233, 233, 233, 0.73)",
                borderStyle: "solid",
                justifyContent: "center",
                alignItems: "center",
                p: 1.2,
                borderRadius: 2,
              }}
            >
              {file.type.indexOf("application/pdf") !== -1 && (
                <BsFileEarmarkPdfFill color="red" />
              )}
              {formart === "xlsx" && <BsFileExcel color="#00A12C" />}
              {formart === "docx" && <BsFileWord color="#1D98E1" />}
              {file.type.indexOf("image") === -1 &&
                file.type.indexOf("application/pdf") === -1 &&
                formart !== "docx" &&
                formart !== "xlsx" && <HiDocument />}
            </Stack>
          )}

          <Stack>
            <Typography style={{}}>{truncateMessage(file.name)}</Typography>
            <Stack direction="row" sx={{ alignItems: "center" }}>
              <Typography variant="caption">
                {(file.size / 1000 / 1000).toFixed(2)} mb
              </Typography>
              <Chip sx={{ ml: 1 }} size="small" label={formart} />

              {!sent && (
                <>
                  {error ? (
                    <Chip
                      icon={<MdRefresh />}
                      clickable
                      color="error"
                      sx={{ ml: 1 }}
                      size="small"
                      label="Failed"
                    />
                  ) : (
                    <LinearProgressWithLabel
                      color={progress === 100 ? "success" : "info"}
                      sx={{ borderRadius: 32, width: "50px", ml: 2 }}
                      value={progress}
                    />
                  )}
                </>
              )}
            </Stack>
          </Stack>
        </Stack>
        {false && (
          <IconButton
            onClick={() => {
              // setFiles((files) => files.filter((temp) => temp !== file));
            }}
          >
            <MdClose />
          </IconButton>
        )}
      </Stack>
    </Stack>
  );
}

export default ListItemFile;
