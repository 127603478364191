/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react-hooks/exhaustive-deps */
"use client";

import { ErrorBoundary } from "react-error-boundary";
import * as React from "react";
import MessageList from "./message-list/view";
import Stack from "@mui/material/Stack";
import Grow from "@mui/material/Grow";
import Conversation from "./conversation/social/view";
import EmailConversation from "./conversation/email/view";
import ClientData from "./conversation/client-data/view";
import FacebookPost from "./conversation/facebook-post/view";
import chatImg from "../../../assets/illustrations/login-visual-5.svg";
import useWebSocket from "react-use-websocket";
import { useDispatch, useSelector } from "react-redux";
import { bulkAddChats, updateChat } from "../../../data/slices/chats";
import {
  updateMessageStatus,
  addMessage,
  updateConversation,
} from "../../../data/slices/conversation";
import { constants } from "../../../config/constants";

const dt = new Date().getTime().toString();

export default function Home() {
  const checked = true;

  const dispatch = useDispatch();

  const conversation = useSelector((state) => state.conversation);
  //let tmp = `ws://127.0.0.1:4000/?userId=agent_` + dt;
  let perm = `wss://${constants.socketIp}/?userId=agent_` + dt;

  const { lastJsonMessage } = useWebSocket(encodeURI(perm), {
    //shouldReconnect: true,
    retryOnError: true,
  });

  React.useEffect(() => {
    if (lastJsonMessage !== null) {
      if (lastJsonMessage.object === "whatsapp") {
        if (lastJsonMessage.type === "status") {
          dispatch(
            updateMessageStatus({
              _id: lastJsonMessage._id,
              status: lastJsonMessage.status,
            })
          );
        } else {
          if (lastJsonMessage.message.whatsapp.wa_id === conversation.wa_id) {
            dispatch(addMessage(lastJsonMessage.message));

            dispatch(
              updateChat({
                message: lastJsonMessage.message,
                inChat: true,
                type: "whatsapp",
              })
            );
            return;
          } else {
            dispatch(
              updateChat({
                message: lastJsonMessage.message,
                type: "whatsapp",
                inChat: false,
              })
            );
          }
        }
      } else if (lastJsonMessage.object === "email") {
        dispatch(
          updateChat({
            message: lastJsonMessage.message,
            inChat: false,
            type: "email",
          })
        );
        return;
      } else if (lastJsonMessage.object === "messenger") {
        let person_id = "";
        if (
          constants.adminPageIDs.includes(
            lastJsonMessage.message.messenger.recipient.id
          )
        ) {
          person_id = lastJsonMessage.message.messenger.sender.id;
        } else {
          person_id = lastJsonMessage.message.messenger.recipient.id;
        }

        if (person_id === conversation.person_id) {
          dispatch(addMessage(lastJsonMessage.message));
          dispatch(
            updateChat({
              message: lastJsonMessage.message,
              inChat: true,
              type: "messenger",
            })
          );
          return;
        } else {
          dispatch(
            updateChat({
              message: lastJsonMessage.message,
              type: "messenger",
              inChat: false,
            })
          );
        }
      } else if (lastJsonMessage.object === "comment") {
        let parent_id = lastJsonMessage.message.comment.parent_id;

        console.log(parent_id, "=>", conversation.comment_id);

        if (
          parent_id === conversation.parent_id ||
          parent_id === conversation.comment_id
        ) {
          dispatch(addMessage(lastJsonMessage.message));
          dispatch(
            updateConversation({
              working_id: lastJsonMessage.message.comment.comment_id,
            })
          );
          dispatch(
            updateChat({
              message: lastJsonMessage.message,
              inChat: true,
              type: "facebook",
            })
          );
          return;
        } else {
          dispatch(
            updateChat({
              message: lastJsonMessage.message,
              type: "facebook",
              inChat: false,
            })
          );
        }
      }
    }
  }, [lastJsonMessage]);

  return (
    <Grow
      in={checked}
      style={{ transformOrigin: "0 0 0" }}
      {...(checked ? { timeout: 1000 } : {})}
    >
      <Stack
        direction="row"
        sx={{
          height: "100vh",
          "& .MuiOutlinedInput-root": {
            "& fieldset": {
              borderColor: "#E6F0F4",
              borderStyle: "none",
            },
          },
        }}
      >
        <MessageList />
        {conversation.type && (
          <>
            {(conversation.type === "whatsapp" ||
              conversation.type === "messenger" ||
              conversation.type === "facebook") && <Conversation />}
            {conversation.type === "email" && <EmailConversation />}
            {conversation.type !== "facebook" && (
              <ErrorBoundary fallback={<div>Something went wrong</div>}>
                <ClientData />
              </ErrorBoundary>
            )}
            {conversation.type === "facebook" && (
              <ErrorBoundary fallback={<div>Something went wrong</div>}>
                <FacebookPost />
              </ErrorBoundary>
            )}
          </>
        )}

        {!conversation.type && (
          <Stack
            sx={{
              flex: 1,
              justifyContent: "center",
              alignItems: "center",
              background: "white",
              ml: 1,
              borderRadius: 2,
              mt: 1,
              mr: 1,
            }}
          >
            <img
              src={chatImg}
              alt="placeholder"
              style={{
                width: "250px",
              }}
            />
            <h1 style={{ margin: 0, textAlign: "center" }}>
              Tap a conversation <br />
              to get started.
            </h1>
          </Stack>
        )}
      </Stack>
    </Grow>
  );
}
