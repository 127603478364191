/* eslint-disable react-hooks/exhaustive-deps */
import * as React from "react";
import { Stack } from "@mui/system";
import { IconButton, Typography } from "@mui/material";
import { MdClose, MdError } from "react-icons/md";
import { HiDocument, HiPhoto } from "react-icons/hi2";
import PropTypes from "prop-types";
import LinearProgress from "@mui/material/LinearProgress";
import Box from "@mui/material/Box";
import { constants } from "../../../../../../../config/constants";

function truncateFileName(str) {
  return str.length > 20 ? str.substring(0, 20) + "..." : str;
}

function LinearProgressWithLabel(props) {
  return (
    <Box sx={{ display: "flex", alignItems: "center" }}>
      <Box sx={{ width: "50px", mr: 1 }}>
        <LinearProgress variant="determinate" {...props} />
      </Box>
      <Box sx={{ flex: 1 }}>
        <Typography variant="body2" color="text.secondary">{`${Math.round(
          props.value
        )}%`}</Typography>
      </Box>
    </Box>
  );
}

LinearProgressWithLabel.propTypes = {
  value: PropTypes.number.isRequired,
};

function ListItemFile({ setFiles, file, _id, saveRef, setUploading }) {
  const [progress, setProgress] = React.useState(0);
  const [error, setError] = React.useState(false);

  const progressHandler = (event) => {
    let percent = Math.round((event.loaded / event.total) * 100);
    setProgress(percent);
  };

  const completeHandler = (e) => {
    setUploading(_id, false);
    if (e.target.status === 200) {
      setProgress(100);
      setError(false);
      saveRef(JSON.parse(e.target.responseText)._id);
    } else {
      setProgress(0);
      setError(true);
    }
  };

  const errorHandler = (e) => {
    setUploading(_id, false);

    setError(true);
  };

  const abortHandler = (e) => {
    setUploading(_id, false);
    setError(true);
  };

  const saveFile = () => {
    var formdata = new FormData();
    formdata.append("file", file, file.name);
    var ajax = new XMLHttpRequest();
    ajax.upload.addEventListener("progress", progressHandler, false);
    ajax.addEventListener("load", completeHandler, false);
    ajax.addEventListener("error", errorHandler, false);
    ajax.addEventListener("abort", abortHandler, false);
    ajax.open("POST", constants.apiUrl + "/documents/email");
    // ajax.setRequestHeader("Authorization", authHeader());
    ajax.send(formdata);
    setUploading(_id, true);
  };

  React.useEffect(() => {
    if (!file.dbRef) {
      saveFile();
    } else {
      setProgress(100);
    }
  }, [file]);

  return (
    <Stack
      sx={{
        mb: 1,
        borderColor: "rgba(233, 233, 233, 0.73)",
        borderStyle: "solid",
        p: 1,
        borderRadius: 2,
      }}
    >
      <Stack
        direction="row"
        sx={{
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Stack
          sx={{
            alignItems: "center",
          }}
          direction="row"
        >
          <Stack
            sx={{
              mr: 1,
              borderColor: "rgba(233, 233, 233, 0.73)",
              borderStyle: "solid",
              justifyContent: "center",
              alignItems: "center",
              p: 1,
              borderRadius: 2,
            }}
          >
            {file.type.indexOf("image") !== -1 ? <HiPhoto /> : <HiDocument />}
          </Stack>
          <Stack sx={{ flex: 1 }}>
            <Typography>{truncateFileName(file.name)}</Typography>
            <Stack direction="row" sx={{ alignItems: "center", flex: 1 }}>
              <Typography sx={{ mr: 1, flex: 1 }} variant="caption">
                {(file.size / 1000 / 1000).toFixed(2)} mb
              </Typography>
              {error ? (
                <MdError color="red" />
              ) : (
                <LinearProgressWithLabel
                  color={progress === 100 ? "success" : "info"}
                  sx={{ borderRadius: 32 }}
                  value={progress}
                />
              )}
            </Stack>
          </Stack>
        </Stack>
        <IconButton
          onClick={() => {
            setFiles((files) => files.filter((temp) => temp.key !== _id));
          }}
        >
          <MdClose />
        </IconButton>
      </Stack>
    </Stack>
  );
}

export default ListItemFile;
