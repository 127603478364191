import React from "react";
import {
  createBrowserRouter,
  RouterProvider,
  createRoutesFromElements,
  Route,
  Navigate
} from "react-router-dom";
import ErrorPage from "./routes/error-page";
import Root from "./components/layouts/root/view";
import Home from "./components/pages/home/view";
import Contacts from "./components/pages/contacts/view";
import Signup from "./components/pages/accounts/signup/view";
import Clients from "./components/pages/clients/view";
import Payments from "./components/pages/payments/view";
import Maps from "./components/pages/maps/view";
import Applications from "./components/pages/applications/view";
import Signin from "./components/pages/accounts/sign-in/view";
import { Provider } from "react-redux";
//import Investors from "./components/pages/investors/view";
import Logout from "./components/pages/logout/view";
import Users from "./components/pages/users/view";
import { PersistGate } from "redux-persist/integration/react";
import { persistor } from "./data/store/store";
import { store } from "./data/store/store";
import { ProtectedRoute } from "./components/pages/ProtectedRoute"; // Import Prot
import "./index.css";
import { createTheme, ThemeProvider } from "@mui/material/styles";

const theme = createTheme({
  palette: {},
  components: {
    MuiListItem: {
      styleOverrides: {
        root: {
          "&.Mui-selected": {
            // backgroundColor: "red",
          },
        },
      },
    },
  },
});

const App = () => {
  const router = createBrowserRouter(
    createRoutesFromElements(
      <Route errorElement={<ErrorPage />}>
        {/* Routes for the application */}
        <Route element={<ProtectedRoute />}>
          <Route path="/" element={<Root />}>
            <Route index element={<Home />} />
            <Route path="contacts" element={<Contacts />} />
            <Route path="applications" element={<Applications />} />
            <Route path="clients" element={<Clients />} />
            <Route path="maps" element={<Maps />} />
            <Route path="payments" element={<Payments />} />
            <Route path="users" element={<Users />} />
            <Route path="logout" element={<Logout />} />
          </Route>
        </Route>
        
        {/* Public routes */}
        <Route path="signup" element={<Signup />} />
        <Route path="signin" element={<Signin />} />
      </Route>
    )
  );
  
  return (
    <ThemeProvider theme={theme}>
      <Provider store={store}>
        <PersistGate
          loading={
            <div>
              <p>Loading...</p>
            </div>
          }
          persistor={persistor}
        >
          <RouterProvider router={router} />
        </PersistGate>
      </Provider>
    </ThemeProvider>
  );
};

export default App;