import React, { useState } from "react";
import Stack from "@mui/material/Stack";
import LinearProgress from "@mui/material/LinearProgress";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import FormContainer from "./components/form";
import { constants } from "../../../../config/constants";
import { setUser } from "../../../../data/slices/user";

const Signin = () => {
  const [profile, setProfile] = useState({
    password: "",
    email: "",
  });
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleLogin = () => {
    setError(null);

    if (!profile.email || !profile.password) {
      setError("Email and password are required");
      return;
    }

    setLoading(true);

    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: JSON.stringify(profile),
      redirect: "follow",
    };

    fetch(`${constants.apiUrl}/users_login`, requestOptions)
      .then(async (response) => {
        setLoading(false);
        if (!response.ok) {
          throw new Error("Incorrect email or password");
        }
        return response.json();
      })
      .then((result) => {
        console.log('Login result:', result); // Debugging
        if (result.error) {
          setError(result.error);
        } else if (result.user) {
          localStorage.setItem("user", JSON.stringify(result.user));
          localStorage.setItem("token", result.token); // Save token as plain text
          dispatch(setUser({ loaded: true, user: result.user, token: result.token }));
          console.log("navigating to home")
          navigate("/");
        }
      })
      .catch((error) => {
        setLoading(false);
        console.log(error.message)
        setError(error.message || "An error occurred. Please try again.");
      });
  };

  return (
    <Stack>
      {loading && <LinearProgress />}
      <Stack sx={{ height: "100vh", width: "100vw" }} direction="row">
        <FormContainer
          error={error}
          handleLogin={handleLogin}
          loading={loading}
          profile={profile}
          setProfile={setProfile}
        />
      </Stack>
    </Stack>
  );
};

export default Signin;
