 /* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from "react";
import Stack from "@mui/material/Stack";
import * as React from "react";
import Grow from "@mui/material/Grow";
import { useSelector, useDispatch } from "react-redux";
import {
  updateConversation,
  addMessage,
} from "../../../../../data/slices/conversation";

import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import Message from "./message/view";
import WhatsappInput from "./input";
import WhatsappHeader from "./header";
import FileMessage from "./fileMsg";
import { constants } from "../../../../../config/constants";
import { LinearProgress } from "@mui/material";
import { MdError } from "react-icons/md";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  borderRadius: 1,
};

const serialize = function (obj) {
  var str = [];
  for (var p in obj)
    if (obj.hasOwnProperty(p)) {
      str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
    }
  return str.join("&");
};

export default function Conversation() {
  const [files, setFiles] = React.useState([]);

  const [loading, setLoading] = React.useState(false);
  const checked = true;

  const dispatch = useDispatch();

  const conversation = useSelector((state) => state.conversation);

  console.log('conversation',conversation)

  const [dragActive, setDragActive] = React.useState(false);

  const handleDrag = function (e) {
    e.preventDefault();
    e.stopPropagation();
    if (e.type === "dragenter" || e.type === "dragover") {
      setDragActive(true);
    } else if (e.type === "dragleave") {
      setDragActive(false);
    }
  };

  const handleDrop = function (ev) {
    ev.preventDefault();
    ev.stopPropagation();
    setDragActive(false);

    if (ev.dataTransfer.items) {
      [...ev.dataTransfer.items].forEach((item, i) => {
        let temp = [];
        if (item.kind === "file") {
          const file = item.getAsFile();
          // previewFile(file);
          temp.push(file);
        }
        sendFileMsg(temp);
      });
    } else {
      let temp = [];
      [...ev.dataTransfer.files].forEach((file, i) => {
        //  previewFile(file);
        temp.push(file);
      });
      sendFileMsg(temp);
    }
  };

  const sendFileMsg = (list = []) => {
    //

    const allowedTypes = [
      "xls",
      "xlsx",
      "pdf",
      "png",
      "jpeg",
      "jpg",
      "docx",
      "doc",
      "txt",
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      "application/vnd.ms-excel",
    ];
    let valid = true;
    list.forEach((item) => {
      if (
        !allowedTypes.includes(item.type.split("/")[1]) &&
        !allowedTypes.includes(item.type)
      ) {
        valid = false;
        handleOpen();
      }
    });

    if (!valid) {
      return;
    }

    list.forEach((item) => {
      dispatch(
        addMessage({
          file: item,
          _id: "fid" + Date.now().toString(),
          sent: false,
        })
      );
    });
  };

  const handleChange = function (e) {
    e.preventDefault();
    if (e.target.files && e.target.files[0]) {
      let temp = [];
      [...e.target.files].forEach((file, i) => {
        //  previewFile(file);
        temp.push(file);
      });

      sendFileMsg(temp);
    }
  };

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  console.log('conversations',conversation)

  return (
    <Grow in={checked} style={{ transformOrigin: "0 0 0" }}>
      <Stack
        style={{
          background: dragActive
            ? "rgba(0, 140, 243, 0.08)"
            : "rgba(238, 217, 198, 0.0)",
          transition: "all .8s ease",
          WebkitTransition: "all .8s ease",
          MozTransition: "all .8s ease",
        }}
        sx={{
          flex: 1,
          height: "100vh",
          position: "relative",
          pl: 1,
          pr: 1,
        }}
        onDragEnter={conversation.type === "facebook" ? null : handleDrag}
        onDragLeave={conversation.type === "facebook" ? null : handleDrag}
        onDragOver={conversation.type === "facebook" ? null : handleDrag}
        onDrop={conversation.type === "facebook" ? null : handleDrop}
      >
        <WhatsappHeader files={files} setFiles={setFiles} />

        {loading && <LinearProgress />}

        <Stack
          sx={{ p: 3 }}
          style={{
            display: "flex",
            flexDirection: "column-reverse",
            height: "75vh",
            overflow: "auto",
            position: "relative",
          }}
        >
          <Stack sx={{ zIndex: 4 }}>
          {[...conversation.messages].reverse().map((message) =>
            !message.file ? (
              <Message
                channel={"whatsapp"}
                key={message.id}
                message={message}
              />
            ) : (
              <FileMessage
                channel={conversation.type}
                key={message._id}
                message={message}
              />
            )
          )}

          </Stack>
        </Stack>

        <WhatsappInput
          lastMessage={
            conversation.messages.length > 0
              ? conversation.messages[0]
              : null
          }
          handleChange={handleChange}
          phone={conversation.wa_id}
        />
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <Stack direction="row" sx={{ alignItems: "center" }}>
              <MdError style={{ color: "red" }} />
              <Typography sx={{ ml: 1 }} variant="h6" component="h2">
                Unallowed file format
              </Typography>
            </Stack>
            <Typography sx={{ mt: 1 }}>
              Your upload has one or more files with an unallowed format. This
              is the list of allowed formats : "xls", "xlsx", "pdf", "png",
              "jpeg", "jpg", "docx", "doc", "txt",
            </Typography>
          </Box>
        </Modal>
      </Stack>
    </Grow>
  );
}
