import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Grow from "@mui/material/Grow";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import Stack from "@mui/material/Stack";
import MenuHeader from "./menu.header";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import MenuItem from "./menu.item";
import { useDispatch, useSelector } from "react-redux";
import { Outlet } from "react-router-dom";
import Snackbar from "@mui/material/Snackbar";
import { updateSnack } from "../../../data/slices/snack";
import {
  supportMenu,
  settingsMenu,
  socialMenu,
  crmMenu,
} from "./config";
import AuthHandler from "./auth/view";
import { useLocation } from "react-router-dom";

export default function Root() {
  const theme = useTheme();
  const location = useLocation();
  const dispatch = useDispatch();
  const [value, setValue] = useState("");
  const snack = useSelector((state) => state.snack);
  const auth = useSelector((state) => state.auth);

  const handleClose = (event, reason) => {
    dispatch(updateSnack({ open: false, msg: null }));
  };

  const drawerWidth = 220;

  const handleChange = (newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    handleChange(location.pathname.replace("/", ""));
  }, [location]);

  const matches = useMediaQuery(theme.breakpoints.up("md"));

  return (
    <div
      style={{ flexDirection: "row", display: "flex", position: "relative" }}
    >
      {matches && !auth._id && !auth.email && (
        <Box
          style={{}}
          sx={{
            width: drawerWidth,
            height: "100vh",
          }}
        >
          <Drawer
            sx={{
              width: drawerWidth,
              flexShrink: 0,
              "& .MuiDrawer-paper": {
                width: drawerWidth,
                boxSizing: "border-box",
                borderStyle: "none",
                pr: 1,
                pl: 1,
              },
            }}
            variant="permanent"
            anchor="left"
          >
            <MenuHeader />

            <Typography variant="caption" sx={{ ml: 2, mt: 3 }}>
              <b>SUPPORT</b>
            </Typography>
            <List>
              {supportMenu.map((data) => (
                <MenuItem
                  key={data.id} // Add a unique key here
                  data={data}
                  handleChange={handleChange}
                  value={value}
                />
              ))}
            </List>

            <Typography variant="caption" sx={{ ml: 2, mt: 3 }}>
              <b>CRM</b>
            </Typography>
            <List>
              {crmMenu.map((data) => (
                <MenuItem
                  key={data.id} // Add a unique key here
                  data={data}
                  handleChange={handleChange}
                  value={value}
                />
              ))}
            </List>

            <Typography variant="caption" sx={{ ml: 2, mt: 3 }}>
              <b>MANAGE</b>
            </Typography>
            <List>
              {settingsMenu.map((data) => (
                <MenuItem
                  key={data.id} // Add a unique key here
                  data={data}
                  handleChange={handleChange}
                  value={value}
                />
              ))}
            </List>
          </Drawer>
        </Box>
      )}
      <Stack id="detail" style={{ flex: 1 }}>
        <Outlet />
      </Stack>
      <Snackbar
        open={snack.open}
        autoHideDuration={15000}
        onClose={handleClose}
        message={snack.msg}
      />
      {false && !auth._id && <AuthHandler />}
    </div>
  );
}
