import React from 'react';
import Stack from "@mui/material/Stack";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import { MdGetApp } from "react-icons/md";
import { ArrowForward } from "@mui/icons-material";
import { HiPlusCircle } from "react-icons/hi2";
import moment from 'moment';



function numberWithCommas(x) {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

function days_between(date1, date2) {
  const ONE_DAY = 1000 * 60 * 60 * 24;

  const differenceMs = Math.abs(date1 - date2);

  return Math.round(differenceMs / ONE_DAY);
}

//const { format } = require('date-fns');

const formatTimestamp = (seconds, nanoseconds) => {
  if (!seconds || !nanoseconds) return '--';

  const milliseconds = seconds * 1000 + nanoseconds / 1000000;
  const date = moment(milliseconds);
  return date.format('D MMMM HH:mm A'); // Customize format as needed
};


export default function Row({ item, showClient, active, showAssign }) {
  return (
    <Stack
      sx={{
        mb: 1,
        p: 2,
        boxShadow: "5px 5px 24px 1px rgba(0, 0, 0, 0.02)",
        alignItems: "center",
        borderRadius: 1,
      }}
      direction="row"
      spacing={3}
    >
      <Typography sx={{ flex: 1 }}>
        {item.appActive && (
          <MdGetApp style={{ marginRight: 8 }} color="blue" size={16} />
        )}
        {item.profile?.fullname}

      </Typography>

      <Typography sx={{ flex: 1 }}>{item.profile?.makeModel}</Typography>

      <Typography sx={{ flex: 1 }}>
        {active === "new" || active === "declined"
          ? item.lastUpdated && item.lastUpdated.seconds && item.lastUpdated.nanoseconds
            ? formatTimestamp(item.lastUpdated.seconds, item.lastUpdated.nanoseconds)
            : ""
          : item.lastUpdated && item.lastUpdated.seconds && item.lastUpdated.nanoseconds
          ? formatTimestamp(item.lastUpdated.seconds, item.lastUpdated.nanoseconds)
          : ""}
      </Typography>

      <Typography
        sx={{
          flex: 1,
        }}
      >
        {item.id}
      </Typography>
      <Typography sx={{ flex: 1 }}>
        {item.assignee ? (
          item.assignee
        ) : (
          <Button
            startIcon={<HiPlusCircle />}
            onClick={() => {
              showAssign(item.applicationId);
            }}
            sx={{ textTransform: "none", borderRadius: 32 }}
          >
            Assign
          </Button>
        )}
      </Typography>
      <Stack sx={{ flex: 1, alignItems: "center" }}>
        <IconButton
          onClick={() => {
            showClient(item.id); //applicationid
          }}
        >
          <ArrowForward />
        </IconButton>
      </Stack>
    </Stack>
  );
}
