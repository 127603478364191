import { constants } from "../config/constants";

function truncateUserName(str = "", len = 20) {
  return str.length > len ? str.substring(0, len) + "..." : str;
}

function truncateMessage(str = "") {
  if (str == null) {
    return "";
  }
  return str.length > 20 ? str.substring(0, 20) + "..." : str;
}

const saveFile = (
  file,
  completeHandler,
  errorHandler,
  abortHandler,
  server = "africVest"
) => {
  try {
    console.log("Received file", file);
    var formdata = new FormData();
    formdata.append("file", file, file.name);
    var ajax = new XMLHttpRequest();
    // ajax.upload.addEventListener("progress", progressHandler, false);
    ajax.addEventListener("load", completeHandler, false);
    ajax.addEventListener("error", errorHandler, false);
    ajax.addEventListener("abort", abortHandler, false);
    ajax.open(
      "POST",
      `${
        server === "africVest" ? constants.africVestApi : constants.apiUrl
      }/documents`
    );

    ajax.send(formdata);
  } catch (e) {
    console.log(e.message);
  }
};

export { truncateMessage, truncateUserName, saveFile };
