import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import PropTypes from "prop-types";
import { styled } from "@mui/material/styles";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Check from "@mui/icons-material/Check";
import TemplateSelector from "./templates";
import Slide from "@mui/material/Slide";

import MessageInput from "./input";
import StepConnector, {
  stepConnectorClasses,
} from "@mui/material/StepConnector";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const QontoConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 10,
    left: "calc(-50% + 16px)",
    right: "calc(50% + 16px)",
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: "#784af4",
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: "#784af4",
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    borderColor:
      theme.palette.mode === "dark" ? theme.palette.grey[800] : "#eaeaf0",
    borderTopWidth: 3,
    borderRadius: 1,
  },
}));

const QontoStepIconRoot = styled("div")(({ theme, ownerState }) => ({
  color: theme.palette.mode === "dark" ? theme.palette.grey[700] : "#eaeaf0",
  display: "flex",
  height: 22,
  alignItems: "center",
  ...(ownerState.active && {
    color: "#784af4",
  }),
  "& .QontoStepIcon-completedIcon": {
    color: "#784af4",
    zIndex: 1,
    fontSize: 18,
  },
  "& .QontoStepIcon-circle": {
    width: 8,
    height: 8,
    borderRadius: "50%",
    backgroundColor: "currentColor",
  },
}));

function QontoStepIcon(props) {
  const { active, completed, className } = props;

  return (
    <QontoStepIconRoot ownerState={{ active }} className={className}>
      {completed ? (
        <Check className="QontoStepIcon-completedIcon" />
      ) : (
        <div className="QontoStepIcon-circle" />
      )}
    </QontoStepIconRoot>
  );
}

QontoStepIcon.propTypes = {
  /**
   * Whether this step is active.
   * @default false
   */
  active: PropTypes.bool,
  className: PropTypes.string,
  /**
   * Mark the step as completed. Is passed to child components.
   * @default false
   */
  completed: PropTypes.bool,
};

const steps = ["Select a template", "Create a message"];

export default function NewWhatsappMessage({ open, setOpen, setOpenSnack }) {
  const handleClose = () => {
    setOpen(false);
  };
  const [selected, setSelected] = React.useState(null);
  let [step, setStep] = React.useState(0);

  const [found, setFound] = React.useState([]);

  const searchTemplateStrings = () => {
    var rxp = /{{([^}}]+)}/g,
      str = selected.components.map((e) => e.text).join(" "),
      curMatch;
    let temp = [];
    while ((curMatch = rxp.exec(str))) {
      temp.push(curMatch[1]);
    }
    setFound(temp);
  };

  const msgSuccess = () => {
    setOpen(false);
    setOpenSnack(true);
  };

  return (
    <Dialog
      maxWidth="xs"
      width="xs"
      TransitionComponent={Transition}
      open={open}
      onClose={handleClose}
      aria-labelledby="draggable-dialog-title"
    >
      <h3 style={{ margin: 0, marginLeft: 16, marginTop: 24 }}>New Message</h3>
      <Stepper
        sx={{ mr: 2, ml: 2 }}
        activeStep={step}
        connector={<QontoConnector />}
      >
        {steps.map((label) => (
          <Step sx={{ marginTop: 0, pb: 1, pt: 1 }} key={label}>
            <StepLabel sx={{ marginTop: 0 }} StepIconComponent={QontoStepIcon}>
              {label}
            </StepLabel>
          </Step>
        ))}
      </Stepper>
      <DialogContent sx={{ height: "90vh", width: "xs" }}>
        {step === 0 && (
          <TemplateSelector setSelected={setSelected} selected={selected} />
        )}
        {step === 1 && (
          <MessageInput
            msgSuccess={msgSuccess}
            found={found}
            template={selected}
          />
        )}
      </DialogContent>
      <DialogActions sx={{ p: 2, background: "#FBFBFB" }}>
        <Button
          sx={{ textTransform: "none", borderRadius: 32 }}
          autoFocus
          variant="outlined"
          onClick={handleClose}
        >
          Cancel
        </Button>
        <Button
          disabled={!selected || step === 0}
          disableElevation
          variant="contained"
          sx={{ textTransform: "none", borderRadius: 32 }}
          color="info"
          onClick={() => {
            setStep(0);
          }}
        >
          PREV
        </Button>
        <Button
          disabled={!selected || step !== 0}
          disableElevation
          variant="contained"
          sx={{ textTransform: "none", borderRadius: 32 }}
          color="info"
          onClick={() => {
            searchTemplateStrings();
            setStep(1);
          }}
        >
          Next
        </Button>
      </DialogActions>
    </Dialog>
  );
}
