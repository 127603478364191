import Stack from "@mui/material/Stack";
import { Typography } from "@mui/material";

import { BsCheck2 } from "react-icons/bs";

import * as React from "react";
import { IoCheckmarkDone } from "react-icons/io5";

export default function MessageStatus({ status }) {
  return (
    <Stack
      direction="row"
      sx={{ alignSelf: "flex-end", mt: 0.4, alignItems: "center" }}
    >
      {status === "sent" && <BsCheck2 />}
      {status === "delivered" && <IoCheckmarkDone color="#0A8FFF" />}

      {status === "read" && <IoCheckmarkDone color="#00E334" />}

      <Typography variant="caption" sx={{ ml: 0.3, opacity: 0.6 }}>
        {status}
      </Typography>
    </Stack>
  );
}
