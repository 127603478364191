// Import the RTK Query methods from the React-specific entry point
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

import { constants } from "../config/constants";

const baseQuery = fetchBaseQuery({
  baseUrl: constants.apiUrl,
});

export const apiSlice = createApi({
  reducerPath: "api",
  baseQuery,
  refetchOnFocus: true,
  endpoints: () => ({}),
});
