import { useSelector } from "react-redux";
import { Navigate, Outlet } from "react-router-dom";

export const ProtectedRoute = () => {
  // Access token from Redux state or localStorage
  const { token } = useSelector((state) => state.user) || {};

  // If no token, redirect to sign-in page
  return token ? <Outlet /> : <Navigate to="/signin" />;
};
