import Stack from "@mui/material/Stack";
import React,{ useState } from "react";
import Sidebar from "./components/sidebar";
import FormContainer from "./components/form";
import { useNavigate } from "react-router-dom";
import { constants } from "../../../../config/constants";
import { setUser } from "../../../../data/slices/user";
import { useDispatch } from "react-redux";

const Signup = () => {
  const [profile, setProfile] = useState({
    password: "",
    firstName: "",
    lastName: "",
    email: "",
  });
  const navigate = useNavigate();

  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);

  //window.localStorage.removeItem("user");
  //window.localStorage.removeItem("token");

  const [error, setError] = useState(null);

  const handleRegister = () => {
    setError(null);
    setLoading(true);

    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify(profile);

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    setTimeout(() => {
      fetch(constants.apiUrl + "/users/superadmin", requestOptions)
        .then(async (response) => {
          if (response.ok) {
            return response.json();
          }
          throw new Error("A server error occurred");
        })
        .then((result) => {
          if (result.error) {
            setError(result.error);
          } else {
            if (result.user) {
              window.localStorage.setItem("user", JSON.stringify(result.user));
              window.localStorage.setItem(
                "token",
                JSON.stringify(result.token)
              );
              dispatch(setUser({ loaded: true, user: result.user }));
              navigate("/configuration");
            }
          }
          setLoading(false);
        })
        .catch((error) => {
          setLoading(false);
          setError(error.message);
        });
    }, 1500);
  };

  return (
    <Stack>
      <Stack sx={{ height: "100vh", width: "100vw" }} direction="row">
        <FormContainer
          error={error}
          handleRegister={handleRegister}
          loading={loading}
          profile={profile}
          setProfile={setProfile}
        />
        <Sidebar />
      </Stack>
    </Stack>
  );
};
export default Signup;
