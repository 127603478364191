import IconButton from "@mui/material/IconButton";
import Stack from "@mui/material/Stack";
import Chip from "@mui/material/Chip";
import { MdPlayCircle } from "react-icons/md";
import { Typography } from "@mui/material";
import MessageDocument from "./document";
import TimeComponent from "./time";
import Grow from "@mui/material/Grow";
import * as React from "react";
import MessageStatus from "./status";
import ImageComponent from "./image";
import { constants } from "../../../../../../config/constants";

export default function Message({ message, channel }) {
  let whatsapp = message.whatsapp;
  let messenger = message.messenger;
  let comment = message.comment;
  let from,
    type,
    status = null;

  let senderType;

  console.log('message',message)

  if (channel === "whatsapp") {
    type = "text";
    status = "read";
    if (message.role === "assistant") {
      senderType = "admin";
    } else {
      senderType = "client";
    }
  } else if (channel === "messenger") {
    from = messenger.sender.id;
    if (constants.adminPageIDs.includes(from)) {
      senderType = "admin";
    } else {
      senderType = "client";
    }
  } else if (channel === "facebook") {
    from = comment.from.name;
    if (constants.adminPageIDs.includes(comment.from.id)) {
      senderType = "admin";
    } else {
      senderType = "client";
    }
  }

  const style = {
    background: senderType === "admin" ? "#4169E1" : "white",
    borderRadius: 3,
    borderTopLeftRadius: senderType === "client" ? 0 : null,
    borderTopRightRadius: senderType === "client" ? null : 0,
    boxShadow: "5px 5px 24px 1px rgba(0, 0, 0, 0.01)",
    color: senderType === "admin" ? "white" : "black",
  };

  const GrowIn = true;

  return (
    <Grow in={GrowIn} {...(GrowIn ? { timeout: 1100 } : {})}>
      <Stack
        sx={{
          alignSelf: senderType === "admin" ? "flex-end" : "flex-start",
          mb: 2.5,
        }}
      >
        {channel !== "facebook" && (
          <TimeComponent date={message.created_at} senderType={senderType} />
        )}
        {channel === "facebook" && (
          <Chip
            color="info"
            sx={{
              mb: 1,
              alignSelf: senderType === "client" ? "flex-start" : "flex-end",
            }}
            label={comment.from.name}
          />
        )}
        {channel === "whatsapp" && (
          <>
            {type === "text" && (
              <Stack sx={{ ...style, p: 2 }}>
                {type === "text" && (
                  <Typography sx={{ maxWidth: "200px" }}>
                    {message.content[0].text.value}
                  </Typography>
                )}
              </Stack>
            )}
            {type === "document" && (
              <MessageDocument
                filePath={message.whatsapp.filePath || null}
                senderType={senderType}
                document={message.whatsapp.document}
              />
            )}

            {type === "image" && (
              <ImageComponent
                filePath={message.whatsapp.filePath}
                image={message.whatsapp.image}
                senderType={senderType}
              />
            )}

            {type === "audio" && (
              <Stack
                sx={{
                  alignItems: "center",
                  background: "#EAEAEC",
                  p: 1.5,
                  borderRadius: 3,
                  borderTopLeftRadius: senderType === "client" ? 0 : null,
                  borderTopRightRadius: senderType === "client" ? null : 0,
                }}
                direction="row"
              >
                <IconButton>
                  <MdPlayCircle />
                </IconButton>
                <p>audio</p>
              </Stack>
            )}

            {status && <MessageStatus status={"read"} />}
          </>
        )}

        {channel === "messenger" && (
          <>
            {messenger.message.text && (
              <Stack sx={{ ...style, p: 2 }}>
                <Typography sx={{ maxWidth: "200px" }}>
                  {messenger.message.text}
                </Typography>
              </Stack>
            )}
            {messenger.message.attachments.length > 0 && (
              <>
                {messenger.message.attachments[0].type !== "image" ? (
                  <MessageDocument
                    senderType={senderType}
                    filetype={messenger.message.attachments[0].type}
                    url={messenger.message.attachments[0].payload.url}
                  />
                ) : (
                  <ImageComponent
                    url={messenger.message.attachments[0].payload.url}
                    senderType={senderType}
                  />
                )}
              </>
            )}

            {status && <MessageStatus status={status} />}
          </>
        )}
        {channel === "facebook" && (
          <Stack sx={{ ...style, p: 2 }}>
            <Typography sx={{ maxWidth: "200px" }}>
              {comment.message}
            </Typography>
          </Stack>
        )}
        {channel === "facebook" && (
          <Stack sx={{ mt: 0.5, ml: 1 }}>
            <TimeComponent date={message.content[0].created_at} senderType={senderType} />
          </Stack>
        )}
      </Stack>
    </Grow>
  );
}
