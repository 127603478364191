import * as React from "react";
import Typography from "@mui/material/Typography";

import Avatar from "@mui/material/Avatar";
import Stack from "@mui/material/Stack";
import { useSelector } from "react-redux";
import logo from "../../../assets/icon-192.png";
export default function MenuHeader() {
  const user = useSelector((state) => state.user.user);

  return (
    <Stack
      sx={{
        p: 3,
        m: 1,
        borderRadius: 3,
        mt: 2,
      }}
      direction="row"
      spacing={2}
    >
      {false && (
        <Stack direction="column">
          <Typography
            numberOfLines={1}
            style={{
              fontSize: 16,
              fontWeight: "bold",
              maxWidth: "145px",
              display: "-webkit-box",
              overflow: "hidden",
              WebkitBoxOrient: "vertical",
              WebkitLineClamp: 1,
            }}
          >
            {user ? user.firstName + " " + user.lastName : ""}
          </Typography>
        </Stack>
      )}
      <img src={logo} alt="logo" style={{ width: 80, marginLeft: -16, marginBottom: -16 }} />
    </Stack>
  );
}
