/* eslint-disable react-hooks/exhaustive-deps */
import Stack from "@mui/material/Stack";
import * as React from "react";
import SearchComponent from "./components/search";
import LinearProgress from "@mui/material/LinearProgress";
import Grow from "@mui/material/Grow";
import Snackbar from "@mui/material/Snackbar";
import Row from "./row";
import { useSelector } from "react-redux";
import Badge from "@mui/material/Badge";
import { filter } from "./filter";
import MailCompose from "../home/conversation/email/compose/view";
import { Typography } from "@mui/material";
import ClientDialog from "./modal/view";
import ButtonBase from "@mui/material/ButtonBase";
import { List, AutoSizer } from "react-virtualized";
import ConfirmationDialog from "./assign_admin";
import { collection, getFirestore, onSnapshot, getDocs, doc, getDoc } from "firebase/firestore";
import {
  IoCheckmarkCircle,
  IoDocumentTextOutline,
  IoFolderOpenOutline,
  IoList,
  IoPersonOutline,
  IoPersonRemove,
  IoTimeOutline,
} from "react-icons/io5";

const styles = {
  container: {
    flex: 1,
  },
  statContainer: {
    flex: 1,
    marginRight: 24,
  },
  optionBtn: {
    flexDirection: "row",
    paddingHorizontal: 16,
    borderRadius: 32,
    marginRight: 8,
    paddingVertical: 4,
    alignItems: "center",
  },
  optionBtnActive: {
    flexDirection: "row",
    paddingHorizontal: 16,
    borderRadius: 32,
    marginRight: 8,
    backgroundColor: "#ffdaa8",
    paddingVertical: 4,
    alignItems: "center",
  },
};

const Applications = () => {
  const [loading, setLoading] = React.useState(false);

  const [activeStatus, setActiveStatus] = React.useState("new");
  const [applications, setApplications] = React.useState([]);

  const [showClient, setShowClient] = React.useState({
    show: false,
    docId: null,
  });
  const [showAssign, setShowAssign] = React.useState({
    show: false,
    docId: null,
  });

  const [search, setSearch] = React.useState("");

  // Fetch all drivers and their profile data
  React.useEffect(() => {
    const fetchDrivers = async () => {
      setLoading(true);
      try {
        // Fetch all drivers
        const querySnapshot = await getDocs(collection(db, 'drivers'));
        const driversData = await Promise.all(querySnapshot.docs.map(async (docSnap) => {
          const driverId = docSnap.id;
          const driverData = docSnap.data();
          
          // Fetch profile data for each driver
          const profileRef = doc(db, 'drivers', driverId, 'profile', 'profileDocument');
          const profileDoc = await getDoc(profileRef);
          const profileData = profileDoc.exists() ? profileDoc.data() : {};

          return {
            id: driverId,
            ...driverData,
            profile: profileData
          };
        }));

        setApplications(driversData);
        console.log(applications)
      } catch (error) {
        console.error('Error fetching drivers:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchDrivers();
  }, []);


  // Set up real-time listeners for drivers and their profiles
  React.useEffect(() => {
    const unsubscribeDrivers = onSnapshot(collection(db, 'drivers'), (snapshot) => {
      snapshot.docChanges().forEach(async (change) => {
        const driverId = change.doc.id;
        const data = change.doc.data();
        
        if (change.type === 'added' || change.type === 'modified') {
          // Fetch profile data
          const profileDoc = await getDoc(doc(db, 'drivers', driverId, 'profile', 'profileDocument'));
          const profileData = profileDoc.exists() ? profileDoc.data() : {};

          setApplications((prevDrivers) => {
            const updatedDriver = { id: driverId, ...data, profile: profileData };
            return prevDrivers.map(driver => driver.id === driverId ? updatedDriver : driver);
          });
        }

        if (change.type === 'removed') {
          setApplications((prevDrivers) =>
            prevDrivers.filter((driver) => driver.id !== driverId)
          );
        }
      });
    });

    return () => unsubscribeDrivers();
  }, []);


  const user = useSelector((state) => state.auth);

  const [assignmentStatus, setAssignmentStatus] = React.useState("all");

  let filtered = (
    activeStatus === "new"
      ? applications.filter((a) => {
          return a.profile.verified === false && a.fcmToken !== "" && a.fcmToken !== "missing";
        })
      : activeStatus === "approved"
      ? applications.filter((a) => {
          return a.profile.verified === true;
        })
      : activeStatus === "declined"
      ? applications.filter((a) => {
          return a.approved === false;
        })
      : activeStatus === "missingPermission"
      ? applications.filter((a) => {
          return !a.fcmToken || a.fcmToken === "" || a.fcmToken === "missing";
        })
      : activeStatus === "awaitingDelivery"
      ? applications.filter((a) => {
          return a.status === "awaitingDelivery";
        })
      : applications
  )
    .sort(function (x, y) {
      if (activeStatus === "awaitingDelivery") {
        return new Date(x.collectionDate) - new Date(y.collectionDate);
      } else {
        const xSeconds = x.lastUpdated?.seconds ?? 0;
        const ySeconds = y.lastUpdated?.seconds ?? 0;
        return ySeconds - xSeconds || y - x;
      }
    })
  
    .filter((a) => filter(a, search.toLowerCase()))
    .filter((a) => {
      if (assignmentStatus === "all") {
        return a;
      } else {
        return a.assigneeEmail === user.email;
      }
    });

  const db = getFirestore();

  console.log('filtered',filtered)

  React.useEffect(() => {}, []);

  const [composeOpen, setComposeOpen] = React.useState(false);

  const [openSnack, setOpenSnack] = React.useState(false);

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnack(false);
  };

  const closeMail = () => {
    setComposeOpen(false);
    setOpenSnack(true);
  };

  const checked = true;

  const StatBox = ({ title, subtitle, status, count, bg }) => (
    <ButtonBase
      onClick={() => {
        setActiveStatus(status);
      }}
      sx={{ mr: 2, flex: 1, borderRadius: 4 }}
    >
      <Stack
        sx={{
          background: bg,
          p: 3,
          borderRadius: 4,
          flex: 1,
          textAlign: "left",
        }}
      >
        <h3 style={{ margin: 0 }}>
          <b>{title}</b>
        </h3>
        <p>{subtitle}</p>

        <Badge color="info" invisible={activeStatus !== status} variant="dot">
          <Typography sx={{ mt: 2, fontSize: 20 }}>{count}</Typography>
        </Badge>
      </Stack>
    </ButtonBase>
  );

  

  const FilterBtn = ({ status, count = null, icon, title }) => (
    <ButtonBase
      onClick={() => {
        count != null ? setActiveStatus(status) : setAssignmentStatus(status);
      }}
      style={
        count != null
          ? activeStatus === status
            ? styles.optionBtnActive
            : styles.optionBtn
          : assignmentStatus === status
          ? styles.optionBtnActive
          : styles.optionBtn
      }
    >
      <Stack direction="row" sx={{ borderRadius: 3, p: 1 }}>
        {icon}
        <p style={{ marginLeft: 8 }}>{title} </p>
        {count != null && <p> ({count})</p>}
      </Stack>
    </ButtonBase>
  );

  return (
    <Stack
      sx={{
        p: 4,
        pt: 2,
        height: "100vh",
        overflow: "scroll",
        background: "white",
        borderLeft: 1,
        borderLeftStyle: "solid",
        borderLeftColor: "rgb(236, 233, 226, 0.4)",
      }}
    >
      <Grow
        in={checked}
        style={{ transformOrigin: "0 0 0" }}
        {...(checked ? { timeout: 1000 } : {})}
      >
        <Stack direction="row" sx={{ mt: 2, mb: 3 }}>
          <StatBox
            bg="rgb(217, 240, 246)"
            title="New"
            subtitle="Applications"
            status="new"
            count={
              applications.filter((a) => {
                return a.profile.verified === false && a.fcmToken !== "" && a.fcmToken !== "missing";
              }).length
            }
          />

          <StatBox
            bg="#E5FDE6"
            title="Miscellaneous"
            subtitle="Applications"
            status="awaitingDelivery"
            count={
              applications.filter((a) => {
                return a.status === "awaitingDelivery" && a.approved !== false;
              }).length
            }
          />

          <StatBox
            bg="#ECEFF6"
            title="Notifications"
            subtitle="Ungranted"
            status="missingPermission"
            count={
              applications.filter((a) => {
                return a.fcmToken ==="" || a.fcmToken ==="missing";
              }).length
            }
          />

          <StatBox
            bg="#FEE5D2"
            title="Verified"
            subtitle="Drivers"
            status="approved"
            count={
              applications.filter((a) => {
                return a.profile.verified ===  true;
              }).length
            }
          />
        </Stack>
      </Grow>
      <Stack
        direction="row"
        sx={{
          justifyContent: "space-between",

          mt: 3,
          mb: 3,
        }}
      >
        <SearchComponent setSearch={setSearch} />
        <div
          style={{
            backgroundColor: "#fffaf2",
            padding: 4,
            borderRadius: 32,
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          <FilterBtn
            icon={<IoPersonOutline />}
            title="Assigned to me"
            status="assigned"
          />

          <FilterBtn title="All applications" status="all" icon={<IoList />} />
        </div>
        <div
          style={{
            backgroundColor: "#fffaf2",
            padding: 4,
            borderRadius: 32,
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          <FilterBtn
            icon={<IoFolderOpenOutline />}
            title="New"
            status="new"
            count={
              applications.filter((a) => {
                return a.profile.verified === false;
              }).length
            }
          />

          <FilterBtn
            title="Approved"
            status="approved"
            icon={<IoCheckmarkCircle />}
            count={
              applications.filter((a) => {
                return (
                  a.assigneeEmail === user.email &&
                  a.assigneeEmail &&
                  a.verified === true &&
                  a.signed == null &&
                  a.status == null
                );
              }).length
            }
          />

          <FilterBtn
            title="Signed"
            icon={<IoDocumentTextOutline />}
            status="signed"
            count={
              applications.filter(
                (a) =>
                  a.status == null &&
                  a.signed === true &&
                  a.assigneeEmail &&
                  a.assigneeEmail === user.email
              ).length
            }
          />

          <FilterBtn
            icon={<IoTimeOutline />}
            title="Awaiting collection"
            status="awaitingDelivery"
            count={
              applications.filter(
                (a) =>
                  a.status === "awaitingDelivery" &&
                  a.assigneeEmail &&
                  a.assigneeEmail === user.email &&
                  a.approved !== false
              ).length
            }
          />
        </div>
      </Stack>
      <Grow
        in={checked}
        style={{ transformOrigin: "0 0 0" }}
        {...(checked ? { timeout: 1000 } : {})}
      >
        <Stack
          spacing={3}
          direction="row"
          sx={{
            boxShadow: "5px 5px 24px 1px rgb(255, 185, 74, 0.15)",
            mt: 2,
            p: 2,
            borderRadius: 1,
            color: "#4169E1",
          }}
        >
          <Typography sx={{ flex: 1, fontWeight: "bold" }}>Driver</Typography>

          <Typography sx={{ flex: 1, fontWeight: "bold" }}>Make & Model</Typography>

          <Typography sx={{ flex: 1, fontWeight: "bold" }}>
            Date Downloaded
          </Typography>

          <Typography sx={{ flex: 1, fontWeight: "bold" }}>Phone</Typography>
          <Typography sx={{ flex: 1, fontWeight: "bold" }}>
            Assigned To
          </Typography>

          <Typography sx={{ flex: 1, fontWeight: "bold", textAlign: "center" }}>
            Action
          </Typography>
        </Stack>
      </Grow>

      {loading && [].length === 0 && <LinearProgress />}

      {filtered.map((item) => (
        <Row
          key={item.id}
          showClient={(docId) => {
            setShowClient({ show: true, docId });
          }}
          showAssign={(docId) => {
            setShowAssign({ show: true, docId });
          }}
          item={item}
        />
      ))}
      {composeOpen && (
        <MailCompose
          closeMail={closeMail}
          open={composeOpen}
          setOpen={setComposeOpen}
        />
      )}
      <Snackbar
        open={openSnack}
        autoHideDuration={6000}
        onClose={handleClose}
        message="Email Sent"
      />

      {showClient.docId && showClient.show && (
        <ClientDialog
          open={showClient.show}
          setOpen={() => {
            setShowClient({ show: false, docId: null });
          }}
          docId={showClient.docId}
        />
      )}

      {showAssign.docId && showAssign.show && (
        <ConfirmationDialog
          open={showAssign.show}
          setOpen={() => {
            setShowAssign({ show: false, docId: null });
          }}
          docId={showAssign.docId}
        />
      )}
    </Stack>
  );
};

export default Applications;
