/* eslint-disable react-hooks/exhaustive-deps */
import * as React from "react";
import { useState, forwardRef } from "react";
import ChatsHeader from "./header";
import Stack from "@mui/material/Stack";

import { HiGlobeAlt } from "react-icons/hi2";
import ChannelFilter from "./channelFilter";
import { useSelector, useDispatch } from "react-redux";
import { filter, filterChannel, filterAssignment } from "./filter";
import MessageComponent from "./message/view";
import { constants } from "../../../../config/constants";
import MailCompose from "../conversation/email/compose/view";
import MatcherClass from "./matching";
import Skeleton from "@mui/material/Skeleton";
import { resetCompose } from "../../../../data/slices/compose";
import Snackbar from "@mui/material/Snackbar";
import { bulkAddContacts } from "../../../../data/slices/contacts";
import { bulkAddChats } from "../../../../data/slices/chats";
import { preprocessChats } from "./filter";
import { List, AutoSizer } from "react-virtualized";

export default function MessageList() {
  const getChats = () => {
    var requestOptions = {
      method: "GET",
      redirect: "follow",
    };

    fetch(`${constants.apiUrl}/messages`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        dispatch(bulkAddChats(result));
      })
      .catch((error) => console.log("error", error));
  };

  React.useEffect(() => {
    getChats();
  }, []);
  const chats = useSelector((state) => state.chats);
  console.log("chats",chats)

  const rowHeight = 110;

  const auth = useSelector((state) => state.auth);
  const [search, setSearch] = useState("");

  const [composeOpen, setComposeOpen] = useState(false);
  const contacts = useSelector((state) => state.contacts);

  const dispatch = useDispatch();


  //const matcher = new MatcherClass(contacts);

  const [openSnack, setOpenSnack] = useState(false);

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnack(false);
  };

  const closeMail = () => {
    setComposeOpen(false);
    setOpenSnack(true);
  };

  const [mailType, setMailType] = React.useState("all");

  const [channel, setChannel] = useState({
    name: "All Channels",
    icon: <HiGlobeAlt />,
  });

  let list = chats
    .map((message, index) => {
      let msgType = "whatsapp";
      if (message.email) msgType = "email";
      let clientContact = { type: null, contact: null };
      let sender = "";
      if (msgType === "email") {
        let from = [];
        let to = [];
        message.email.to.value.forEach((t) => {
          to.push(t.address);
        });
        message.email.from.value.forEach((t) => {
          from.push(t.address);
        });

        clientContact.type = "email";
        let hasFromAdmin = false;
        constants.adminEmails.forEach((e) => {
          if (from.includes(e)) {
            hasFromAdmin = true;
          }
        });
        if (hasFromAdmin) {
          clientContact.contact = to[0];
          sender = "admin";
        } else {
          clientContact.contact = from[0];
          sender = "client";
        }
      } else if (msgType === "whatsapp") {
        clientContact = {
          contact: message.user_id,
          type: "phone",
        };
      }
      let matchResult = "User";

      return { message, matchResult, sender };
    })
    .filter((a) => filter(a, search))
    .filter((a) => filterChannel(a, channel))
    .filter((a) => filterAssignment(a, mailType, auth._id))
    .sort((a, b) => new Date(b.message.message[0].created_at) - new Date(a.message.message[0].created_at)); // Sort by timestamp descending

  function rowRenderer({ index, key, style }) {
    const data = list[index];
    console.log('data',data)
    //label={conversation.match.type || "No match"}
    const user_id = data.message.user_id
    const { message } = data;
    let sender = user_id;
    const Name = data.message.Name !== '' ? data.message.Name : sender;

    let matchResult ={ type: data.message.Account_type, 
      contact: Name, 
      balance:data.message.wallet_balance, 
      transactions: data.message.transaction_history,
      rides: data.message.rides,
      Account_status: data.message.Account_status,
      car_make_and_model: data.message.car_make_and_model,
      docId:user_id,
      email: data.message.Email,
      thread_id: data.message.thread_id
    };

    let messenger = message.messenger;

    let person_id = "";

    if (messenger != null) {
      if (
        messenger.recipient &&
        constants.adminPageIDs.includes(messenger.recipient.id)
      ) {
        if (messenger.sender) person_id = messenger.sender.id;
      } else {
        if (messenger.recipient) person_id = messenger.recipient.id;
      }
    }

    const working_id = user_id

    return (
      <div key={key} style={style}>
        <MessageComponent
          match={matchResult}
          sender={sender}
          key={working_id}
          message={message}
          working_id={working_id}
        />
      </div>
    );
  }

  return (
    <Stack
      sx={{
        width: "340px",
        borderLeftWidth: 0,
        borderTopWidth: 0,
        borderBottomWidth: 0,
        m: 1,
        ml: 2,
        mr: 0,
      }}
    >
      <ChannelFilter
        mailType={mailType}
        setMailType={setMailType}
        channel={channel}
        setChannel={setChannel}
      />
      <Stack
        sx={{
          boxShadow: "5px 5px 24px 1px rgba(0,0, 0, 0.04)",
          background: "white",
          borderRadius: 2,
        }}
      >
        <ChatsHeader
          setOpenSnack={setOpenSnack}
          openCompose={() => {
            dispatch(resetCompose());
            setComposeOpen(true);
          }}
          setSearch={setSearch}
        />
        <Stack sx={{ pt: 0, height: "68vh", overflow: "scroll", pb: 40 }}>
          {chats.length === 0 &&
            [{}, {}, {}, {}].map((e) => (
              <Stack sx={{ p: 3 }} spacing={1}>
                <Stack direction="row">
                  <Skeleton
                    sx={{ mr: 2 }}
                    variant="circular"
                    width={50}
                    height={50}
                  />
                  <Stack>
                    <Skeleton variant="text" sx={{ fontSize: "1rem" }} />

                    <Skeleton variant="rectangular" width={210} height={60} />
                  </Stack>
                </Stack>
              </Stack>
            ))}

          <AutoSizer>
            {({ width, height }) => (
              <List
                onScroll={(e) => {
                  console.log(e);
                  if (e) {
                    const bottom =
                      e.scrollHeight - e.scrollTop === e.clientHeight;
                    if (bottom) {
                      console.log("bottom");
                    }
                  }
                }}
                width={width}
                height={height}
                rowHeight={rowHeight}
                rowRenderer={rowRenderer}
                rowCount={list.length}
                overscanRowCount={3}
              />
            )}
          </AutoSizer>
        </Stack>
      </Stack>
      {composeOpen && (
        <MailCompose
          closeMail={closeMail}
          open={composeOpen}
          setOpen={setComposeOpen}
        />
      )}
      <Snackbar
        open={openSnack}
        autoHideDuration={6000}
        onClose={handleClose}
        message="Message Sent"
      />
    </Stack>
  );
}
