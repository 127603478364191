/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { IconButton, Typography } from "@mui/material";
import Stack from "@mui/material/Stack";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Grow from "@mui/material/Grow";
import Divider from "@mui/material/Divider";
import SelectAssignee from "../assignments/view";
import CircularProgress from "@mui/material/CircularProgress";
import Snackbar from "@mui/material/Snackbar";
import Fade from "@mui/material/Fade";
import { MdReply, MdReplyAll } from "react-icons/md";
import MailCompose from "./compose/view";
import { HiPlusCircle } from "react-icons/hi2";
import { constants } from "../../../../../config/constants";
import Attachments from "./attachments";
import { IoAttachOutline } from "react-icons/io5";
import Badge from "@mui/material/Badge";
import { styled } from "@mui/material/styles";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import DocViewer from "../doc-viewer/view";
import { useDispatch } from "react-redux";

import { MdInfo } from "react-icons/md";
import {
  resetCompose,
  updateCompose,
} from "../../../../../data/slices/compose";

const HtmlTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    background: "white",
    fontSize: theme.typography.pxToRem(12),
    boxShadow: "5px 5px 24px 1px rgba(0, 0, 0, 0.1)",
    color: "black",
    padding: 16,
    borderRadius: 12,
    maxWidth: null,
    width: "400px",
  },
}));

const EmailConversation = () => {
  const [message, setMessage] = useState(null);
  const [attachOpen, setAttachOpen] = useState(false);
  const conversation = useSelector((state) => state.conversation);

  useEffect(() => {
    var requestOptions = {
      method: "PATCH",
      redirect: "follow",
    };

    fetch(
      `${constants.apiUrl}/messages/read/email/${conversation._id}`,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {})
      .catch((error) => console.log("error", error));
  }, [conversation._id]);

  const checked = true;

  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setMessage(null);
    setLoading(true);

    dispatch(resetCompose);

    var requestOptions = {
      method: "GET",
      redirect: "follow",
    };

    fetch(
      `${constants.apiUrl}/messages/email/${conversation._id}`,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        setLoading(false);

        setMessage(result);
        if (result.assignee) setReassigned(result.assignee);
      })
      .catch((error) => {
        setLoading(false);
        alert("Error loading email");
        console.log("error", error);
      });
  }, [conversation.messageId]);

  const [composeOpen, setComposeOpen] = useState(false);

  let userName = "";
  if (message) {
    if (conversation.sender === "client") {
      userName =
        message.email.from.value[0].name &&
        message.email.from.value[0].name !== ""
          ? message.email.from.value[0].name
          : "No name";
    } else {
      if (message.email.to.value.length > 1) {
        userName = "Multiple recipients";
      } else {
        userName =
          message.email.to.value[0].name &&
          message.email.to.value[0].name !== ""
            ? message.email.from.value[0].name
            : "No name";
      }
    }
  }

  const [doc, setDoc] = useState({
    _id: null,
    fileType: null,
    filename: null,
  });
  const [docViewerOpen, setDocViewerOpen] = useState(false);

  const [openSnack, setOpenSnack] = useState(false);

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnack(false);
  };

  const closeMail = () => {
    setComposeOpen(false);
    setOpenSnack(true);
  };

  const [reassigned, setReassigned] = useState(null);
  const [open, setOpen] = useState(false);

  return (
    <Grow in={checked}>
      <Stack
        sx={{
          flex: 1,
          background: "white",
          ml: 1,
          borderRadius: 2,
          mt: 1,
          mr: 1,
        }}
      >
        {loading && (
          <CircularProgress style={{ alignSelf: "center", marginTop: 100 }} />
        )}
        {message && (
          <Fade in={checked}>
            <Stack
              direction="row"
              sx={{
                p: 2,
                flex: 1,
                borderRadius: 2,
                m: 1,
                boxShadow: "5px 5px 16px 1px rgba(0, 0, 0, 0.05)",
                mb: 0,
                zIndex: 20,
                justifyContent: "space-between",
              }}
            >
              {conversation.sender === "client" ? (
                <Stack>
                  <h4 style={{ margin: 0 }}>{userName}</h4>
                  <Typography variant="caption">
                    {message.email.from.value
                      .map((email) => email.address)
                      .join(", ")}
                  </Typography>
                </Stack>
              ) : (
                <Stack direction="row">
                  <Typography variant="caption" sx={{ mr: 2 }}>
                    To:
                  </Typography>
                  <Stack>
                    {userName}
                    <Typography variant="caption">
                      {message.email.to.value
                        .map((email) => email.address)
                        .join(", ")}
                    </Typography>
                  </Stack>
                </Stack>
              )}
              {conversation.sender === "client" ? (
                <Stack sx={{ ml: 2 }}>
                  <Stack direction="row">
                    <IconButton
                      onClick={() => {
                        setComposeOpen(true);
                        dispatch(
                          updateCompose({
                            to: [
                              ...message.email.from.value.map((e) => e.address),
                            ],
                            inReplyTo: message.email.messageId,
                            subject: message.email.subject,
                          })
                        );
                      }}
                    >
                      <MdReply />
                    </IconButton>
                    {false && (
                      <IconButton>
                        <MdReplyAll />
                      </IconButton>
                    )}
                    {message && message.email.attachments.length > 0 && (
                      <HtmlTooltip
                        placement="top-start"
                        open={attachOpen && !docViewerOpen}
                        title={
                          <Attachments
                            setDoc={setDoc}
                            setDocViewerOpen={setDocViewerOpen}
                            close={() => {
                              setAttachOpen(false);
                            }}
                            attachments={message.email.attachments}
                          />
                        }
                      >
                        <IconButton
                          onClick={() => {
                            setAttachOpen(true);
                          }}
                          sx={{ mr: 2 }}
                        >
                          <Badge
                            color="info"
                            variant="standard"
                            badgeContent={message.email.attachments.length}
                          >
                            <IoAttachOutline />
                          </Badge>
                        </IconButton>
                      </HtmlTooltip>
                    )}

                    <IconButton
                      sx={{ ml: 1 }}
                      onClick={() => {
                        setOpen(true);
                      }}
                    >
                      <MdInfo size={16} />
                    </IconButton>
                  </Stack>
                </Stack>
              ) : (
                <Stack>
                  <Stack direction="row">
                    <IconButton
                      onClick={() => {
                        setComposeOpen(true);
                        dispatch(
                          updateCompose({
                            to: [
                              ...message.email.to.value.map((e) => e.address),
                            ],
                          })
                        );
                      }}
                    >
                      <HiPlusCircle />
                    </IconButton>
                  </Stack>
                </Stack>
              )}
            </Stack>
          </Fade>
        )}
        {message && (
          <Grow in={checked}>
            <Stack sx={{ height: "100%", overflow: "scroll", p: 2.5, pt: 3 }}>
              <Typography variant="caption">
                {new Date(message.date).toDateString()}
              </Typography>
              <h4 style={{ margin: 0 }}>{message.email.subject}</h4>
              <Divider sx={{ mt: 2, mb: 2, opacity: 0.7 }} />
              <iframe
                title="Email"
                height="100%"
                id="email_content"
                width="100%"
                style={{
                  margin: 0,
                  border: 0,
                  padding: 0,
                }}
                srcDoc={message.email.html}
              ></iframe>
            </Stack>
          </Grow>
        )}
        {composeOpen && (
          <MailCompose
            closeMail={closeMail}
            open={composeOpen}
            setOpen={setComposeOpen}
          />
        )}
        {docViewerOpen && (
          <DocViewer
            filename={doc.filename}
            open={docViewerOpen}
            setOpen={setDocViewerOpen}
            id={doc._id}
            fileType={doc.fileType}
          />
        )}

        <Snackbar
          open={openSnack}
          autoHideDuration={6000}
          onClose={handleClose}
          message="Message Sent"
        />
        <SelectAssignee
          reassigned={reassigned}
          setReassigned={setReassigned}
          open={open}
          setOpen={setOpen}
        />
      </Stack>
    </Grow>
  );
};

export default EmailConversation;
