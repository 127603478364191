/* eslint-disable react-hooks/exhaustive-deps */
import Stack from "@mui/material/Stack";
import * as React from "react";
import Grid from "@mui/material/Grid";
import SearchComponent from "./components/search";
import { filter } from "./filter";
import LinearProgress from "@mui/material/LinearProgress";
import Grow from "@mui/material/Grow";
import Snackbar from "@mui/material/Snackbar";
import User from "./user/view";
import MailCompose from "../home/conversation/email/compose/view";
import { useGetUsersQuery } from "../../../services/api-endpoints/user";
import IconButton from "@mui/material/IconButton";
import { useSelector } from "react-redux";
import { ClickAwayListener } from "@mui/base";
import { styled } from "@mui/material/styles";
import { HiPlusCircle } from "react-icons/hi2";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import NewUser from "./new-user/view";

const HtmlTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    background: "white",
    width: 250,
    fontSize: theme.typography.pxToRem(12),
    boxShadow: "5px 5px 24px 1px rgba(0, 0, 0, 0.25)",
    color: "black",
    padding: 16,
    borderRadius: 12,
  },
}));

const Users = () => {
  const { data: users = [], isLoading, isRefetching } = useGetUsersQuery();
  const [search, setSearch] = React.useState("");

  const [composeOpen, setComposeOpen] = React.useState(false);

  const [openSnack, setOpenSnack] = React.useState(false);

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnack(false);
  };

  const closeMail = () => {
    setComposeOpen(false);
    setOpenSnack(true);
  };

  const [open, setOpen] = React.useState(false);

  const handleTooltipClose = () => {
    setOpen(false);
  };

  const handleTooltipOpen = () => {
    setOpen(true);
    setOpen(true);
  };

  const checked = true;

  const auth = useSelector((state) => state.auth);
  let isAdmin = auth.role === "admin";

  return (
    <Stack sx={{ p: 4, pt: 2 }}>
      <Grow
        in={checked}
        style={{ transformOrigin: "0 0 0" }}
        {...(checked ? { timeout: 1000 } : {})}
      >
        <Stack
          direction="row"
          sx={{
            boxShadow: "5px 5px 24px 1px rgba(0, 137, 254, 0.07)",
            p: 2,
            borderRadius: 2,
            // background: "rgba(0, 236, 255, 0.04)",
            justifyContent: "space-between",

            alignItems: "center",
          }}
        >
          <Stack direction="row" sx={{ alignItems: "center" }}>
            <h1 style={{ margin: 0 }}>
              Users{" "}
              <span style={{ color: "#004449" }}>
                {" (" + users.length + ")"}
              </span>
            </h1>
            <ClickAwayListener onClickAway={handleTooltipClose}>
              <div>
                <HtmlTooltip
                  placement="right-start"
                  open={open}
                  title={<NewUser handleTooltipClose={handleTooltipClose} />}
                >
                  <IconButton sx={{ ml: 2 }} onClick={handleTooltipOpen}>
                    <HiPlusCircle />
                  </IconButton>
                </HtmlTooltip>
              </div>
            </ClickAwayListener>
          </Stack>
          <SearchComponent setSearch={setSearch} />
        </Stack>
      </Grow>

      {(isLoading || isRefetching) && <LinearProgress />}

      <Stack sx={{ height: "90vh", overflow: "scroll", mt: 2 }}>
        <Grid container columns={{ xs: 12, sm: 12, md: 12 }}>
          {users
            .filter((a) => filter(a, search.toLowerCase()))
            .map((user, index) => (
              <Grid key={user._id} item sx={{ p: 1.5 }} xs={12} sm={4} md={4}>
                <Grow
                  in={checked}
                  style={{ transformOrigin: "0 0 0" }}
                  {...(checked ? { timeout: 1000 } : {})}
                >
                  <div>
                    <User
                      showEdit={isAdmin}
                      ownEmail={auth.email}
                      setComposeOpen={setComposeOpen}
                      user={user}
                    />
                  </div>
                </Grow>
              </Grid>
            ))}
        </Grid>
      </Stack>
      {composeOpen && (
        <MailCompose
          closeMail={closeMail}
          open={composeOpen}
          setOpen={setComposeOpen}
        />
      )}
      <Snackbar
        open={openSnack}
        autoHideDuration={6000}
        onClose={handleClose}
        message="Email Sent"
      />
    </Stack>
  );
};

export default Users;
