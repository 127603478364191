/* eslint-disable react-hooks/exhaustive-deps */
import * as React from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Stack from "@mui/material/Stack";
import Slide from "@mui/material/Slide";
import Collapse from "@mui/material/Collapse";
import LinearProgress from "@mui/material/LinearProgress";
import Chip from "@mui/material/Chip";
import InputAdornment from "@mui/material/InputAdornment";

import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import Checkbox from "@mui/material/Checkbox";
import { NumericFormatCustom } from "../../../layouts/inputs/view";
import { constants } from "../../../../config/constants";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";

import { Divider, IconButton, Typography } from "@mui/material";
import { HiMinusCircle, HiPlusCircle } from "react-icons/hi2";
import { CiPaperplane } from "react-icons/ci";
import dayjs from "dayjs";

const apiUrl = constants.apiUrl;
// eslint-disable-next-line react-hooks/exhaustive-deps

class LineItem extends React.PureComponent {
  state = {
    description: this.props.item.description,
    cost: this.props.item.cost,
    quantity: this.props.item.quantity,
  };

  render() {
    const { item, index, setItems, items, removeItem } = this.props;

    const updateVal = (key, val) => {
      this.setState({
        [key]: val,
      });
      let copy = [...items];
      copy[index][key] = val;

      setItems([...copy]);
    };

    return (
      <Stack sx={{ mt: 3 }} direction="row">
        <TextField
          sx={{
            width: "40ch",
            mr: 1,
          }}
          onChange={(e) => {
            updateVal("description", e.target.value);
          }}
          //  defaultValue={this.state.description}
          value={this.state.description}
          label="Description"
        />
        <TextField
          sx={{
            width: "30ch",
            mr: 1,
          }}
          fullWidth
          inputProps={{ decimalScale: 2 }}
          onChange={(e) => {
            updateVal("cost", e.target.value);
          }}
          value={this.state.cost}
          label="Cost"
          InputProps={{
            inputComponent: NumericFormatCustom,
            startAdornment: <InputAdornment position="start">R</InputAdornment>,
          }}
        />
        <TextField
          sx={{
            width: "30ch",
          }}
          fullWidth
          inputProps={{ decimalScale: 2 }}
          InputProps={{
            inputComponent: NumericFormatCustom,
          }}
          onChange={(e) => {
            updateVal("quantity", e.target.value);
          }}
          value={this.state.quantity}
          label="Qty"
        />
        <Stack sx={{ alignItems: "center", flex: 1 }}>
          <IconButton
            onClick={() => {
              removeItem(item.key);
            }}
            sx={{ ml: 2, mt: 1 }}
          >
            <HiMinusCircle />
          </IconButton>
        </Stack>
      </Stack>
    );
  }
}
function numberWithCommas(x) {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

function reducer(accumulator = 0, currentValue, index) {
  const returns =
    accumulator +
    (currentValue.cost === "" || !currentValue.cost ? 0 : currentValue.cost) *
      (currentValue.quantity === "" || !currentValue.quantity
        ? 0
        : currentValue.quantity);

  return returns;
}

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function NewInvoice({
  open,
  setOpen,
  user,
  invoiceData,
  getInvoice,
}) {
  const handleClose = () => {
    setOpen(false);
  };

  const [loading, setLoading] = React.useState(false);

  const [invoice, setInvoice] = React.useState({
    dueDate: null,
    email: user.email,
    reference: user.car,
    client: user.name,
  });

  const [defaultItems, setDefaultItems] = React.useState({
    subscription: {
      included:
        invoiceData && invoiceData.items.find((a) => a.isSubscription)
          ? true
          : false,
      cost: user.subscription,
      quantity: 1,
      description: "Monthly Subscription",
      isSubscription: true,
    },
    mileage: {
      included:
        invoiceData && invoiceData.items.find((a) => a.isTopup) ? true : false,
      cost: parseFloat(user.costKM).toFixed(2),
      quantity:
        invoiceData && invoiceData.items.find((a) => a.isTopup)
          ? invoiceData.items.find((a) => a.isTopup).quantity
          : user.balanceKM < 0
          ? Math.abs(user.balanceKM)
          : "",
      description: "Excess Mileage Fee",
      isTopup: true,
    },
  });

  const [error, setError] = React.useState(null);

  const [items, setItems] = React.useState([
    ...(invoiceData ? invoiceData.items : []),
  ]);

  let missingInItems = false;
  items.forEach((item) => {
    missingInItems = false;
    if (item.quantity === "" || item.description === "" || item.cost === "") {
      missingInItems = true;
    }
  });

  let missingInDefaults = false;

  if (
    (defaultItems.subscription.included &&
      defaultItems.subscription.cost === "") ||
    (defaultItems.mileage.included && defaultItems.mileage.quantity === "")
  ) {
    missingInDefaults = true;
  } else {
    missingInDefaults = false;
  }

  const disableSend =
    error ||
    !invoice.dueDate ||
    missingInItems ||
    missingInDefaults ||
    loading ||
    (items.length === 0 &&
      !defaultItems.subscription.included &&
      !defaultItems.mileage.included);

  const invoice_total =
    items.reduce(reducer, 0) +
    (defaultItems.subscription.included
      ? defaultItems.subscription.cost !== ""
        ? parseFloat(defaultItems.subscription.cost)
        : 0
      : 0) +
    (defaultItems.mileage.included
      ? defaultItems.mileage.cost !== ""
        ? parseFloat(defaultItems.mileage.cost) *
          (defaultItems.mileage.quantity !== ""
            ? parseFloat(defaultItems.mileage.quantity)
            : "")
        : 0
      : 0);

  React.useEffect(() => {
    invoiceData &&
      setInvoice((invoice) => ({
        ...invoice,
        dueDate: dayjs(invoiceData.dueDate),
      }));
  }, []);

  const sendInvoice = () => {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      dueDate: invoice.dueDate,
      initiatedBy: !invoiceData ? "admin" : invoiceData.initiatedBy,
      userID: user.userID,
      carReg: user.carReg,
      items: [
        ...items,
        ...(defaultItems.subscription.included
          ? [defaultItems.subscription]
          : []),
        ...(defaultItems.mileage.included ? [defaultItems.mileage] : []),
      ],
      total: invoice_total,
      name: user.name,
      car: user.car,
      email: user.email,
    });

    var requestOptions = {
      method: invoiceData ? "PATCH" : "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    setLoading(true);

    const localUrl = "http://localhost:4000";

    fetch(
      `${apiUrl}/invoices${
        invoiceData ? invoiceData && "/" + invoiceData._id : ""
      }`,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        getInvoice && getInvoice();
        setLoading(false);
        setOpen(false);
      })
      .catch((error) => {
        setLoading(false);
        alert("Failed to send invoice");
      });
  };

  return (
    <Dialog
      maxWidth="md"
      TransitionComponent={Transition}
      open={open}
      onClose={handleClose}
    >
      <DialogTitle>
        {!invoiceData ? "New Invoice" : `Edit ${invoiceData.invoiceNumber}`}
      </DialogTitle>

      <DialogContent
        sx={{
          width: 550,
          "& fieldset": {
            borderRadius: 2,
          },
          "& .MuiOutlinedInput-root": {
            "& fieldset": {
              borderColor: "rgba(0, 0, 0, 0.05)",
            },
          },
        }}
      >
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DatePicker
            sx={{
              "& fieldset": {
                borderRadius: 2,
              },
              "& .MuiOutlinedInput-root": {
                "& fieldset": {
                  borderColor: "rgba(0, 0, 0, 0.05)",
                },
              },
              mt: 2,
              width: "100%",
            }}
            fullWidth
            label="Invoice due date"
            format="DD/MM/YYYY"
            onError={(newError) => setError(newError)}
            value={invoice.dueDate}
            renderInput={(props) => <TextField sx={{ mb: 2 }} {...props} />}
            onChange={(newValue) => {
              setInvoice((invoice) => ({ ...invoice, dueDate: newValue }));
            }}
          />
        </LocalizationProvider>
        <TextField
          sx={{
            mt: 3,
          }}
          fullWidth
          defaultValue={invoice.email}
          value={invoice.email}
          onChange={(newValue) => {
            // setInvoice((invoice) => ({ ...invoice, dueDate: newValue }));
          }}
          label="Email address"
        />

        <TextField
          sx={{
            mt: 3,
          }}
          fullWidth
          defaultValue={invoice.reference}
          value={invoice.reference}
          onChange={(newValue) => {
            // setInvoice((invoice) => ({ ...invoice, dueDate: newValue }));
          }}
          label="Reference"
        />
        <TextField
          sx={{
            mt: 3,
          }}
          fullWidth
          defaultValue={invoice.client}
          value={invoice.client}
          onChange={(newValue) => {
            // setInvoice((invoice) => ({ ...invoice, dueDate: newValue }));
          }}
          label="Client"
        />

        <Typography sx={{ mt: 2 }}>Items</Typography>
        <Stack sx={{ mt: 2 }} direction="row">
          <TextField
            sx={{
              width: "40ch",
              mr: 1,
            }}
            value="Subscription"
            onChange={(e) => {}}
            label="Description"
          />
          <TextField
            sx={{
              width: "30ch",
              mr: 1,
            }}
            fullWidth
            value={defaultItems.subscription.cost}
            onChange={(e) => {
              setDefaultItems((defItems) => ({
                ...defItems,
                subscription: {
                  ...defItems.subscription,
                  cost: e.target.value,
                },
              }));
            }}
            label="Cost"
            inputProps={{ decimalScale: 2 }}
            InputProps={{
              inputComponent: NumericFormatCustom,
              startAdornment: (
                <InputAdornment position="start">R</InputAdornment>
              ),
            }}
          />
          <TextField
            sx={{
              width: "30ch",
            }}
            disabled
            fullWidth
            value="1"
            InputProps={{
              inputComponent: NumericFormatCustom,
            }}
            label="Qty"
            inputProps={{ decimalScale: 2 }}
          />
          <Stack sx={{ alignItems: "center", flex: 1 }}>
            <Checkbox
              sx={{
                ml: 2,
                mt: 1,
                borderRadius: 32,
                "input:hover ~ &": {
                  backgroundColor: "red",
                },
              }}
              color="info"
              onChange={(e) => {
                setDefaultItems((defItems) => ({
                  ...defItems,
                  subscription: {
                    ...defItems.subscription,
                    included: e.target.checked,
                  },
                }));
              }}
              defaultChecked={defaultItems.subscription.included}
              value={defaultItems.subscription.included}
            />
          </Stack>
        </Stack>

        <Stack sx={{ mt: 3 }} direction="row">
          <TextField
            sx={{
              width: "40ch",
              mr: 1,
            }}
            value="Mileage Fee"
            onChange={(e) => {}}
            label="Description"
          />
          <TextField
            sx={{
              width: "30ch",
              mr: 1,
            }}
            fullWidth
            disabled
            inputProps={{ decimalScale: 2 }}
            value={defaultItems.mileage.cost}
            onChange={(e) => {}}
            label="Cost"
            InputProps={{
              inputComponent: NumericFormatCustom,
              startAdornment: (
                <InputAdornment position="start">R</InputAdornment>
              ),
            }}
          />
          <TextField
            sx={{
              width: "30ch",
            }}
            fullWidth
            value={defaultItems.mileage.quantity}
            inputProps={{ decimalScale: 2 }}
            InputProps={{
              inputComponent: NumericFormatCustom,
            }}
            onChange={(e) => {
              setDefaultItems((defItems) => ({
                ...defItems,
                mileage: { ...defItems.mileage, quantity: e.target.value },
              }));

              console.log(defaultItems);
            }}
            label="Qty"
          />
          <Stack sx={{ alignItems: "center", flex: 1 }}>
            <Checkbox
              onChange={(e) => {
                setDefaultItems((defItems) => ({
                  ...defItems,
                  mileage: { ...defItems.mileage, included: e.target.checked },
                }));
              }}
              defaultChecked={defaultItems.mileage.included}
              value={defaultItems.mileage.included}
              sx={{ ml: 2, mt: 1 }}
              color="info"
            />
          </Stack>
        </Stack>

        {items.map((item, index) => (
          <LineItem
            setItems={(items) => {
              setItems([...items]);
            }}
            key={item._id ? item._id : item.key}
            removeItem={(key) => {
              setItems((items) => [...items.filter((a) => a.key !== key)]);
            }}
            index={index}
            items={items}
            item={item}
          />
        ))}

        <Divider sx={{ mt: 3, opacity: 0.4 }} />
        <Collapse in={loading}>
          <LinearProgress />
        </Collapse>
        <Stack
          direction="row"
          sx={{ alignItems: "center", justifyContent: "space-between" }}
        >
          <Stack direction="row" sx={{ alignItems: "center" }}>
            <Chip
              label={`Total: R${numberWithCommas(invoice_total.toFixed(2))}`}
            />
            <Button
              color="info"
              onClick={() => {
                setItems((items) => [
                  ...items,
                  {
                    description: "",
                    cost: "",
                    quantity: "",
                    key: "key_" + new Date().getTime().toString(),
                  },
                ]);
              }}
              sx={{
                textTransform: "none",
                mt: 3,
                mb: 3,
                ml: 1,
                borderRadius: 32,
              }}
              startIcon={<HiPlusCircle />}
            >
              Add Row
            </Button>
          </Stack>
          <Button
            color="info"
            variant="contained"
            disableElevation
            disabled={disableSend}
            onClick={sendInvoice}
            sx={{
              textTransform: "none",
              mt: 3,
              mb: 3,
              ml: 1,
              borderRadius: 32,
            }}
            startIcon={<CiPaperplane />}
          >
            Send Invoice
          </Button>
        </Stack>
      </DialogContent>
      <DialogActions sx={{}}>
        <Button
          color="warning"
          sx={{ width: 80, textTransform: "none" }}
          onClick={handleClose}
        >
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
}
