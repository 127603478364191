import * as React from "react";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import LinearProgress from "@mui/material/LinearProgress";
import TextField from "@mui/material/TextField";
import { getFirestore, doc, updateDoc, increment } from "firebase/firestore";

import InputAdornment from "@mui/material/InputAdornment";
import { NumericFormatCustom } from "../../../layouts/inputs/view";

export default function AdjustBalance({ handleTooltipClose, userID, costKM }) {
  const [value, setValue] = React.useState("");

  const [fiat, setFiat] = React.useState("");

  const [loading, setLoading] = React.useState(false);

  const db = getFirestore();

  const saveBalance = async () => {
    setLoading(true);
    const docRef = doc(db, "clients", userID);

    await updateDoc(docRef, {
      balanceKM: increment(value),
      balanceFiat: increment(fiat),
    });
    setLoading(false);
    handleTooltipClose();
  };

  return (
    <React.Fragment>
      <Typography variant="h6">
        <b>Adjust wallet balance</b>
      </Typography>
      <Typography variant="caption">
        Enter a positive or negative number to adjust the wallet balance
      </Typography>
      {loading && <LinearProgress sx={{ mt: 1, borderRadius: 32 }} />}
      <TextField
        sx={{
          "& fieldset": {
            borderRadius: 32,
          },
          "& .MuiOutlinedInput-root": {
            "& fieldset": {
              borderColor: "rgba(0, 0, 0, 0.05)",
            },
          },
          mt: 3,
        }}
        fullWidth
        value={value}
        onChange={(e) => {
          setValue(e.target.value);
          setFiat(parseFloat(costKM) * parseFloat(e.target.value));
        }}
        inputProps={{ decimalScale: 2 }}
        label="Mileage adjustment"
        InputProps={{
          inputComponent: NumericFormatCustom,
          endAdornment: <InputAdornment position="end">km</InputAdornment>,
        }}
      />

      <TextField
        sx={{
          "& fieldset": {
            borderRadius: 32,
          },
          "& .MuiOutlinedInput-root": {
            "& fieldset": {
              borderColor: "rgba(0, 0, 0, 0.05)",
            },
          },
          mt: 3,
        }}
        inputProps={{ decimalScale: 2 }}
        fullWidth
        value={fiat}
        onChange={(e) => {
          setFiat(e.target.value);

          setValue(parseFloat(e.target.value) / parseFloat(costKM));
        }}
        label="Balance adjustment"
        InputProps={{
          inputComponent: NumericFormatCustom,
          startAdornment: <InputAdornment position="start">R</InputAdornment>,
        }}
      />

      <Stack sx={{ mt: 2, mb: 2 }}>
        <Button
          onClick={saveBalance}
          disabled={value === "" || fiat === "" || loading}
          disableElevation
          variant="contained"
          color="info"
          sx={{ mr: 1, borderRadius: 32, height: 45 }}
        >
          Save
        </Button>
        <Button
          onClick={handleTooltipClose}
          disableElevation
          color="warning"
          variant="outlined"
          sx={{ mr: 1, borderRadius: 32, mt: 1, height: 45 }}
        >
          Cancel
        </Button>
      </Stack>
    </React.Fragment>
  );
}
