import React from 'react';
import { MdContactPage, MdMessage, MdPeople } from "react-icons/md";

import {
  IoSettings,
  IoDocument,
  IoFileTray,
  IoMapOutline,
  IoLocationSharp,
  IoPeople,
  IoListCircle,
  IoBook,
  IoArrowForwardCircle,
} from "react-icons/io5";

import { SiFacebook } from "react-icons/si";
import { LogoutRounded } from "@mui/icons-material";
const iconSize = 20;

const supportMenu = [
  {
    id: "",
    title: "Chats",
    icon: <MdMessage size={iconSize} />,
  },
  {
    id: "contacts",
    title: "Contacts",
    icon: <MdContactPage size={iconSize} />,
  },
];
const crmMenu = [
  {
    id: "applications",
    title: "Applications",
    icon: <IoFileTray size={iconSize} />,
  },
  {
    id: "clients",
    title: "Drivers",
    icon: <MdPeople size={iconSize} />,
  },
  {
    id: "maps",
    title: "Maps",
    icon:<IoLocationSharp size={iconSize} />,
  },
  {
    id: "payments",
    title: "Payments",
    icon: <IoDocument size={iconSize} />,
  },
];


const settingsMenu = [
  {
    id: "users",
    title: "Team",
    icon: <IoSettings size={iconSize} />,
  },
  {
    id: "logout",
    title: "Sign out",
    icon: <LogoutRounded size={iconSize} />,
  },
];

const socialMenu = [
  {
    id: "facebook",
    title: "Facebook",
    icon: <SiFacebook size={iconSize} />,
  },
];

export { supportMenu, settingsMenu, socialMenu, crmMenu };
