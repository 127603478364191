import Stack from "@mui/material/Stack";
import { FiSend } from "react-icons/fi";
import TextField from "@mui/material/TextField";
import { MdAttachFile, MdList } from "react-icons/md";
import LinearProgress from "@mui/material/LinearProgress";
import * as React from "react";
import { updateConversation } from "../../../../../data/slices/conversation";
import { useDispatch, useSelector } from "react-redux";
import { BsArrowDownCircleFill } from "react-icons/bs";
import { constants } from "../../../../../config/constants";
import { addMessage } from "../../../../../data/slices/conversation";
import NewWhatsappMessage from "./new-wa/view";

import IconButton from "@mui/material/IconButton";
export default function WhatsappInput({ phone, handleChange, lastMessage }) {
  const [loading, setLoading] = React.useState(false);
  const [message, setMessage] = React.useState("");
  const dispatch = useDispatch();
  const conversation = useSelector((state) => state.conversation);
  

  var hours = lastMessage
  ? Math.abs(new Date() - new Date(lastMessage.created_at * 1000)) / 36e5
  : 0;


  console.log('hours',hours)

  const sendMessage = () => {
    setLoading(true);
    var myHeaders = new Headers();

    myHeaders.append("Content-Type", "application/json");

    var raw = {};

    let endpoint = "";

    if (conversation.type === "whatsapp") {
      endpoint = "whatsapp";
      raw = JSON.stringify({
        message,
        phone,
        profileName: conversation.name || conversation.wa_id,
        thread_id:conversation.match.thread_id,
        type: conversation.match.type
      });
      console.log('raw', raw)
    } else if (conversation.type === "messenger") {
      endpoint = "facebook";
      raw = JSON.stringify({
        message,
        recipient: conversation.person_id,
      });
    } else if (conversation.type === "facebook") {
      endpoint = "facebook/comments";
      raw = JSON.stringify({
        message,
        parent_id: conversation.messages[0].comment.comment_id,
      });
    }

    var requestOptions = {
      method: "POST",
      headers: {
        'Content-Type': 'application/json', // Ensure the correct content type is set
      },
      body: raw,
    };


    fetch(`${constants.apiUrl}/${endpoint}/send`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (conversation.type === "facebook") {
          dispatch(
            addMessage({
              comment: {
                post: conversation.messages[0].comment.post,
                from: {
                  name: "Drive to Own",
                  id: "114370986869351",
                },
                isReply: true,
                message,
                parent_id: conversation.messages[0].comment.comment_id,
                comment_id: result.id,
                post_id: conversation.messages[0].comment.post_id,
              },
              read: true,
              date: new Date().toISOString(),
            })
          );
        } else {
          console.log('addMessage',result)
          dispatch(addMessage(result));
        }

        setMessage("");
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        console.log("error", error);
        setLoading(false);
      });
  };

  const inputRef = React.useRef(null);

  const [openWA, setOpenWA] = React.useState(false);

  return (
    <Stack sx={{ position: "absolute", bottom: 4, right: 8, left: 8 }}>
      <input
        ref={inputRef}
        type="file"
        accept=".xls,.xlsx,.pdf,.png,.jpeg,.jpg,.docx,.doc,.txt"
        style={{ display: "none" }}
        id="input-file-upload"
        multiple={true}
        onChange={handleChange}
      />
      <Stack
        sx={{
          background: "white",
          zIndex: 1000,
          boxShadow: "5px 5px 24px 1px rgba(0, 0, 0, 0.1)",
          borderRadius: 2,
        }}
      >
        {loading && (
          <LinearProgress sx={{ borderRadius: 2, mr: 0.5, ml: 0.5 }} />
        )}
        <Stack
          sx={{
            alignItems: "center",
            pr: 2,
            pl: 2,
            pb: 0.5,
            pt: 0.5,
          }}
          direction="row"
        >
          {conversation.type !== "facebook" && (
            <IconButton
              onClick={() => {
                inputRef.current.click();
              }}
              disabled={loading}
              sx={{
                mr: 1,
              }}
            >
              <MdAttachFile size={20} />
            </IconButton>
          )}
          {conversation.type !== "facebook" && (
            <div
              style={{
                height: "20px",
                width: "1px",
                borderRadius: 32,
                backgroundColor: "silver",
                opacity: 0.25,
              }}
            />
          )}
          <TextField
            onKeyDown={(e) => {
              if (
                e.key === "Enter" &&
                !e.shiftKey && // To prevent sending when pressing Shift + Enter for new line
                !loading &&
                !((hours > 24 && conversation.type === "whatsapp")) &&
                message.trim() !== ""
              ) {
                e.preventDefault(); // Prevents the Enter key from creating a new line
                sendMessage(); // Call the sendMessage function
              }
            }}
            value={message}
            onChange={(e) => {
              setMessage(e.target.value);
            }}
            multiline
            disabled={loading}
            sx={{ flex: 1 }}
            maxRows={2}
            autoComplete="new-password"
            placeholder="Write a message ..."
          />
          <Stack direction="row">
            <IconButton
              onClick={sendMessage}
              disabled={
                loading ||
                (hours > 24 && conversation.type === "whatsapp") ||
                message.trim() === ""
              }
              sx={{}}
            >
              <FiSend size={20} />
            </IconButton>
            {conversation.type === "whatsapp" && (
              <IconButton
                onClick={() => {
                  setOpenWA(true);
                  dispatch(
                    updateConversation({
                      newWATemplateMsgPhone: true,
                    })
                  );
                }}
                disabled={loading}
                sx={{}}
              >
                <BsArrowDownCircleFill size={20} />
              </IconButton>
            )}
          </Stack>
        </Stack>
      </Stack>
      <NewWhatsappMessage
        setOpenSnack={() => {}}
        open={openWA}
        setOpen={setOpenWA}
      />
    </Stack>
  );
}
