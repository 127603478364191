import * as React from "react";
import { styled } from "@mui/material/styles";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";

import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import { Link } from "react-router-dom";

const CustomListButton = styled((props) => <ListItemButton {...props} />)(
  ({ theme }) => ({
    textTransform: "none",
    minWidth: 0,
    [theme.breakpoints.up("sm")]: {
      minWidth: 0,
    },
    "&.Mui-selected": {
      // backgroundColor: "#F6910E",
    },
    "&.Mui-focusVisible": {
      // backgroundColor: "#F6910E",
    },
    "&.Mui-focused": {
      // backgroundColor: "#F6910E",
    },
  })
);

export default function MenuItem({ data, handleChange, value }) {
  const [open, setOpen] = React.useState(
    data.subItems &&
      data.subItems.findIndex((item) => {
        return item.id === value;
      }) !== -1
  );

  React.useEffect(() => {
    if (
      data.subItems &&
      data.subItems.findIndex((item) => {
        return item.id === value;
      }) === -1
    ) {
      setOpen(false);
    }
  }, [value]);

  const handleClick = () => {
    setOpen(!open);
  };

  return (
    <Link to={data.id}>
      <ListItem sx={{ mt: 0.75 }} disablePadding>
        <CustomListButton
          selected={data.id === value}
          sx={{ mr: 1, ml: 1, borderRadius: 2 }}
        >
          <ListItemIcon>{data.icon}</ListItemIcon>
          <ListItemText primary={data.title} />
          {data.subItems && (
            <div> {open ? <ExpandLess /> : <ExpandMore />}</div>
          )}
        </CustomListButton>
      </ListItem>
    </Link>
  );
}


