import * as React from "react";
import Button from "@mui/material/Button";
import { Stack } from "@mui/system";
import { HiCloudArrowUp } from "react-icons/hi2";
import ListItemFile from "./file";
import { Typography } from "@mui/material";

function DragDropFile({ files, setFiles, setUploading }) {
  const [dragActive, setDragActive] = React.useState(false);
  const inputRef = React.useRef(null);

  const handleDrag = function (e) {
    e.preventDefault();
    e.stopPropagation();
    if (e.type === "dragenter" || e.type === "dragover") {
      setDragActive(true);
    } else if (e.type === "dragleave") {
      setDragActive(false);
    }
  };

  const previewFile = (file) => {
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onloadend = function () {
      console.log(reader.result);
    };
  };

  const handleDrop = function (ev) {
    ev.preventDefault();
    ev.stopPropagation();
    setDragActive(false);

    if (ev.dataTransfer.items) {
      [...ev.dataTransfer.items].forEach((item, i) => {
        let temp = [];
        if (item.kind === "file") {
          const file = item.getAsFile();
          // previewFile(file);
          temp.push({ file, key: Date.now() });
        }
        setFiles((files) => [...files, ...temp]);
      });
    } else {
      let temp = [];
      [...ev.dataTransfer.files].forEach((file, i) => {
        temp.push({ file, key: Date.now() });
        //previewFile(file);
      });
      setFiles((files) => [...files, ...temp]);
    }
  };

  const handleChange = function (e) {
    e.preventDefault();
    if (e.target.files && e.target.files[0]) {
      let temp = [...e.target.files];
      temp.forEach((file, index) => {
        temp[index] = { file, key: Date.now() };
      });
      setFiles((files) => [...files, ...temp]);
    }
  };

  const onButtonClick = () => {
    inputRef.current.click();
  };

  return (
    <Stack
      sx={{
        borderRadius: 2,
        flex: 1,
        pt: 2,
        maxHeight: "60vh",
        overflow: "scroll",
      }}
    >
      <form
        id="form-file-upload"
        onDragEnter={handleDrag}
        onSubmit={(e) => e.preventDefault()}
      >
        <input
          ref={inputRef}
          type="file"
          id="input-file-upload"
          multiple={true}
          onChange={handleChange}
        />
        <div id="label-file-upload" className={dragActive ? "drag-active" : ""}>
          <div>
            <Typography sx={{ mt: 2 }}>
              Drag and drop your files here or
            </Typography>
            <Button
              onClick={onButtonClick}
              startIcon={<HiCloudArrowUp />}
              disableElevation
              sx={{
                borderRadius: 32,
                mt: 1,
                textTransform: "none",
                height: 45,
                mb: 3,
              }}
              color="info"
            >
              Upload Files
            </Button>
          </div>
        </div>
        {dragActive && (
          <div
            id="drag-file-element"
            onDragEnter={handleDrag}
            onDragLeave={handleDrag}
            onDragOver={handleDrag}
            onDrop={handleDrop}
          ></div>
        )}
      </form>
      <Stack
        sx={{
          flex: 1,
          mt: 1,
          pt: 2,
          overflow: "scroll",
          borderLeftWidth: 1,
          background: "white",
        }}
      >
        <h5 style={{ margin: 0 }}>Files</h5>
        {files.length === 0 && (
          <Typography variant="caption">No files selected</Typography>
        )}
        <Stack sx={{ mt: 2 }}>
          {files.map((file, index) => (
            <ListItemFile
              setUploading={(key, value) => {
                let temp = [...files];
                let index = temp.findIndex((a) => a.key === key);
                temp[index].uploading = value;
                setFiles(temp);
              }}
              saveRef={(dbRef) => {
                let temp = files;
                temp[index].dbRef = dbRef;
                setFiles(temp);
              }}
              file={file.file}
              key={file.key.toString()}
              _id={file.key}
              setFiles={setFiles}
            />
          ))}
        </Stack>
      </Stack>
    </Stack>
  );
}

export default DragDropFile;
