import * as React from "react";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

export default function TimeComponent({ date }) {
  // Convert Unix timestamp (seconds) to milliseconds
  const dt = new Date(date * 1000);

  // Convert to SAST (South African Standard Time, UTC+2)
  const options = { timeZone: "Africa/Johannesburg", hour: '2-digit', minute: '2-digit', hour12: false };
  const time = dt.toLocaleTimeString('en-GB', options);

  const diffTime = Math.abs(new Date() - dt);
  let diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
  if (new Date().toDateString() === dt.toDateString()) {
    diffDays = 0;
  }

  const months = [
    "Jan", "Feb", "Mar", "April", "May", "June", 
    "July", "Aug", "Sept", "Oct", "Nov", "Dec"
  ];

  const days = ["Mon", "Tues", "Wed", "Thurs", "Fri", "Sat", "Sun"];

  return (
    <Stack sx={{ mt: 0.2, fontSize: 12 }} direction="row">
      {diffDays === 0 && (
        <Typography sx={{}} variant="caption">
          {time}
        </Typography>
      )}
      <Stack sx={{ mr: 1 }}>
        {diffDays === 1 && (
          <Typography sx={{}} variant="caption">
            {days[dt.getDay()]}, {time}
          </Typography>
        )}
        {diffDays > 1 && diffDays < 31 && (
          <Typography sx={{}} variant="caption">
            {months[dt.getMonth()]} {dt.getDate()}, {time}
          </Typography>
        )}
        {diffDays < 365 && diffDays > 31 && (
          <Typography sx={{}} variant="caption">
            {months[dt.getMonth()]} {dt.getDate()}
          </Typography>
        )}
        {diffDays > 365 && (
          <Typography sx={{}} variant="caption">
            {dt.toDateString()}
          </Typography>
        )}
      </Stack>
    </Stack>
  );
}
