/* eslint-disable no-restricted-globals */
/* eslint-disable react-hooks/exhaustive-deps */
import Stack from "@mui/material/Stack";
import * as React from "react";
import { useDispatch } from "react-redux";
import { IoMail } from "react-icons/io5";
import Chip from "@mui/material/Chip";
import { updateCompose } from "../../../../data/slices/compose";
import { Divider, IconButton, Typography } from "@mui/material";
import { BsPencil, BsTrash } from "react-icons/bs";
import LinearProgress from "@mui/material/LinearProgress";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { ClickAwayListener } from "@mui/base";
import {
  useEditUserMutation,
  useDeleteUserMutation,
} from "../../../../services/api-endpoints/user";
import RadioGroup from "@mui/material/RadioGroup";
import Radio from "@mui/material/Radio";

const HtmlTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    background: "white",
    width: 250,
    fontSize: theme.typography.pxToRem(12),
    boxShadow: "5px 5px 24px 1px rgba(0, 0, 0, 0.25)",
    color: "black",
    padding: 16,
    borderRadius: 12,
  },
}));

const User = ({ user, setComposeOpen, showEdit, ownEmail }) => {
  const dispatch = useDispatch();

  const [open, setOpen] = React.useState(false);

  const handleTooltipClose = () => {
    setOpen(false);
  };

  const handleTooltipOpen = () => {
    setEditUser((user) => ({
      ...user,
      password: "",
    }));
    setOpen(true);
    setOpen(true);
  };

  const [userEdit, setEditUser] = React.useState({
    firstName: user.firstName,
    lastName: user.lastName,
    role: user.role,
    email: user.email,
    password: "",
  });

  const [editUser, {}] = useEditUserMutation();

  const [deleteUser, { isLoading }] = useDeleteUserMutation();

  const saveuser = async () => {
    let temp = { ...userEdit };
    if (temp.password.trim() === "") {
      delete temp.password;
    }
    try {
      let result = await editUser({
        _id: user._id,
        user: { ...temp },
      });
      if (result.error) {
        throw new Error(result.error.data.error);
      }
      handleTooltipClose();
    } catch (e) {
      alert("Error => " + e.message);
    }
  };

  return (
    <Stack
      sx={{
        boxShadow: "5px 5px 24px 1px rgba(0, 137, 254, 0.07)",
        p: 3,
        borderRadius: 2,
        flex: 1,
        mb: 2,
        mr: 1,
      }}
    >
      <h5 style={{ margin: 0 }}>
        {user.firstName} {user.lastName}
      </h5>
      <Stack direction="row" sx={{ mt: 0.5, alignItems: "center" }}>
        <IoMail size={13} color="#00808B" style={{ marginRight: 8 }} />{" "}
        <Typography
          onClick={() => {
            setComposeOpen(true);
            dispatch(
              updateCompose({
                to: [user.email],
              })
            );
          }}
          style={{
            "word-break": "break-all",
            cursor: "pointer",
          }}
          sx={{
            "&: hover": {
              textDecorationLine: "underline",
            },
          }}
        >
          {user.email}
        </Typography>
      </Stack>
      <Stack
        direction="row"
        sx={{ mt: 0.5, alignItems: "center", justifyContent: "space-between" }}
      >
        <Stack direction="row" sx={{ alignItems: "center" }}>
          <Chip
            sx={{ alignSelf: "flex-start", mt: 0.5, mr: 1 }}
            label={user.role}
            color="info"
          />
          {(showEdit || ownEmail === user.email) && (
            <ClickAwayListener onClickAway={handleTooltipClose}>
              <div>
                <HtmlTooltip
                  placement="left-start"
                  open={open}
                  title={
                    <Stack>
                      <form
                        onSubmit={(e) => {
                          e.preventDefault();
                          saveuser();
                          // submitForm();
                        }}
                        action="/"
                      >
                        <TextField
                          size="small"
                          color="info"
                          required
                          sx={{
                            "& fieldset": {
                              borderRadius: 3,
                            },
                            "& .MuiOutlinedInput-root": {
                              "& fieldset": {
                                borderColor: "rgba(0, 0, 0, 0.05)",
                              },
                            },
                            mt: 2,
                          }}
                          defaultValue={userEdit.firstName}
                          fullWidth
                          label="First name"
                          onChange={(e) => {
                            setEditUser((user) => ({
                              ...user,
                              firstName: e.target.value,
                            }));
                          }}
                        />
                        <TextField
                          required
                          size="small"
                          color="info"
                          onChange={(e) => {
                            setEditUser((user) => ({
                              ...user,
                              lastName: e.target.value,
                            }));
                          }}
                          sx={{
                            "& fieldset": {
                              borderRadius: 3,
                            },
                            "& .MuiOutlinedInput-root": {
                              "& fieldset": {
                                borderColor: "rgba(0, 0, 0, 0.05)",
                              },
                            },
                            mt: 2,
                          }}
                          fullWidth
                          defaultValue={user.lastName}
                          label="Last name"
                        />
                        <TextField
                          size="small"
                          required
                          color="info"
                          onChange={(e) => {
                            setEditUser((user) => ({
                              ...user,
                              email: e.target.value,
                            }));
                          }}
                          sx={{
                            "& fieldset": {
                              borderRadius: 3,
                            },
                            "& .MuiOutlinedInput-root": {
                              "& fieldset": {
                                borderColor: "rgba(0, 0, 0, 0.05)",
                              },
                            },
                            mt: 2,
                          }}
                          fullWidth
                          defaultValue={user.email}
                          label="Email address"
                        />
                        <Stack>
                          <Typography variant="caption" sx={{ mt: 1, ml: 1 }}>
                            User role
                          </Typography>
                          <RadioGroup
                            defaultValue={user.role}
                            value={editUser.role}
                            onChange={(e) => {
                              setEditUser((user) => ({
                                ...user,
                                role: e.target.value,
                              }));
                            }}
                          >
                            <Stack
                              sx={{ alignItems: "center" }}
                              direction="row"
                            >
                              <Radio
                                disabled={!showEdit}
                                color="info"
                                value="admin"
                              />
                              <Typography variant="caption">Admin</Typography>
                              <Radio
                                disabled={!showEdit}
                                color="info"
                                sx={{ ml: 2 }}
                                value="agent"
                              />
                              <Typography variant="caption">Agent</Typography>
                            </Stack>
                          </RadioGroup>
                        </Stack>

                        <Divider sx={{ opacity: 0.5, mt: 1, mb: 1 }} />

                        <Typography variant="caption" sx={{ mt: 1 }}>
                          If you don't want to change the password, leave the
                          field below blank.
                        </Typography>
                        <TextField
                          size="small"
                          color="info"
                          onChange={(e) => {
                            setEditUser((user) => ({
                              ...user,
                              password: e.target.value,
                            }));
                          }}
                          sx={{
                            "& fieldset": {
                              borderRadius: 3,
                            },
                            "& .MuiOutlinedInput-root": {
                              "& fieldset": {
                                borderColor: "rgba(0, 0, 0, 0.05)",
                              },
                            },
                            mt: 1,
                          }}
                          fullWidth
                          value={editUser.password}
                          label="Password"
                        />
                        {isLoading && (
                          <LinearProgress
                            color="info"
                            sx={{ mt: 1, borderRadius: 32 }}
                          />
                        )}

                        <Stack
                          direction="row"
                          sx={{ mt: 2, justifyContent: "flex-end" }}
                        >
                          <Button
                            onClick={handleTooltipClose}
                            disableElevation
                            color="warning"
                            variant="outlined"
                            sx={{ mr: 1, borderRadius: 32 }}
                          >
                            Cancel
                          </Button>
                          <Button
                            type="submit"
                            color="info"
                            disabled={isLoading}
                            disableElevation
                            variant="contained"
                            sx={{ mr: 1, borderRadius: 32 }}
                          >
                            Save
                          </Button>
                        </Stack>
                      </form>
                    </Stack>
                  }
                >
                  <IconButton onClick={handleTooltipOpen} size="medium">
                    <BsPencil size={14} />
                  </IconButton>
                </HtmlTooltip>
              </div>
            </ClickAwayListener>
          )}
        </Stack>

        {showEdit && (
          <IconButton
            onClick={async () => {
              if (
                confirm("Are you sure you would like to delete this user?") ===
                true
              ) {
                await deleteUser({ _id: user._id });
              }
            }}
            size="medium"
          >
            <BsTrash color="red" size={14} />
          </IconButton>
        )}
      </Stack>
    </Stack>
  );
};

export default User;
