import Stack from "@mui/material/Stack";
import { Typography } from "@mui/material";
import ListItemFile from "./file-upload/file";
import React from 'react';


export default function FileMessage({ message }) {
  const dt = new Date();
  let time = dt.getHours().toString() + ":";

  time +=
    dt.getMinutes().toString().length < 2
      ? "0" + dt.getMinutes().toString()
      : dt.getMinutes().toString();

  return (
    <Stack
      sx={{
        alignSelf: "flex-end",
        mb: 2.5,
      }}
    >
      <Typography sx={{ mb: 0.5, alignSelf: "flex-end" }} variant="caption">
        {time}
      </Typography>

      <ListItemFile _id={message._id} sent={message.sent} file={message.file} />
    </Stack>
  );
}
