/* eslint-disable react-hooks/exhaustive-deps */
import Stack from "@mui/material/Stack";
import * as React from "react";
import { constants } from "../../../config/constants";
import SearchComponent from "./components/search";
import ClientType from "./components/filters";
import LinearProgress from "@mui/material/LinearProgress";
import Grow from "@mui/material/Grow";
import Snackbar from "@mui/material/Snackbar";
import Row from "./row";
import { filter } from "./filter";
import MailCompose from "../home/conversation/email/compose/view";
import { Typography } from "@mui/material";
import ClientDialog from "./modal/view";
import Button from "@mui/material/Button";
import { List, AutoSizer } from "react-virtualized";

import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";

import { collection, getFirestore, onSnapshot } from "firebase/firestore";
import { FolderOpenOutlined } from "@mui/icons-material";
import { IoCheckmarkCircleOutline } from "react-icons/io5";

const styles = {
  optionBtn: {
    flexDirection: "row",
    paddingHorizontal: 16,
    borderRadius: 32,
    marginRight: 8,
    paddingVertical: 4,
    alignItems: "center",
  },
  optionBtnActive: {
    flexDirection: "row",
    paddingHorizontal: 16,
    borderRadius: 32,
    marginRight: 8,
    backgroundColor: "#ffdaa8",
    paddingVertical: 4,
    alignItems: "center",
  },
};

const Clients = () => {
  const [loading, setLoading] = React.useState(false);

  const [clients, setClients] = React.useState([]);

  const [showClient, setShowClient] = React.useState({
    show: false,
    docId: null,
  });

  const rowHeight = 85;

  const [search, setSearch] = React.useState("");

  let filtered = clients.filter((a) => filter(a, search.toLowerCase()));
  function rowRenderer({ index, key, style }) {
    const data = filtered[index];

    return (
      <div key={key} style={style}>
        <Row
          showClient={(docId) => {
            setShowClient({ show: true, docId });
          }}
          item={data}
        />
      </div>
    );
  }

  const [activeOrder, setActiveOrder] = React.useState("balance");

  React.useEffect(() => {
    setLoading(true);
    var requestOptions = {
      method: "GET",
      redirect: "follow",
    };
    console.log('we are here now')
    fetch(`${constants.apiUrl}/drivers`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        setLoading(false);
        const temp = [];
        result.forEach((item) => {
          let completedRidesCount = 0;
          let totalFareAmount = 0;
    
          // Use optional chaining and provide an empty array if `rides` is undefined or null
          (item.rides ?? []).forEach((ride) => {
            if (ride.ride_status === 'completed') {
              completedRidesCount += 1;
              
              // Remove the "R " prefix and parse the fare amount as a number
              const fare = parseFloat(ride.fare_amount.replace("R ", ""));
              totalFareAmount += fare;
            }
          });
          temp.push({
            ...item,
            trips: completedRidesCount,
            totalFare: totalFareAmount
          });
        });
        console.log("drivers",temp)
        temp.sort((a, b) => {
          return b.trips - a.trips;
        });
        setClients(temp);
      })
      .catch((error) => {
        setLoading(false);
      });
  }, []);

  const db = getFirestore();

  React.useEffect(() => {
    const unsub = onSnapshot(collection(db, "clients"), (snapshot) => {
      snapshot.docChanges().forEach((change) => {
        let data = change.doc.data();
        let userID = change.doc.id;
        let newItem = {
          name: data.name,
          email: data.email,
          docId: change.doc.id,
          car: data.car,
          balanceFiat: data.balanceFiat,
          subscriptionExpires: data.subscriptionExpires,
          userID: data.userID,
          carReg: data.carReg,
        };
        if (clients.length > 0) {
          if (change.type === "added") {
            let index = clients.findIndex((a) => a.userID === userID);
            if (index === -1) {
              setClients((clients) => [...clients, newItem]);
            }
          }
          if (change.type === "modified") {
            const newState = clients.map((obj) => {
              if (obj.userID === userID) {
                return newItem;
              }
              return obj;
            });

            setClients(newState);
            return;
          }
          if (change.type === "removed") {
            setClients((clients) => clients.filter((a) => a.userID !== userID));
          }
        }
      });
    });

    return unsub;
  }, []);

  const [clientType, setClientType] = React.useState(0);

  const [composeOpen, setComposeOpen] = React.useState(false);

  const [openSnack, setOpenSnack] = React.useState(false);

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnack(false);
  };

  const closeMail = () => {
    setComposeOpen(false);
    setOpenSnack(true);
  };

  const checked = true;

  return (
    <Stack
      sx={{
        p: 4,
        pt: 2,
        height: "100vh",
        overflow: "scroll",
        background: "white",
        borderLeft: 1,
        borderLeftStyle: "solid",
        borderLeftColor: "rgb(236, 233, 226, 0.4)",
      }}
    >
      <Grow
        in={checked}
        style={{ transformOrigin: "0 0 0" }}
        {...(checked ? { timeout: 1000 } : {})}
      >
        <Stack
          direction="row"
          sx={{
            justifyContent: "space-between",

            mt: 3,
            mb: 3,
            alignItems: "center",
          }}
        >
          <SearchComponent setSearch={setSearch} />

          <FormControl>
            <RadioGroup value={activeOrder} sx={{ alignItems: "center" }} row>
              <FormLabel sx={{ mr: 2 }}>Order By</FormLabel>
              <FormControlLabel
                value="balance"
                control={
                  <Radio
                    onChange={(e) => {
                      setActiveOrder("balance");
                      if (e.target.checked) {
                        let temp = [...clients];
                        temp.sort((a, b) => {
                          return a.balanceFiat - b.balanceFiat;
                        });
                        setClients(temp);
                      }
                    }}
                  />
                }
                label="Wallet Balance"
              />
              <FormControlLabel
                value="subscription"
                control={
                  <Radio
                    onChange={(e) => {
                      setActiveOrder("subscription");
                      if (e.target.checked) {
                        let temp = [...clients];
                        temp.sort((a, b) => {
                          return a.subDate - b.subDate;
                        });
                        setClients(temp);
                      }
                    }}
                  />
                }
                label="Trips"
              />
            </RadioGroup>
          </FormControl>
        </Stack>
      </Grow>
      <Grow
        in={checked}
        style={{ transformOrigin: "0 0 0" }}
        {...(checked ? { timeout: 1000 } : {})}
      >
        <Stack direction="row" sx={{ mt: 2, mb: 3 }}>
          <Stack
            sx={{
              background: "rgb(217, 240, 246)",
              p: 3,
              borderRadius: 4,
              mr: 2,
              flex: 1,
            }}
          >
            <h3 style={{ margin: 0 }}>
              <b>Active</b>
            </h3>
            <p>Rides</p>

            <Typography sx={{ mt: 2, fontSize: 20 }}>
              {
                clients.filter((a) => {
                  return a.car !== "Unassigned";
                }).length
              }
            </Typography>
          </Stack>
          <Stack
            sx={{
              background: "#FEE5D2",
              p: 3,
              borderRadius: 4,
              mr: 2,
              flex: 1,
            }}
          >
            <h4 style={{ margin: 0 }}>
              <b>Awaiting</b>
            </h4>
            <p>Rides</p>

            <Typography sx={{ mt: 2, fontSize: 20 }}>
              {
                clients.filter((a) => {
                  return a.car === "Unassigned";
                }).length
              }
            </Typography>
          </Stack>
          <Stack
            sx={{
              background: "#ECEFF6",
              p: 3,
              borderRadius: 4,
              mr: 2,
              flex: 1,
            }}
          >
            <h4 style={{ margin: 0 }}>
              <b>Low Wallet</b>
            </h4>
            <p>Balance</p>

            <Typography sx={{ mt: 2, fontSize: 20 }}>
              {
                clients.filter((a) => {
                  return a.balanceFiat > 0;
                }).length
              }
            </Typography>
          </Stack>
          <Stack
            sx={{
              background: "#E5FDE6",
              p: 3,
              borderRadius: 4,
              mr: 2,
              flex: 1,
            }}
          >
            <h4 style={{ margin: 0 }}>
              <b>Today's</b>
            </h4>
            <p>Trips</p>
            <Typography sx={{ mt: 2, fontSize: 20 }}>
              {
                clients.filter((a) => {
                  return (
                    a.subscriptionExpires &&
                    new Date(a.subscriptionExpires.date) < new Date()
                  );
                }).length
              }
            </Typography>
          </Stack>
        </Stack>
      </Grow>
      <Grow
        in={checked}
        style={{ transformOrigin: "0 0 0" }}
        {...(checked ? { timeout: 1000 } : {})}
      >
        <Stack
          spacing={3}
          direction="row"
          sx={{
            boxShadow: "5px 5px 24px 1px rgba(0, 0, 0, 0.04)",
            mt: 2,
            p: 2,
            borderRadius: 1,
            color: "#4169E1",
          }}
        >
          <Typography sx={{ flex: 1, fontWeight: "bold" }}>Driver</Typography>

          <Typography sx={{ flex: 1, fontWeight: "bold" }}>Make & Model</Typography>

          <Typography sx={{ flex: 1, fontWeight: "bold" }}>
            Wallet Balance
          </Typography>

          <Typography sx={{ flex: 1, fontWeight: "bold" }}>
            Trips
          </Typography>
          <Typography sx={{ flex: 1, fontWeight: "bold" }}>Phone</Typography>

          <Typography sx={{ flex: 1, fontWeight: "bold", textAlign: "center" }}>
            Action
          </Typography>
        </Stack>
      </Grow>

      {loading && [].length === 0 && <LinearProgress />}
      {false && (
        <AutoSizer>
          {({ width, height }) => (
            <List
              width={width}
              height={height}
              rowHeight={rowHeight}
              rowRenderer={rowRenderer}
              rowCount={filtered.length}
              overscanRowCount={3}
            />
          )}
        </AutoSizer>
      )}
      {clients
        .filter((a) => filter(a, search.toLowerCase()))
        .map((item) => (
          <Row
            key={item.user_id}
            showClient={(docId) => {
              setShowClient({ show: true, docId });
            }}
            item={item}
          />
        ))}
      {composeOpen && (
        <MailCompose
          closeMail={closeMail}
          open={composeOpen}
          setOpen={setComposeOpen}
        />
      )}
      <Snackbar
        open={openSnack}
        autoHideDuration={6000}
        onClose={handleClose}
        message="Email Sent"
      />

      {showClient.docId && showClient.show && (
        <ClientDialog
          open={showClient.show}
          setOpen={() => {
            setShowClient({ show: false, docId: null });
          }}
          docId={showClient.docId}
        />
      )}
    </Stack>
  );
};

export default Clients;
