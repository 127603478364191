import { styled, alpha } from "@mui/material/styles";
import IconButton from "@mui/material/IconButton";
import InputBase from "@mui/material/InputBase";
import Avatar from "@mui/material/Avatar";
import Chip from "@mui/material/Chip";
import Stack from "@mui/material/Stack";
import { HiMagnifyingGlass } from "react-icons/hi2";
import { useSelector } from "react-redux";
import { SiWhatsapp, SiMessenger, SiFacebook } from "react-icons/si";
import { Typography } from "@mui/material";
import { MdInfo } from "react-icons/md";
import * as React from "react";
import { constants } from "../../../../../config/constants";
import SelectAssignee from "../assignments/view";

const Search = styled("div")(({ theme }) => ({
  position: "relative",
  borderRadius: 8,
  backgroundColor: alpha(theme.palette.common.black, 0.02),
  "&:hover": {
    backgroundColor: alpha(theme.palette.common.black, 0.03),
  },
  width: "100%",
}));

const SearchIconWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: "100%",
  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "inherit",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: "20ch",
    },
  },
}));

export default function WhatsappHeader() {
  const conversation = useSelector((state) => state.conversation);
  const [reassigned, setReassigned] = React.useState(null);
  const [open, setOpen] = React.useState(false);

  return (
    <Stack sx={{ position: "relative" }}>
      <Stack
        direction="row"
        sx={{
          boxShadow: "5px 5px 24px 1px rgba(90, 135, 136, 0.09)",
          p: 2,
          alignItems: "center",
          justifyContent: "space-between",
          borderRadius: 2,
          mt: 1,
          zIndex: 4,
        }}
      >
        {conversation.type !== "facebook" && (
          <Stack sx={{ alignItems: "center" }} direction="row">
            <Avatar
              sx={{
                fontSize: 14,
                mr: 1,
                background: "rgba(14, 115, 198, 0.10)",
                color: "black",
              }}
            >
              {conversation.name.charAt(0).toUpperCase()}
            </Avatar>
            <Stack>
              <h5 style={{ margin: 0 }}>{conversation.name}</h5>
              <Chip
                label={conversation.match.type || "No match"}
                color="info"
                sx={{
                  alignSelf: "flex-start",
                }}
                size="small"
              ></Chip>
            </Stack>
          </Stack>
        )}

        {conversation.type === "facebook" && (
          <Stack sx={{ alignItems: "center" }} direction="row">
            <Avatar
              sx={{
                fontSize: 14,
                mr: 1,

                backgroundColor: "black",
                color: "white",
              }}
            >
              D
            </Avatar>
            <Stack>
              <h5 style={{ margin: 0 }}>Drive to Own</h5>
              <Chip
                color="info"
                label="Facebook Post"
                variant="outlined"
                sx={{
                  alignSelf: "flex-start",
                }}
                size="small"
              ></Chip>
            </Stack>
          </Stack>
        )}

        <Stack direction="row" sx={{ alignItems: "center" }}>
          {false && (
            <Search>
              <SearchIconWrapper>
                <HiMagnifyingGlass size={20} />
              </SearchIconWrapper>
              <StyledInputBase
                placeholder="Search…"
                inputProps={{ "aria-label": "search" }}
              />
            </Search>
          )}

          <>
            {conversation.type === "messenger" && (
              <>
                <SiMessenger />
                <Typography sx={{ ml: 1 }} variant="caption">
                  Messenger
                </Typography>
              </>
            )}

            {conversation.type === "whatsapp" && (
              <>
                <Typography sx={{ mr: 1 }}>{conversation.wa_id}</Typography>
                <SiWhatsapp />
              </>
            )}

            {conversation.type === "facebook" && (
              <>
                <SiFacebook />
                <Typography sx={{ ml: 1 }} variant="caption">
                  Facebook
                </Typography>
              </>
            )}

            <IconButton
              sx={{ ml: 1 }}
              onClick={() => {
                setOpen(true);
              }}
            >
              <MdInfo size={16} />
            </IconButton>
          </>
        </Stack>
      </Stack>
      <SelectAssignee
        reassigned={reassigned}
        setReassigned={setReassigned}
        open={open}
        setOpen={setOpen}
      />
    </Stack>
  );
}
