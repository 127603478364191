/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-loop-func */
import * as React from "react";
import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";

import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import InputAdornment from "@mui/material/InputAdornment";
import { NumericFormatCustom } from "../../../layouts/inputs/view";
import { LinearProgress } from "@mui/material";
import Collapse from "@mui/material/Collapse";
import { doc, updateDoc, getFirestore } from "firebase/firestore";
import AdjustBalance from "./adjust_balance";

import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { styled } from "@mui/material/styles";
import { HiCheckCircle, HiWrench } from "react-icons/hi2";
import { ClickAwayListener } from "@mui/base";

const HtmlTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    background: "white",
    width: 250,
    fontSize: theme.typography.pxToRem(12),
    boxShadow: "5px 5px 24px 1px rgba(0, 0, 0, 0.25)",
    color: "black",
    padding: 16,
    borderRadius: 12,
  },
}));

export default function UserProfile({ user }) {
  const [items, setItems] = React.useState([
    {
      key: "name",
      label: "Full name",
      required: true,
    },

    {
      key: "designation",
      label: "Job description",
    },

    {
      key: "employer",
      label: "Employer",
    },
    {
      key: "phone",
      label: "Phone number",
      type: "phone",
      required: true,
    },

    {
      key: "email",
      label: "Email address",
      type: "email",
      required: true,
    },

    {
      key: "costKM",
      label: "Cost per km",
      type: "currency",
      required: true,
    },
    {
      key: "balanceFiat",
      label: "Wallet balance",
      type: "currency",
      required: true,
    },
    {
      key: "balanceKM",
      label: "KM Balance",
      type: "number",
      required: true,
    },
    {
      key: "kmToOwn",
      label: "KM to own",
      type: "number",
      required: true,
    },
    {
      key: "interval",
      label: "Service interval",
      type: "number",
    },
    {
      key: "totalTravel",
      label: "Distance driven",
      type: "number",
    },
    {
      key: "subscription",
      label: "Subscription",
      type: "currency",
      required: true,
    },

    {
      key: "renewalDay",
      label: "Renewal day",
      type: "number",
    },
  ]);
  React.useEffect(() => {
    user && setItems(items.map((item) => ({ ...item, value: user[item.key] })));
  }, [user]);

  const [openTooltip, setOpenTooltip] = React.useState({
    item: null,
  });

  const db = getFirestore();

  const MAX_VAL = 31;
  const withValueLimit = ({ value }) => value <= MAX_VAL;

  const [loading, setLoading] = React.useState(false);

  const saveChanges = async () => {
    const form = document.getElementById("edit-profile");
    const updates = {};
    for (let i = 0; i < form.elements.length; i++) {
      var e = form.elements[i];
      if (e.name) {
        if (e.value.trim() !== "") {
          let x = items.find((a) => a.key === e.name);
          updates[e.name] =
            x.type === "currency" || x.type === "number"
              ? parseFloat(e.value.replaceAll(",", ""))
              : e.value.trim();
        }
      }
    }

    setLoading(true);
    const docRef = doc(db, "clients", user.userID);

    await updateDoc(docRef, {
      ...updates,
    });
    setLoading(false);
  };

  return (
    <Stack>
      <h4 style={{ margin: 0 }}>Profile</h4>
      <form
        id="edit-profile"
        action="/"
        onSubmit={(e) => {
          e.preventDefault();
          saveChanges();
        }}
      >
        <Stack>
          <Grid spacing={2} container columns={{ xs: 12, sm: 12, md: 12 }}>
            {items.map((item) => (
              <Grid item xs={12} sm={12} md={4} key={item.key}>
                {false && (
                  <Typography variant="caption">
                    {item.label}
                    {item.required && (
                      <span style={{ color: "red", fontSize: 16, margin: 0 }}>
                        *
                      </span>
                    )}
                  </Typography>
                )}
                <TextField
                  
                  name={item.key}
                  required={item.required || false}
                  sx={{ mt: 1 }}
                  helperText={item.label}
                  onChange={(e) => {
                    setItems((values) =>
                      values.map((x) => {
                        return {
                          ...x,
                          value: item.key === x.key ? e.target.value : x.value,
                        };
                      })
                    );
                  }}
                  value={items.find((a) => a.key === item.key).value}
                  defaultValue={
                    (item.type === "currency" || item.type === "number") &&
                    item.key !== "renewalDay"
                      ? parseFloat(item.value || 0).toFixed(2)
                      : item.value
                  }
                  inputProps={{
                    ...((item.type === "currency" || item.type === "number") &&
                    item.key !== "renewalDay"
                      ? { decimalScale: 2 }
                      : {
                          decimalScale: 0,
                          isAllowed: withValueLimit,
                        }),
                  }}
                  InputProps={{
                    ...(item.type === "currency" || item.type === "number"
                      ? {
                          inputComponent: NumericFormatCustom,
                          startAdornment:
                            item.type === "currency" ? (
                              <InputAdornment position="start">
                                R
                              </InputAdornment>
                            ) : null,
                          endAdornment:
                            item.type === "number" &&
                            item.key !== "renewalDay" ? (
                              <InputAdornment position="end">km</InputAdornment>
                            ) : null,
                        }
                      : {}),
                  }}
                  fullWidth
                  placeholder={item.label}
                />
              </Grid>
            ))}
          </Grid>

          <Collapse in={loading} sx={{ mt: 2 }}>
            <LinearProgress color="info" />
          </Collapse>
          <Stack sx={{ mt: 2 }} direction="row">
            <ClickAwayListener
              onClickAway={() => {
                if (openTooltip.item === "adjust_balance")
                  setOpenTooltip({ item: null });
              }}
            >
              <div>
                <HtmlTooltip
                  placement="bottom-start"
                  open={openTooltip.item === "adjust_balance"}
                  title={
                    <AdjustBalance
                      costKM={user.costKM}
                      handleTooltipClose={() => {
                        setOpenTooltip({ item: null });
                      }}
                      userID={user.userID}
                    />
                  }
                >
                  <Button
                    disableElevation
                    color="info"
                    onClick={() => {
                      setOpenTooltip({ item: "adjust_balance" });
                    }}
                    disabled={loading}
                    startIcon={<HiWrench size={14} />}
                    sx={{
                      alignSelf: "flex-start",
                      textTransform: "none",
                      mr: 2,
                    }}
                  >
                    Adjust Balance
                  </Button>
                </HtmlTooltip>
              </div>
            </ClickAwayListener>
            <Button
              disableElevation
              color="info"
              disabled={loading}
              type="submit"
              startIcon={<HiCheckCircle size={14} />}
              sx={{ alignSelf: "flex-start", textTransform: "none" }}
            >
              Save Changes
            </Button>
          </Stack>
        </Stack>
      </form>
    </Stack>
  );
}
