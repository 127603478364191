/* eslint-disable react-hooks/exhaustive-deps */
import * as React from "react";
import { useState } from "react";
import { HiChevronDown, HiGlobeAlt } from "react-icons/hi2";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import ButtonBase from "@mui/material/ButtonBase";
import IconButton from "@mui/material/IconButton";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import { MdEmail } from "react-icons/md";
import { SiWhatsapp, SiMessenger, SiFacebook, SiGmail } from "react-icons/si";

import { styled, alpha } from "@mui/material/styles";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";

const HtmlTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "white",
    color: "rgba(0, 0, 0, 0.87)",
    width: 220,
    maxWidth: 300,

    boxShadow: "5px 5px 24px 1px rgba(0,0, 0, 0.07)",
    padding: 24,
    borderRadius: 16,
  },
}));

export default function ChannelFilter({
  channel,
  setChannel,
  mailType,
  setMailType,
}) {
  const handleChangeMailType = (event, newValue) => {
    setMailType(newValue);
  };

  const [open, setOpen] = React.useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const channels = [
    {
      name: "All Channels",
      icon: <HiGlobeAlt />,
    },
    {
      name: "Whatsapp",
      icon: <SiWhatsapp color="005D3F" />,
    },
    {
      name: "Facebook",
      icon: <SiFacebook color="#0064FF" />,
    },
    {
      name: "Messenger",
      icon: <SiMessenger color="#407CBE" />,
    },
    {
      name: "Email",
      icon: <SiGmail />,
    },
  ];

  return (
    <Stack
      sx={{
        boxShadow: "5px 5px 24px 1px rgba(0,0, 0, 0.04)",
        background: "white",
        borderRadius: 2,
        mb: 1,
        pr: 2,
        pl: 2,
      }}
    >
      <Stack
        sx={{
          justifyContent: "space-between",
          width: "100%",
          alignItems: "center",
          mt: 1,
        }}
        direction="row"
      >
        <Stack sx={{ alignItems: "center" }} direction="row">
          {channel.icon}
          <h4 style={{ margin: 0, marginLeft: 8 }}>{channel.name}</h4>
        </Stack>
        <ClickAwayListener onClickAway={handleClose}>
          <div>
            <HtmlTooltip
              open={open}
              placement="bottom-end"
              title={
                <React.Fragment>
                  <h2
                    style={{
                      margin: 0,
                      marginTop: 8,
                    }}
                    color="inherit"
                  >
                    Select Channel
                  </h2>
                  <Stack sx={{ mt: 2, mb: 2 }}>
                    {channels.map((e) => (
                      <ButtonBase
                        onClick={() => {
                          setChannel(e);
                        }}
                        sx={{
                          mb: 1,
                          flex: 1,
                          alignItems: "left",
                          p: 1,
                          borderRadius: 32,
                        }}
                      >
                        {channel.name === e.name && (
                          <div
                            style={{
                              height: 8,
                              width: 8,
                              borderRadius: 4,
                              backgroundColor: "orange",
                              marginRight: 8,
                            }}
                          />
                        )}
                        <Stack
                          direction="row"
                          sx={{ alignItems: "center", flex: 1 }}
                        >
                          {e.icon}
                          <Typography sx={{ ml: 2 }}>{e.name}</Typography>
                        </Stack>
                      </ButtonBase>
                    ))}
                  </Stack>
                </React.Fragment>
              }
            >
              <IconButton onClick={handleOpen} sx={{ ml: 0.5 }}>
                <HiChevronDown />
              </IconButton>
            </HtmlTooltip>
          </div>
        </ClickAwayListener>
      </Stack>

      <Tabs onChange={handleChangeMailType} value={mailType}>
        <Tab value="personal" sx={{ textTransform: "none" }} label="Personal" />
        <Tab
          value="unassigned"
          sx={{ textTransform: "none" }}
          label="Unassigned"
        />
        <Tab value="all" sx={{ textTransform: "none" }} label="All Chats" />
      </Tabs>
    </Stack>
  );
}
