/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-loop-func */

"use client";

import { ErrorBoundary } from "react-error-boundary";

import * as React from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Divider from "@mui/material/Divider";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import Grid from "@mui/material/Grid";
import InputAdornment from "@mui/material/InputAdornment";
import { NumericFormatCustom } from "../../../layouts/inputs/view";
import { LinearProgress } from "@mui/material";
import Collapse from "@mui/material/Collapse";
import { doc, updateDoc, getFirestore } from "firebase/firestore";
import AdjustBalance from "./adjust_balance";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { styled } from "@mui/material/styles";
import { HiCheckCircle, HiWrench } from "react-icons/hi2";
import { ClickAwayListener } from "@mui/base";
function numberWithCommas(x) {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

const HtmlTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    background: "white",
    width: 250,
    fontSize: theme.typography.pxToRem(12),
    boxShadow: "5px 5px 24px 1px rgba(0, 0, 0, 0.25)",
    color: "black",
    padding: 16,
    borderRadius: 12,
  },
}));

export default function UserProfile({ user }) {
  const [items, setItems] = React.useState([
    {
      key: "name",
      label: "Full name",
      required: true,
    },

    {
      key: "designation",
      label: "Job description",
    },

    {
      key: "employer",
      label: "Employer",
    },
    {
      key: "phone",
      label: "Phone number",
      type: "phone",
      required: true,
    },

    {
      key: "email",
      label: "Email address",
      type: "email",
      required: true,
    },

    {
      key: "costKM",
      label: "Cost per km",
      type: "currency",
      required: true,
    },
    {
      key: "balanceFiat",
      label: "Wallet balance",
      type: "currency",
      required: true,
    },
    {
      key: "balanceKM",
      label: "KM Balance",
      type: "number",
      required: true,
    },
    {
      key: "kmToOwn",
      label: "KM to own",
      type: "number",
      required: true,
    },
    {
      key: "interval",
      label: "Service interval",
      type: "number",
    },
    {
      key: "totalTravel",
      label: "Distance driven",
      type: "number",
    },
    {
      key: "subscription",
      label: "Subscription",
      type: "currency",
      required: true,
    },

    {
      key: "renewalDay",
      label: "Renewal day",
      type: "number",
    },
  ]);
  React.useEffect(() => {
    user && setItems(items.map((item) => ({ ...item, value: user[user.key] })));
  }, [user]);

  const [openTooltip, setOpenTooltip] = React.useState({
    item: null,
  });

  const db = getFirestore();

  const MAX_VAL = 31;
  const withValueLimit = ({ value }) => value <= MAX_VAL;

  const [loading, setLoading] = React.useState(false);

  const saveChanges = async () => {
    const form = document.getElementById("edit-profile");
    const updates = {};
    for (let i = 0; i < form.elements.length; i++) {
      var e = form.elements[i];
      if (e.name) {
        if (e.value.trim() !== "") {
          let x = items.find((a) => a.key === e.name);
          updates[e.name] =
            x.type === "currency" || x.type === "number"
              ? parseFloat(e.value.replaceAll(",", ""))
              : e.value.trim();
        }
      }
    }

    setLoading(true);
    const docRef = doc(db, "clients", user.userID);

    await updateDoc(docRef, {
      ...updates,
    });
    setLoading(false);
  };

  return (
    <Stack>
      <Stack>
      <Stack
        sx={{
          borderRadius: 2,
          background: "#E6F4F8",
          p: 2,
        }}
      >
        <h3 style={{ margin: 0, marginBottom: 8 }}>Personal Details</h3>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <p>
              Name: <b>{user.fullname}</b>
            </p>
          </Grid>
          <Grid item xs={6}>
            <p>
              Phone: <b>{user.applicationId}</b>
            </p>
          </Grid>
          <Grid item xs={6}>
            <p
              style={{
                fontSize: 16,
                marginTop: 4,
              }}
            >
              ID/Passport #: <b>{user.identification}</b>
            </p>
          </Grid>
          <Grid item xs={6}>
            <p
              style={{
                fontSize: 16,
                marginTop: 4,
              }}
            >
              Email: <b>{user.email}</b>
            </p>
          </Grid>
        </Grid>
      </Stack>
        <Stack
          sx={{
            borderRadius: 2,
            background: "#CCFAE1",
            mt: 2,
            p: 2,
          }}
        >
          <h3 style={{ margin: 0, marginBottom: 8 }}>Car Details</h3>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <p>
                Car: <b>{user.makeModel}</b>
              </p>
            </Grid>
            <Grid item xs={6}>
              <p>
                Year: <b>{user.year}</b>
              </p>
            </Grid>
            <Grid item xs={6}>
              <p>
                Color: <b>{user.color}</b>
              </p>
            </Grid>
            <Grid item xs={6}>
              <p>
                Reg Number: <b>{user.licensePlate}</b>
              </p>
            </Grid>
          </Grid>
        </Stack>
        <Collapse in={loading} sx={{ mt: 2 }}>
          <LinearProgress color="info" />
        </Collapse>
      </Stack>
    </Stack>
  );
}
