import * as React from "react";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import LinearProgress from "@mui/material/LinearProgress";
import TextField from "@mui/material/TextField";

import {
  getFirestore,
  doc,
  updateDoc,
  arrayUnion,
  Timestamp,
} from "firebase/firestore";

import { useSelector } from "react-redux";

export default function NewNote({ handleTooltipClose, userID, costKM }) {
  const [loading, setLoading] = React.useState(false);

  const db = getFirestore();

  const user = useSelector((state) => state.auth);

  const [note, setNote] = React.useState("");

  const addNote = async () => {
    setLoading(true);
    const docRef = doc(db, "clients", userID);
    await updateDoc(docRef, {
      notes: arrayUnion({
        content: note,
        writer:
          (user.firstName || "Unkown ") + " " + (user.lastName || "Admin"),
        date: new Date().toDateString(),
        time: Timestamp.fromDate(new Date()),
      }),
    });
    setNote("");
    setLoading(false);
    handleTooltipClose();
  };

  return (
    <React.Fragment>
      <Typography variant="h6">
        <b>Add a note</b>
      </Typography>

      {loading && <LinearProgress sx={{ mt: 1, borderRadius: 32 }} />}
      <TextField
        sx={{
          "& fieldset": {
            borderRadius: 5,
          },
          "& .MuiOutlinedInput-root": {
            "& fieldset": {
              borderColor: "rgba(0, 0, 0, 0.05)",
            },
          },
          mt: 3,
        }}
        multiline
        minRows={3}
        maxRows={5}
        fullWidth
        value={note}
        onChange={(e) => {
          setNote(e.target.value);
        }}
        inputProps={{ decimalScale: 2 }}
        label="New note"
      />

      <Stack sx={{ mt: 2, mb: 2 }}>
        <Button
          onClick={addNote}
          disabled={note === "" || loading}
          disableElevation
          variant="contained"
          color="info"
          sx={{ mr: 1, borderRadius: 32, height: 45 }}
        >
          Save
        </Button>
        <Button
          onClick={handleTooltipClose}
          disableElevation
          color="warning"
          variant="outlined"
          sx={{ mr: 1, borderRadius: 32, mt: 1, height: 45 }}
        >
          Cancel
        </Button>
      </Stack>
    </React.Fragment>
  );
}
