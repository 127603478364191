/* eslint-disable react-hooks/exhaustive-deps */
import * as React from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";

export default function ClientType({ setClientType, clientType }) {
  const handleClientType = (event, newValue) => {
    setClientType(newValue);
  };

  return (
    <Tabs
      onChange={handleClientType}
      value={clientType}
      aria-label="basic tabs example"
    >
      <Tab sx={{ textTransform: "none" }} label="All" />
      <Tab sx={{ textTransform: "none" }} label="Drivers" />
      <Tab sx={{ textTransform: "none" }} label="Riders" />
    </Tabs>
  );
}
