import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";

import { initializeApp } from "firebase/app";
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const config = {
  apiKey: "AIzaSyAkZmOUJBT-oaB-GD1hib3N4QACy7ZpDW4",
  authDomain: "swiftly-driver.firebaseapp.com",
  databaseURL: "https://swiftly-driver-default-rtdb.firebaseio.com",
  projectId: "swiftly-driver",
  storageBucket: "swiftly-driver.appspot.com",
  messagingSenderId: "222723821104",
  appId: "1:222723821104:web:62af0bb1f2ec5e4f7f67f5",
  measurementId: "G-EC7P7J6601"
};

initializeApp(config);

ReactDOM.createRoot(document.getElementById("root")).render(<App />);
