import * as React from "react";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";

import Avatar from "@mui/material/Avatar";
import PersonIcon from "@mui/icons-material/Person";
import AddIcon from "@mui/icons-material/Add";
import Typography from "@mui/material/Typography";
import { blue } from "@mui/material/colors";
import { LinearProgress } from "@mui/material";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import ListItemButton from "@mui/material/ListItemButton";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Dialog from "@mui/material/Dialog";
import RadioGroup from "@mui/material/RadioGroup";
import Radio from "@mui/material/Radio";
import FormControlLabel from "@mui/material/FormControlLabel";
import {
  collection,
  getDocs,
  getFirestore,
  doc,
  getDoc,
  updateDoc,
} from "firebase/firestore";

const options = [
  "None",
  "Atria",
  "Callisto",
  "Dione",
  "Ganymede",
  "Hangouts Call",
  "Luna",
  "Oberon",
  "Phobos",
  "Pyxis",
  "Sedna",
  "Titania",
  "Triton",
  "Umbriel",
];

const emails = ["username@gmail.com", "user02@gmail.com"];

function ConfirmationDialogRaw(props) {
  const { onClose, value: valueProp, open, ...other } = props;
  const [value, setValue] = React.useState(valueProp);
  const radioGroupRef = React.useRef(null);

  const db = getFirestore();
  const [admins, setadmins] = React.useState([]);

  const [loading, setLoading] = React.useState(true);

  const getadmins = React.useCallback(async () => {
    const docRef = doc(db, "whitelisted", "emails");
    const docSnap = await getDoc(docRef);
    setLoading(true);
    let list = docSnap.data().list;
    setadmins(list);
    setLoading(false);
  }, [db]);

  React.useEffect(() => {
    getadmins();
  }, [getadmins]);

  React.useEffect(() => {
    if (!open) {
      setValue(valueProp);
    }
  }, [valueProp, open]);

  const handleEntering = () => {
    if (radioGroupRef.current != null) {
      radioGroupRef.current.focus();
    }
  };

  const handleCancel = () => {
    onClose();
  };

  const handleOk = () => {
    onClose(value);
  };

  const handleChange = (event) => {
    setValue(event.target.value);
  };

  const handleListItemClick = async (value) => {
    setLoading(true);
    const docRef = doc(db, "applications", props.docId);
    await updateDoc(docRef, {
      assignee: value.name,
      assigneeEmail: value.email,
    });
    setLoading(false);
    onClose(value);
  };

  return (
    <Dialog
      fullWidth
      maxWidth="xs"
      TransitionProps={{ onEntering: handleEntering }}
      open={open}
      {...other}
    >
      {loading && <LinearProgress />}
      <DialogTitle>Assign Application</DialogTitle>
      <DialogContent>
        {admins
          .filter((a) => a.type !== "admin")
          .map((item) => (
            <ListItem disableGutters>
              <ListItemButton
                disabled={loading}
                sx={{ borderRadius: 32 }}
                onClick={() => handleListItemClick(item)}
                key={item.email}
              >
                <ListItemAvatar>
                  <Avatar sx={{ bgcolor: blue[100], color: blue[600] }}>
                    <PersonIcon />
                  </Avatar>
                </ListItemAvatar>
                <ListItemText primary={item.name} secondary={item.email} />
              </ListItemButton>
            </ListItem>
          ))}
      </DialogContent>
      <DialogActions>
        <Button disabled={loading} autoFocus onClick={handleCancel}>
          Cancel
        </Button>
        <Button disabled={loading} onClick={handleOk}>
          Ok
        </Button>
      </DialogActions>
    </Dialog>
  );
}

ConfirmationDialogRaw.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  value: PropTypes.string.isRequired,
};

export default function ConfirmationDialog({ open, setOpen, docId }) {
  const [value, setValue] = React.useState("Dione");

  const handleClickListItem = () => {
    setOpen(true);
  };

  const handleClose = (newValue) => {
    setOpen(false);

    if (newValue) {
      setValue(newValue);
    }
  };

  return (
    <ConfirmationDialogRaw
      keepMounted
      docId={docId}
      open={open}
      onClose={handleClose}
      value={value}
    />
  );
}
