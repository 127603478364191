import * as React from "react";
import ButtonBase from "@mui/material/ButtonBase";
import Avatar from "@mui/material/Avatar";
import { useDispatch, useSelector } from "react-redux";
import { truncateUserName } from "../../../../../utils/functions";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { constants } from "../../../../../config/constants";
import { MdEmail } from "react-icons/md";
import { SiWhatsapp, SiMessenger, SiFacebook } from "react-icons/si";
import { updateToRead } from "../../../../../data/slices/chats";
import { updateConversation } from "../../../../../data/slices/conversation";

import TimeComponent from "./time.";
import MessageContent from "./content";

export default function MessageComponent({
  message,
  sender,
  match,
  working_id,
}) {
  let whatsapp = message;
  let email = message.email;
  let comment = message.comment;
  let messenger = message.messenger;

  const dispatch = useDispatch();

  const conversation = useSelector((state) => state.conversation);

  const active = conversation.working_id === working_id;

  let username = "User";
  let emailAddress = "";
  let person_id = "";
  let safeUsername=""

  if (whatsapp) {
    if (match !== "") {
      username = match.contact;
      safeUsername = (typeof username === 'string' && username.length > 0) ? username : sender;
      //username = "User";
    } else {
        username = sender;
        safeUsername = (typeof username === 'string' && username.length > 0) ? username : sender;
        //username = "User";
      }

  } else if (email) {
    if (match.data) {
      emailAddress =
        sender === "admin"
          ? email.to.value[0].address
          : email.from.value[0].address;
      if (match.type === "applicant") username = match.data.name;
      if (match.type === "client") username = match.data.name;
    } else {
      emailAddress =
        sender === "admin"
          ? email.to.value[0].address
          : email.from.value[0].address;
      username =
        sender === "admin" ? email.to.value[0].name : email.from.value[0].name;
    }
  } else if (messenger && (messenger.recipient || messenger.sender)) {
    username = messenger.contact.first_name + " " + messenger.contact.last_name;
    if (constants.adminPageIDs.includes(messenger.recipient.id)) {
      person_id = messenger.sender.id;
    } else {
      person_id = messenger.recipient.id;
    }
  } else if (comment) {
    username = comment.from.name;
  }

  const containerStyle = {
    backgroundColor: active ? "rgba(14, 115, 198, 0.1)" : "white",
    transition: "all .8s ease",
    WebkitTransition: "all .8s ease",
    MozTransition: "all .8s ease",
    borderRadius: "8px",
    boxShadow: "5px 5px 24px 1px rgba(0,0, 0, 0.04)",
    margin: "16px",
    marginTop: "0px",
  };

  return (
    <div style={containerStyle}>
      <ButtonBase
        onClick={() => {
          if (whatsapp) {
            dispatch(
              updateConversation({
                type: "whatsapp",
                wa_id: sender,
                name: username,
                match,
                working_id,
                messages: message.message,
              })
            );
            dispatch(updateToRead({ type: "whatsapp", _id: whatsapp.wa_id }));
          }
          if (messenger && (messenger.recipient || messenger.sender)) {
            dispatch(
              updateConversation({
                type: "messenger",
                person_id,
                name: username,
                match,
                working_id,
                messages: [],
              })
            );
            dispatch(updateToRead({ type: "messenger", _id: person_id }));
          }
          if (comment) {
            dispatch(
              updateConversation({
                type: "facebook",
                person_id,
                name: username,
                match,
                working_id,
                messages: [],
                isReply: comment.isReply,
                parent_id: comment.parent_id,
                post_id: comment.post_id,
                comment_id: comment.comment_id,
              })
            );
            dispatch(updateToRead({ type: "comment", _id: working_id }));
          }
          if (email) {
            dispatch(
              updateConversation({
                type: "email",
                messageId: email.messageId,
                _id: message._id,
                match,
                name: username,
                sender,
                working_id,
                messages: [],
                email: emailAddress,
              })
            );
            dispatch(
              updateToRead({
                type: "email",
                _id: message._id,
              })
            );
          }
        }}
        sx={{
          p: 2,
          pt: 2,
          pb: 2,
          flex: 1,
          borderRadius: "8px",
          width: "100%",
        }}
      >
        <Stack sx={{ flex: 1 }} direction="row">
          <Avatar
            sx={{
              color: "black",
              fontSize: 14,
              background: active
                ? "rgba(14, 115, 198, 0.1)"
                : "rgba(14, 115, 198, 0.05)",
              transition: "all .5s ease",
              WebkitTransition: "all .5s ease",
              MozTransition: "all .5s ease",
            }}
          >
            {(whatsapp || messenger || comment) &&
              safeUsername.charAt(0).toUpperCase()}
            {email &&
              (sender === "admin"
                ? email.to.value[0].address.charAt(0).toUpperCase()
                : email.from.value[0].address.charAt(0).toUpperCase())}
          </Avatar>
          <Stack sx={{ height: "100%", pl: 2, flex: 1 }}>
            <Stack
              direction="row"
              sx={{
                justifyContent: "space-between",
                width: "100%",
                alignItems: "center",
              }}
            >
              <Typography
                style={{
                  fontSize: 14,
                  display: "-webkit-box",
                  overflow: "hidden",
                  WebkitBoxOrient: "vertical",
                  WebkitLineClamp: 1,
                  mr: "10px",
                  textAlign: "left",
                }}
              >
                {(whatsapp || messenger || comment) &&
                  truncateUserName(safeUsername)}

                {email && truncateUserName(emailAddress)}
              </Typography>

              <Stack direction="row" sx={{ alignItems: "center" }}>
                {email && <MdEmail style={{ opacity: 0.6 }} size={12} />}
                {whatsapp && <SiWhatsapp color="#007544" size={12} />}
                {comment && <SiFacebook color="#0985EF" size={12} />}
                {messenger && (messenger.recipient || messenger.sender) && (
                  <SiMessenger color="#0985EF" size={12} />
                )}
              </Stack>
            </Stack>
            <MessageContent message={message} sender={sender} match={match} />
            <TimeComponent date={message.message[0].created_at} />
          </Stack>
        </Stack>
      </ButtonBase>
    </div>
  );
}
