import * as React from "react";
import Stack from "@mui/material/Stack";
import Grow from "@mui/material/Grow";
import Divider from "@mui/material/Divider";
import Chip from "@mui/material/Chip";
import { Button, TextField, Typography, IconButton, Select, MenuItem, InputLabel, FormControl } from "@mui/material";
import { HiPencil } from "react-icons/hi";
import Zoom from "react-medium-image-zoom";
import "react-medium-image-zoom/dist/styles.css";
import DocViewer from "../doc-viewer/view";
import { updateUserData } from "./updateUserData";

function isImage(url) {
  return /\.(jpg|jpeg|png|webp|avif|gif|svg)$/.test(url);
}

function numberWithCommas(x) {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export default function Applicant({ data }) {
  const [doc, setDoc] = React.useState({
    _id: null,
    fileType: null,
    filename: null,
    type: null,
    url: null,
  });
  const [docViewerOpen, setDocViewerOpen] = React.useState(false);
  const [isUpdating, setIsUpdating] = React.useState(false);
  const [updateStatus, setUpdateStatus] = React.useState('');


  const [isEditing, setIsEditing] = React.useState({
    fullName: false,
    email: false,
    carDetails: false,
    walletBalance: false,
    accountStatus: false
  });

  // Original data
  const [originalData, setOriginalData] = React.useState({
    contact: data.contact || '',
    email: data.email || '',
    car_make_and_model: data.car_make_and_model || '',
    balance: data.balance || 0,
    Account_status: data.Account_status || 'Verified'
  });

  const [fullName, setFullName] = React.useState(data.contact || '');
  const [email, setEmail] = React.useState(data.email || '');
  const [carDetails, setCarDetails] = React.useState(data.car_make_and_model || '');
  const [walletBalance, setWalletBalance] = React.useState(data.balance || 0);
  const [accountStatus, setAccountStatus] = React.useState(data.Account_status || 'Verified');

  const handleUpdate = async () => {
    setIsUpdating(true);
    setUpdateStatus('Updating...');
  
    try {
      // Prepare only updated fields
      const updatedData = {};
      if (fullName !== originalData.contact) updatedData.contact = fullName;
      if (email !== originalData.email) updatedData.email = email;
      if (carDetails !== originalData.car_make_and_model) updatedData.car_make_and_model = carDetails;
      if (walletBalance !== originalData.balance) updatedData.balance = walletBalance;
      if (accountStatus !== originalData.Account_status) updatedData.Account_status = accountStatus;
  
      // Check if there are any changes
      if (Object.keys(updatedData).length === 0) {
        setIsUpdating(false);
        setUpdateStatus('No changes made');
        return;
      }
  
      // Perform the update
      await updateUserData(data.docId, updatedData);
      setUpdateStatus('Successful'); // Set status to "successful" after a successful update
    } catch (error) {
      console.error("Error updating data:", error);
      setUpdateStatus('Update failed'); // Reset the status on failure
    } finally {
      setIsUpdating(false);
      setIsEditing({
        fullName: false,
        email: false,
        carDetails: false,
        walletBalance: false,
        accountStatus: false
      });
    }
  };
  
  const handleEditClick = (field) => {
    setIsEditing((prev) => ({ ...prev, [field]: true }));
  };

  return (
    <Grow in={true} style={{ transformOrigin: "0 0 0" }}>
      <Stack
        sx={{
          p: 2.5,
          boxShadow: "5px 5px 24px 1px rgba(181, 115, 25, 0.08)",
          borderRadius: 2,
          flex: 1,
          mb: 2,
        }}
      >
        <Stack
          sx={{ justifyContent: "space-between", alignItems: "center" }}
          direction="row"
        >
          <h4 style={{ margin: 0, color: "#2A234D" }}>Account Status</h4>
          <Chip color="info" sx={{}} label={data.type === "Driver" ? data.Account_status : "Verified"} />
        </Stack>
        <Divider sx={{ mt: 2, mb: 2, opacity: 0.6 }} />
        <Stack>
          <Stack direction="row" alignItems="center">
          <Typography variant="caption" sx={{ fontSize: '0.875rem', fontWeight: 600 }}>Full Name</Typography>
            <IconButton sx={{ ml: 1 }} onClick={() => handleEditClick('fullName')}>
              <HiPencil />
            </IconButton>
          </Stack>
          {isEditing.fullName ? (
            <TextField
              value={fullName}
              onChange={(e) => setFullName(e.target.value)}
              variant="outlined"
              fullWidth
              sx={{ mt: 1, textAlign: "left" }}
              autoFocus
            />
          ) : (
            <Typography>{fullName}</Typography>
          )}
        </Stack>
        <Stack sx={{ mt: 2 }}>
          <Stack direction="row" alignItems="center">
            <Typography variant="caption" sx={{ fontSize: '0.875rem', fontWeight: 600 }}>Email Address</Typography>
            <IconButton sx={{ ml: 1 }} onClick={() => handleEditClick('email')}>
              <HiPencil />
            </IconButton>
          </Stack>
          {isEditing.email ? (
            <TextField
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              variant="outlined"
              fullWidth
              sx={{ mt: 1, textAlign: "left" }}
              autoFocus
            />
          ) : (
            <Typography>{email}</Typography>
          )}
        </Stack>
        <Stack sx={{ mt: 2 }}>
          <Stack direction="row" alignItems="center">
            <Typography variant="caption" sx={{ fontSize: '0.875rem', fontWeight: 600 }}>Verification Status</Typography>
            <IconButton sx={{ ml: 1 }} onClick={() => handleEditClick('accountStatus')}>
              <HiPencil />
            </IconButton>
          </Stack>
          {isEditing.accountStatus ? (
            <FormControl fullWidth variant="outlined" sx={{ mt: 1 }}>
              <InputLabel id="account-status-label">Status</InputLabel>
              <Select
                labelId="account-status-label"
                value={accountStatus}
                onChange={(e) => setAccountStatus(e.target.value)}
                label="Status"
                autoFocus
              >
                <MenuItem value="verified">Verified</MenuItem>
                <MenuItem value="unverified">Unverified</MenuItem>
              </Select>
            </FormControl>
          ) : (
            <Typography>{accountStatus}</Typography>
          )}
        </Stack>
        {data.car_make_and_model && (
          <Stack sx={{ mt: 2 }}>
            <Stack direction="row" alignItems="center">
              <Typography variant="caption" sx={{ fontSize: '0.875rem', fontWeight: 600 }}>Car Details</Typography>
              <IconButton sx={{ ml: 1 }} onClick={() => handleEditClick('carDetails')}>
                <HiPencil />
              </IconButton>
            </Stack>
            {isEditing.carDetails ? (
              <TextField
                value={carDetails}
                onChange={(e) => setCarDetails(e.target.value)}
                variant="outlined"
                fullWidth
                sx={{ mt: 1, textAlign: "left" }}
                autoFocus
              />
            ) : (
              <Typography>{carDetails}</Typography>
            )}
          </Stack>
        )}
        <Stack sx={{ mt: 2 }}>
          <Stack direction="row" alignItems="center">
            <Typography variant="caption" sx={{ fontSize: '0.875rem', fontWeight: 600 }}>Wallet Balance</Typography>
            <IconButton sx={{ ml: 1 }} onClick={() => handleEditClick('walletBalance')}>
              <HiPencil />
            </IconButton>
          </Stack>
          {isEditing.walletBalance ? (
            <TextField
              type="number"
              value={walletBalance}
              onChange={(e) => setWalletBalance(parseFloat(e.target.value) || 0)}
              variant="outlined"
              fullWidth
              sx={{ mt: 1, textAlign: "left" }}
              InputProps={{
                startAdornment: "R",
              }}
              autoFocus
            />
          ) : (
            <Typography>{"R" + numberWithCommas((walletBalance || 0).toFixed(2))}</Typography>
          )}
        </Stack>
        <Stack sx={{ mt: 2 }}>
          <Button 
              variant="contained" 
              onClick={handleUpdate} 
              disabled={isUpdating} // Disable the button while updating
          >
              {isUpdating ? 'Updating...' : 'Update'}
          </Button>
          {updateStatus && (
              <Typography sx={{ mt: 1, fontWeight: 500, color: 'green' }}>
                  {updateStatus}
              </Typography>
          )}
      </Stack>


        {!data.profilePictureUri && data.type !== 'Rider' && (
          <Stack sx={{ justifyContent: 'center', mt: 16 }}>
            <p style={{ textAlign: "center" }}>
              Documents not found in <br />
              database.
            </p>
          </Stack>
        )}

        {data.profilePictureUri && (
          <>
            <Divider sx={{ mt: 2, mb: 2, opacity: 0.6 }} />
            <Stack
              sx={{
                justifyContent: "space-between",
                alignItems: "center",
                mb: 2,
              }}
              direction="row"
            >
              <h4 style={{ margin: 0 }}>Documents</h4>
            </Stack>
            {Object.keys(data).map((e) => {
              let filename = "";
              let link = "";

              if (e === "driversLicense") {
                filename = "Driver's License";
                link = data[e];
              }
              if (e === "ehailingProfile") {
                filename = "E-hailing Profile";
                link = data[e];
              }
              if (e === "prdp") {
                filename = "PrDP";
                link = data[e];
              }
              if (e === "profilePictureUri") {
                filename = "Profile Picture";
                link = data[e];
              }
              if (e === "vehicleFrontImage") {
                filename = "Vehicle Image";
                link = data[e];
              }
              if (e === "vehicleLicenseDisk") {
                filename = "Vehicle Disk";
                link = data[e];
              }

              if (filename) {
                return (
                  <Stack sx={{ mb: 2 }} key={e}>
                    <Typography sx={{ mb: 0.5, fontSize: 14 }}>
                      <b>{filename}</b>
                    </Typography>
                    <Zoom>
                      <img
                        alt={filename}
                        src={link}
                        style={{ width: "250px", height: "200px" }}
                      />
                    </Zoom>
                  </Stack>
                );
              }

              return null;
            })}
          </>
        )}

        {docViewerOpen && (
          <DocViewer
            filename={doc.filename}
            appDoc={true}
            open={docViewerOpen}
            setOpen={setDocViewerOpen}
            id={doc._id}
            firestoreLink={doc._id}
            fileType={doc.fileType}
          />
        )}
      </Stack>
    </Grow>
  );
}
