import React from 'react';
import Stack from "@mui/material/Stack";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Chip from "@mui/material/Chip";
import Divider from "@mui/material/Divider";
import { ArrowForward } from "@mui/icons-material";

function numberWithCommas(x) {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export default function Row({ item, showInvoice }) {
  const {
    invoiceNumber,
    car,
    carReg,
    total,
    dueDate,
    status,
    userID,
    name,
    _id,
  } = item;

  var dobArr = new Date(dueDate).toDateString().split(" ");
  var dobFormat = dobArr[2] + " " + dobArr[1] + " " + dobArr[3];
  return (
    <Stack
      sx={{
        mb: 1,
        p: 2,
        boxShadow: "5px 5px 24px 1px rgba(0, 0, 0, 0.02)",
        alignItems: "center",
      }}
      direction="row"
      spacing={3}
    >
      <Typography sx={{ flex: 1 }}>{invoiceNumber}</Typography>
      <Typography sx={{ flex: 1 }}>
        {name}
        {` (${userID || "applicant"})`}
      </Typography>

      <Typography sx={{ flex: 1 }}>
        {car}
        {carReg && ` (${carReg})`}
      </Typography>

      <Typography sx={{ flex: 1 }}>
        R{numberWithCommas(parseFloat(total).toFixed(2))}
      </Typography>

      <Typography sx={{ flex: 1 }}>{dobFormat}</Typography>
      <Typography sx={{ flex: 1 }}>
        <Chip
          size="small"
          color={status === "pending" ? "warning" : "info"}
          sx={{ alignSelf: "flex-start" }}
          label={status}
        ></Chip>
      </Typography>
      <Stack sx={{ flex: 1, alignItems: "center" }}>
        <IconButton
          onClick={() => {
            showInvoice(_id);
          }}
        >
          <ArrowForward />
        </IconButton>
      </Stack>
    </Stack>
  );
}
