import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import Chip from "@mui/material/Chip";
import * as React from "react";
import ButtonBase from "@mui/material/ButtonBase";
import { MdCancel } from "react-icons/md";
import IconButton from "@mui/material/IconButton";
import DocViewer from "../doc-viewer/view";

const Attachments = ({ attachments, close, setDoc, setDocViewerOpen }) => {
  return (
    <Stack
      sx={{
        borderRadius: 2,
        flex: 1,
        pt: 1,
        maxHeight: "60vh",
        overflow: "scroll",
      }}
    >
      <Stack
        direction="row"
        sx={{ justifyContent: "space-between", alignItems: "flex-start" }}
      >
        <Stack>
          <h3 style={{ margin: 0 }}>Attachments</h3>

          <Chip
            sx={{ alignSelf: "flex-start", mt: 0.5 }}
            color="info"
            label={
              attachments.length +
              " attachment" +
              (attachments.length > 1 ? "s" : "")
            }
          />
        </Stack>
        <IconButton onClick={close}>
          <MdCancel />
        </IconButton>
      </Stack>
      <Divider sx={{ mt: 1.5, mb: 1.5, opacity: 0.5 }} />
      {attachments.map((attachment) => (
        <>
          <ButtonBase
            onClick={() => {
              let filename = attachment.name;
              let splits = filename.split(".");
              setDoc({
                _id: attachment._id,
                fileType: splits[splits.length - 1],
                filename,
              });
              setDocViewerOpen(true);
            }}
            sx={{
              "& .MuiTouchRipple-child": {
                backgroundColor: "#B7F1F7",
              },
              p: 1,
              borderRadius: 2,
            }}
          >
            <Typography
              style={{ fontSize: 16 }}
              sx={{
                "&: hover": {
                  // textDecorationLine: "underline",
                },
                cursor: "pointer",
                textAlign: "left",
              }}
            >
              {attachment.name}
            </Typography>
          </ButtonBase>
          <Divider sx={{ mt: 1.5, mb: 1.5, opacity: 0.5 }} />
        </>
      ))}
    </Stack>
  );
};

export default Attachments;
