/* eslint-disable react-hooks/exhaustive-deps */
import * as React from "react";
import Stack from "@mui/material/Stack";
import Grow from "@mui/material/Grow";
import Button from "@mui/material/Button";
import { LinearProgress, Typography } from "@mui/material";
import { useSelector } from "react-redux";
import Applicant from "./applicant";
import ClientInfo from "./client";

import { doc, getDoc, getFirestore } from "firebase/firestore";

export default function ClientData() {
  const db = getFirestore();
  const checked = true;

  const match = useSelector((state) => state.conversation.match);
  const docId = match.docId;

  const [loading, setLoading] = React.useState(true);
  const [client, setClient] = React.useState(null);

  const getClientInfo = async () => {
    if (!docId) {
      setLoading(false);
      return;
    }
    setLoading(true);
    const docRef = doc(db, 'drivers', docId, 'profile', 'uploadedDocuments');
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
      setClient(docSnap.data());
    } else {
      setClient(null);
      console.log("No such document!");
    }
    setLoading(false);
  };

  React.useEffect(() => {
    getClientInfo();
  }, [docId]);

  const combinedData = { ...match, ...client };
  console.log('combinedData', combinedData)

  return (
    <Grow
      in={checked}
      style={{ transformOrigin: "0 0 0" }}
      {...(checked ? { timeout: 1000 } : {})}
    >
      <Stack
        sx={{
          width: "280px",
          height: "100vh",
          mr: 1,
          mt: 1,
          background: "white",
          p: 1,
          borderRadius: 2,
          overflow: "scroll",
          pb: 4,
        }}
      >
        {loading && <LinearProgress />}
        {false && (
          <Stack
            sx={{
              p: 2.5,
              boxShadow: "5px 5px 24px 1px rgba(181, 115, 25, 0.08)",
              borderRadius: 2,
              height: "20%",
            }}
          >
            <Stack
              sx={{ alignItems: "center", justifyContent: "space-between" }}
              direction="row"
            >
              <h4 style={{ margin: 0, color: "#2A234D" }}>Shared files</h4>
              <Button
                disabled
                sx={{
                  textTransform: "none",
                  color: "#2A234D",
                  borderRadius: 32,
                }}
              >
                See All
              </Button>
            </Stack>
            <Typography variant="caption">No files shared yet</Typography>
          </Stack>
        )}

        {match &&
          !loading && <Applicant data={combinedData} />}
        {!docId && (
          <Grow in={checked} style={{ transformOrigin: "0 0 0" }}>
            <Stack
              sx={{
                p: 2.5,
                boxShadow: "5px 5px 24px 1px rgba(181, 115, 25, 0.08)",
                borderRadius: 2,
                height: "100%",
                flex: 1,
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <p style={{ textAlign: "center" }}>
                Contact not found in <br />
                application or client list.
              </p>
            </Stack>
          </Grow>
        )}
        {false && (
          <Stack
            sx={{
              p: 2.5,
              boxShadow: "5px 5px 24px 1px rgba(181, 115, 25, 0.08)",
              borderRadius: 2,
              mt: 2,
              height: "40%",
            }}
          >
            <h4 style={{ margin: 0, color: "#2A234D" }}>Notes</h4>
          </Stack>
        )}
      </Stack>
    </Grow>
  );
}
