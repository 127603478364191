/* eslint-disable react-hooks/exhaustive-deps */
import * as React from "react";
import Divider from "@mui/material/Divider";
import Stack from "@mui/material/Stack";
import { IoOpen } from "react-icons/io5";
import DocViewer from "../../home/conversation/doc-viewer/view";

import Button from "@mui/material/Button";
import { OpenInFull, PictureAsPdf, UploadFile } from "@mui/icons-material";
import { useFilePicker } from "use-file-picker";

import Zoom from "react-medium-image-zoom";
import "react-medium-image-zoom/dist/styles.css";

import {
  getStorage,
  ref,
  uploadBytesResumable,
  getDownloadURL,
  uploadString,
} from "firebase/storage";

import {
  getFirestore,
  doc,
  updateDoc,
  arrayUnion,
  arrayRemove,
  increment,
  deleteDoc,
} from "firebase/firestore";
import { Typography } from "@mui/material";

function isImage(url) {
  return /\.(jpg|jpeg|png|webp|avif|gif|svg)$/.test(url);
}

export default function UserDocuments({ user }) {
  const [links, setLinks] = React.useState({});

  const formats = user.formats;

  const storage = getStorage();

  const [uploading, setUploading] = React.useState(false);

  const db = getFirestore();


  const [openFileSelector, { filesContent = [], loading, clear }] =
    useFilePicker({
      multiple: false,
      readAs: "DataURL", // availible formats: "Text" | "BinaryString" | "ArrayBuffer" | "DataURL"
      // accept: '.ics,.pdf',
      accept: ["image/*", ".pdf"],
    });

  
  const [uploadType, setUploadType] = React.useState(null);

  React.useEffect(() => {
    if (uploadType && filesContent.length > 0) {
      let format =
        filesContent[0].name.split(".")[
          filesContent[0].name.split(".").length - 1
        ];
      saveDocument(filesContent[0].content, format, uploadType);
    }
  }, [filesContent]);

  const saveDocument = async (content, mimeType, type) => {
    setUploading(true);
    const storageRef = ref(storage, "documents/" + user.userID + "/" + type);

    uploadString(storageRef, content, "data_url").then((snapshot) => {
      getDownloadURL(snapshot.ref).then(async (downloadURL) => {
        console.log(downloadURL);
        const docRef = doc(db, "clients", user.userID);
        let tempLinks = [];
        for (const property in links) {
          tempLinks.push({ [property]: links[property] });
        }
        if (tempLinks.findIndex((a) => a[type] != null) === -1) {
          tempLinks.push({ [type]: downloadURL });
        }

        await updateDoc(docRef, {
          links: tempLinks,
          formats: { ...formats, [type]: mimeType },
        });
        setUploading(false);
        clear();
      });

      setUploading(false);
    });
  };

  const [docV, setDoc] = React.useState({
    url: null,
    fileType: null,
    filename: null,
    open: false,
  });

  const DocContainer = ({ type, side }) => {
    if (links[type] == null || links[type] === "") {
      return (
        <div
          style={{
            justifyContent: "center",
            alignItems: "center",
            borderRightColor: "#B3D5FF",
            borderRightWidth: side === "last" ? 0 : 3,
            marginRight: 16,
          }}
        >
          <Button
            color="inherit"
            startIcon={<UploadFile />}
            sx={{ textTransform: "none" }}
            disabled={uploading && uploadType === type}
            onClick={async () => {
              setUploadType(type);
              openFileSelector();
            }}
          >
            {type === "id"
              ? "Passport/ID"
              : type === "bankStatement"
              ? "Statement"
              : type === "payslip"
              ? "Payslip"
              : "License"}
          </Button>
        </div>
      );
    }

    let format = formats && formats[type] ? formats[type] : null;
    let fileName =
      type === "id"
        ? "National ID/Passport"
        : type === "bankStatement"
        ? "Bank Statement"
        : type === "payslip"
        ? "Payslip"
        : "License";

    let link = links[type];
    return (
      <div
        style={{
          flex: 1,
          justifyContent: "center",
          alignItems: "center",
          borderRightColor: "white",
          borderRightWidth: side === "last" ? 0 : 3,
        }}
      >
        {isImage(
          `.${format.replace("application/", "").replace("image/", "")}`
        ) ? (
          <Stack sx={{}}>
            <Typography sx={{ mb: 0.5, fontSize: 14 }}>
              <b>{fileName}</b>
            </Typography>
            <Zoom>
              <img alt="" src={link} width="50" />
            </Zoom>
          </Stack>
        ) : (
          <Button
            color="inherit"
            endIcon={<IoOpen />}
            sx={{ textTransform: "none" }}
            onClick={() => {
              if (!format) {
                return window.open(link);
              }

              setDoc({
                url: link,
                fileType: format
                  .replace("application/", "")
                  .replace("image/", ""),
                filename: fileName,
                open: true,
              });
            }}
            style={{
              marginVertical: 24,
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            {type === "id"
              ? "View ID"
              : type === "bankStatement"
              ? "Statement"
              : type === "payslip"
              ? "Payslip"
              : "License"}
          </Button>
        )}
      </div>
    );
  };

  React.useEffect(() => {
    user.links &&
      setLinks({
        ...(user.links[0] || null),
        ...(user.links[1] || null),
        ...(user.links[2] || null),
        ...(user.links[3] || null),
      });
  }, [user]);

  return (
    <Stack sx={{}}>
      <h4 style={{ margin: 0 }}>User Documents</h4>

      <Stack sx={{ mt: 2 }} direction="row">
        <DocContainer type="id" side="right" />
        <DocContainer type="license" side="left" />
        <DocContainer type="payslip" side="right" />
        <DocContainer type="bankStatement" side="last" />
      </Stack>

      <Divider sx={{ mt: 2, mb: 2, opacity: 0.3 }} />
      {docV.open && (
        <DocViewer
          filename={docV.filename}
          open={docV.open}
          setOpen={() => {
            setDoc({ _id: null, fileType: null, filename: null, open: false });
          }}
          url={docV.url}
          id={docV.url}
          firestoreLink={docV.url}
          fileType={docV.fileType}
        />
      )}
    </Stack>
  );
}
