/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from "react";
import Divider from "@mui/material/Divider";
import Stack from "@mui/material/Stack";
import Zoom from "react-medium-image-zoom";
import "react-medium-image-zoom/dist/styles.css";
import Typography from "@mui/material/Typography";

const UserDocuments = ({ user }) => {

  const DocContainer = ({ type, side }) => {
    const fileName =
      type === "driversLicense"
        ? "Driver's License"
        : type === "ehailingProfile"
        ? "E-hailing Profile"
        : type === "profilePictureUri"
        ? "Profile Picture"
        : type === "vehicleFrontImage"
        ? "Vehicle Image"
        : type === "prdp"
        ? "PrDP"
        : "Vehicle Disk";

    const link = user[type];

    return (
      <div
        style={{
          flex: 1,
          justifyContent: "center",
          alignItems: "center",
          borderRightColor: "white",
          borderRightWidth: side === "last" ? 0 : 3,
          padding: "10px", // Add padding to give some space around the content
        }}
      >
          <Stack sx={{ mr: 1 }}>
            <Typography sx={{ mb: 0.5, fontSize: 14 }}>
              <b>{fileName}</b>
            </Typography>
            <Zoom zoomMargin={40} overlayBgColorEnd="rgba(0, 0, 0, 0.75)">
              {link && (<img
                alt={fileName}
                src={link}
                style={{ maxWidth: "100%", height: "auto" }}
              />)}
            </Zoom>
          </Stack>
      </div>
    );
  };

  return (
    <Stack sx={{}}>
      <Divider sx={{ mt: 2, mb: 2, opacity: 0.3 }} />
      <h3 style={{ margin: 0 }}>User Documents</h3>
      <Stack sx={{ mt: 2 }} direction="row">
        <DocContainer type="ehailingProfile" side="right" />
        <DocContainer type="driversLicense" side="left" />
        <DocContainer type="prdp" side="left" />
        <DocContainer type="vehicleLicenseDisk" side="left" />
        <DocContainer type="vehicleFrontImage" side="right" />
        <DocContainer type="profilePictureUri" side="last" />
      </Stack>
      <Divider sx={{ mt: 2, mb: 2, opacity: 0.3 }} />
    </Stack>
  );
};

export default UserDocuments;
