import * as React from "react";
import PropTypes from "prop-types";
import Avatar from "@mui/material/Avatar";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import { HiCheck } from "react-icons/hi2";
import Dialog from "@mui/material/Dialog";
import Stack from "@mui/material/Stack";
import PersonIcon from "@mui/icons-material/Person";
import Button from "@mui/material/Button";
import AddIcon from "@mui/icons-material/Add";
import { constants } from "../../../../../config/constants";
import { blue, green } from "@mui/material/colors";
import { useSelector } from "react-redux";
import { DialogActions, DialogContent, Typography } from "@mui/material";
import { useGetUsersQuery } from "../../../../../services/api-endpoints/user";

export default function SelectAssignee({
  open,
  setOpen,
  setReassigned,
  reassigned,
}) {
  const [selectedValue, setSelectedValue] = React.useState(null);
  const conversation = useSelector((state) => state.conversation);

  const [loading, setLoading] = React.useState(false);

  const reassignConversation = () => {
    setLoading(true);
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      target:
        conversation.type === "messenger"
          ? conversation.person_id
          : conversation.type === "whatsapp"
          ? conversation.wa_id
          : conversation.type === "facebook"
          ? conversation.post_id
          : conversation.email,
      assignee: selectedValue._id,
      channel: conversation.type,
    });

    var requestOptions = {
      method: "PATCH",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(`${constants.apiUrl}/messages/reassign`, requestOptions)
      .then((response) => {
        response.json();
      })
      .then((result) => {
        setReassigned(selectedValue);
        setLoading(false);
        setOpen(false);
        console.log(result);
      })
      .catch((error) => {
        alert("Error re-assigning");
        console.log("error", error);
      });
  };

  const { data: users = [], isLoading, isRefetching } = useGetUsersQuery();

  const handleClose = (value) => {
    setOpen(false);
    //  setSelectedValue(value);
  };

  return (
    <Dialog onClose={handleClose} open={open}>
      <Stack sx={{ pt: 2, pr: 2, pl: 2 }}>
        <h3 style={{ margin: 0 }}>Select Assignee</h3>
        <Typography variant="caption">
          {!reassigned
            ? (conversation.messages.length > 0 &&
                conversation.messages[0].assignee &&
                "Assigned to " +
                  conversation.messages[0].assignee.firstName +
                  " " +
                  conversation.messages[0].assignee.lastName) ||
              "Not assigned"
            : ""}
          {reassigned &&
            "Assigned to " + reassigned.firstName + " " + reassigned.lastName}
        </Typography>
      </Stack>
      <Stack
        sx={{
          height: "60vh",
          overflow: "scroll",
          width: 300,
          pr: 2,
          pl: 2,
          pb: 2,
        }}
      >
        <List sx={{}}>
          {users
            .filter((a) => a.role === "agent")
            .map((user) => (
              <ListItem disableGutters>
                <ListItemButton
                  color="info"
                  sx={{ borderRadius: 32 }}
                  onClick={() => {
                    setSelectedValue(user);
                  }}
                  key={user._id}
                >
                  <ListItemAvatar>
                    <Avatar
                      sx={{
                        bgcolor:
                          selectedValue === user ? green[500] : blue[100],
                        color: selectedValue === user ? "white" : blue[600],
                      }}
                    >
                      {selectedValue === user ? <HiCheck /> : <PersonIcon />}
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText
                    secondary={user.email}
                    primary={user.firstName + " " + user.lastName}
                  />
                </ListItemButton>
              </ListItem>
            ))}
        </List>
      </Stack>
      <DialogActions sx={{ background: "#FCFCFC" }}>
        <Button
          onClick={handleClose}
          variant="outlined"
          disableElevation
          sx={{ borderRadius: 32, textTransform: "none" }}
        >
          Cancel
        </Button>
        <Button
          onClick={reassignConversation}
          variant="contained"
          disabled={isLoading || loading || !selectedValue}
          disableElevation
          sx={{ borderRadius: 32, textTransform: "none" }}
          color="info"
        >
          Re-assign
        </Button>
      </DialogActions>
    </Dialog>
  );
}
