/* eslint-disable react-hooks/exhaustive-deps */
import * as React from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Stack from "@mui/material/Stack";
import IconButton from "@mui/material/IconButton";
import { Refresh } from "@mui/icons-material";

export default function ClientType({ setClientType, clientType, getInvoices }) {
  const handleClientType = (event, newValue) => {
    setClientType(newValue);
  };

  return (
    <Stack direction="row" sx={{ alignItems: "center" }} spacing={1}>
      <Tabs
        onChange={handleClientType}
        value={clientType}
        aria-label="basic tabs example"
      >
        <Tab sx={{ textTransform: "none" }} label="Invoices" />
        {false && (
          <>
            <Tab sx={{ textTransform: "none" }} label="Issues" />
            <Tab sx={{ textTransform: "none" }} label="Payments" />
          </>
        )}
      </Tabs>
      <IconButton
        onClick={() => {
          getInvoices();
        }}
      >
        <Refresh />
      </IconButton>
    </Stack>
  );
}
