/* eslint-disable react-hooks/exhaustive-deps */
import * as React from "react";
import { styled, alpha } from "@mui/material/styles";
import InputBase from "@mui/material/InputBase";
import { HiMagnifyingGlass, HiPlusCircle } from "react-icons/hi2";

import { updateConversation } from "../../../../data/slices/conversation";
import Stack from "@mui/material/Stack";
import IconButton from "@mui/material/IconButton";

import Button from "@mui/material/Button";
import { IoMail } from "react-icons/io5";
import { SiWhatsapp } from "react-icons/si";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import NewWhatsappMessage from "../conversation/social/new-wa/view";
import { useDispatch } from "react-redux";

const Search = styled("div")(({ theme }) => ({
  position: "relative",
  borderRadius: 8,
  backgroundColor: alpha(theme.palette.common.black, 0.02),
  "&:hover": {
    backgroundColor: alpha(theme.palette.common.black, 0.03),
  },
  width: "100%",
}));

const SearchIconWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: "100%",
  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "inherit",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: "20ch",
    },
  },
}));

const HtmlTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "white",
    color: "rgba(0, 0, 0, 0.87)",
    width: 220,
    maxWidth: 300,
    fontSize: theme.typography.pxToRem(12),
    boxShadow: "5px 5px 24px 1px rgba(0,0, 0, 0.07)",
    padding: 24,
    borderRadius: 16,
  },
}));

export default function ChatsHeader({
  setSearch,
  openCompose,
  openNewWA,
  setOpenSnack,
}) {
  const [open, setOpen] = React.useState(false);
  const [openWA, setOpenWA] = React.useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const dispatch = useDispatch();

  return (
    <Stack
      sx={{
        //  boxShadow: "5px 5px 24px 1px rgba(0,0, 0, 0.04)",
        background: "white",
        borderRadius: 2,

        pb: 2,
      }}
    >
      <Stack sx={{ pl: 2, pr: 2 }}>
        <Stack
          sx={{
            mb: 1,
            justifyContent: "space-between",
            alignItems: "start",
            mt: 2,
          }}
          direction="row"
        >
          <Stack>
            <h1 style={{ margin: 0 }}>Messages</h1>
          </Stack>

          <Stack direction="row">
            <Stack sx={{ alignItems: "center" }}>
              <ClickAwayListener onClickAway={handleClose}>
                <div>
                  <HtmlTooltip
                    open={open}
                    placement="bottom-start"
                    title={
                      <React.Fragment>
                        <h2
                          style={{
                            margin: 0,
                            marginTop: 8,
                          }}
                          color="inherit"
                        >
                          New Message
                        </h2>
                        <Button
                          variant="outlined"
                          size="large"
                          disableElevation
                          onClick={() => {
                            dispatch(
                              updateConversation({
                                newWATemplateMsgPhone: false,
                              })
                            );
                            handleClose();
                            setOpenWA(true);
                          }}
                          color="success"
                          sx={{
                            textTransform: "none",
                            borderRadius: 32,
                            mt: 2,
                            width: "100%",
                          }}
                          startIcon={<SiWhatsapp />}
                        >
                          New Message
                        </Button>
                        <Button
                          variant="outlined"
                          size="large"
                          onClick={() => {
                            handleClose();
                            openCompose();
                          }}
                          color="info"
                          sx={{
                            textTransform: "none",
                            borderRadius: 32,
                            mt: 1,
                            width: "100%",
                            mb: 2,
                          }}
                          startIcon={<IoMail />}
                        >
                          Compose Email
                        </Button>
                      </React.Fragment>
                    }
                  >
                    <IconButton onClick={handleOpen}>
                      <HiPlusCircle />
                    </IconButton>
                  </HtmlTooltip>
                </div>
              </ClickAwayListener>
            </Stack>
          </Stack>
        </Stack>

        <Stack sx={{ mt: 2 }}>
          <Search>
            <SearchIconWrapper>
              <HiMagnifyingGlass size={20} />
            </SearchIconWrapper>
            <StyledInputBase
              onChange={(e) => {
                setSearch(e.target.value);
              }}
              placeholder="Search…"
              inputProps={{ "aria-label": "search" }}
            />
          </Search>
        </Stack>
      </Stack>
      <NewWhatsappMessage
        setOpenSnack={setOpenSnack}
        open={openWA}
        setOpen={setOpenWA}
      />
    </Stack>
  );
}
