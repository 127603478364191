import React, { Component } from "react";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

const EditorContainer = ({ body, setBody }) => {
  return (
    <CKEditor
      editor={ClassicEditor}
      config={{
        toolbar: [
          "heading",
          "bold",
          "italic",
          "link",
          "undo",
          "redo",
          "numberedList",
          "bulletedList",
          "indent",
          "outdent",
        ],
      }}
      data={body}
      onReady={(editor) => {
        editor.editing.view.change((writer) => {
          writer.setStyle(
            "height",
            "300px",
            editor.editing.view.document.getRoot()
          );
          writer.setStyle(
            "borderStyle",
            "none",
            editor.editing.view.document.getRoot()
          );
        });
      }}
      onChange={(event, editor) => {
        const data = editor.getData();
        setBody(data);
      }}
    />
  );
};

export default EditorContainer;
