const filter = (data, search) => {
  console.log('FilterData', data)
  const { message, matchResult } = data;
  if (search.trim() === "") {
    return true;
  }
  let msgType = "whatsapp";
  if (message.whatsapp) msgType = "whatsapp";
  if (message.email) msgType = "email";
  if (message.comment) msgType = "comment";
  if (message.messenger) msgType = "messenger";
  let searchObj = { type: null, contact: null, content: null };

  if (msgType === "email") {
    let from = [];
    let to = [];
    message.email.to.value.forEach((t) => {
      to.push(t.address);
    });
    message.email.from.value.forEach((t) => {
      from.push(t.address);
    });

    searchObj.content = message.email.text + ", " + message.email.subject;
    if (from.includes("admin@drivetoown.com")) {
      searchObj.contact = to[0];
    } else {
      searchObj.contact = from[0];
    }
  } else if (msgType === "whatsapp") {
    searchObj = {
      contact: message.user_id + ", " + message.Name,
      content: message.message[0].content[0].text.value,
    };
    console.log('dataType',typeof searchObj.content); // Check the type

  } else if (msgType === "comment") {
    searchObj = {
      contact: message.comment.from.name,
      content: message.comment.message,
    };
  } else if (msgType === "messenger") {
    searchObj = {
      contact:
        (message.messenger.contact?.first_name || "") +
        (message.messenger.contact?.last_name || ""),
      content: message.messenger.message?.text || "",
    };
  }

  if (
    searchObj.contact &&
    searchObj.contact.toLowerCase().indexOf(search.toLowerCase()) !== -1
  )
    return true;
  if (
    searchObj.content &&
    searchObj.content.toLowerCase().indexOf(search.toLowerCase()) !== -1
  )
    return true;

  if (
    searchObj.content &&
    searchObj.content.toLowerCase().indexOf(search.toLowerCase()) !== -1
  )
    return true;

  if (
    matchResult.data &&
    matchResult.data.name.toLowerCase().indexOf(search.toLowerCase()) !== -1
  ) {
    return true;
  }

  return false;
};

const filterChannel = (data, channel) => {
  if (channel.name === "All Channels") {
    return true;
  }
  const { message } = data;

  if (message.whatsapp && channel.name.toLowerCase() === "whatsapp")
    return true;
  if (message.email && channel.name.toLowerCase() === "email") return true;
  if (message.comment && channel.name.toLowerCase() === "facebook") return true;
  if (message.messenger && channel.name.toLowerCase() === "messenger")
    return true;

  return false;
};

const filterAssignment = (data, assignment, _id) => {
  if (assignment === "all") {
    return true;
  }
  const { message } = data;

  if (
    assignment === "personal" &&
    message.assignee &&
    message.assignee._id === _id
  )
    return true;

  if (assignment === "unassigned" && !message.assignee) return true;
  return false;
};

const preprocessChats = (chats, item) => {
  let temp = [...chats];
  let whatsapp = item.whatsapp;
  let messenger = item.messenger;
  let comment = item.comment;
  if (!whatsapp && !messenger && !comment) {
    temp.push({ ...item, unread: item.read === true ? 0 : 1 });
  } else {
    let i = temp.findIndex((a) => {
      if (a.whatsapp && whatsapp) {
        return a.whatsapp.wa_id === whatsapp.wa_id;
      } else if (a.messenger && messenger) {
        return (
          a.messenger.recipient.id === messenger.recipient.id ||
          a.messenger.sender.id === messenger.recipient.id
        );
      } else if (a.comment && comment) {
        if (
          a.comment.comment_id === comment.parent_id ||
          a.comment.parent_id === comment.comment_id
        ) {
          return true;
        }
      }
      return false;
    });
    if (i === -1) {
      temp.push({ ...item, unread: item.read ? 0 : 1 });
    } else {
      let unread = temp[i].unread || 0;
      if (item.read === false) {
        unread += 1;
      }
      temp[i] = {
        ...temp[i],
        unread: unread,
      };
    }
  }

  return temp;
};

export { filter, filterChannel, filterAssignment, preprocessChats };
