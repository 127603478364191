import ButtonBase from "@mui/material/ButtonBase";
import Chip from "@mui/material/Chip";
import Stack from "@mui/material/Stack";
import { Typography } from "@mui/material";
import { FcDocument } from "react-icons/fc";
import { constants } from "../../../../../../config/constants";
import IconButton from "@mui/material/IconButton";
import { BsFileEarmarkPdfFill, BsDownload } from "react-icons/bs";
import DocViewer from "../../doc-viewer/view";
import * as React from "react";

export default function MessageDocument({
  document: document_m,
  senderType,
  filePath = null,
  url = null,
  filetype,
}) {
  const [doc, setDoc] = React.useState({
    _id: null,
    fileType: null,
    filename: "",
    url: null,
  });
  const [docViewerOpen, setDocViewerOpen] = React.useState(false);

  return (
    <>
      {document_m.mime_type === "application/pdf" && (
        <ButtonBase
          onClick={() => {
            let filename = document_m.filename;
            let splits = filename.split(".");
            setDoc({
              _id: filePath || document_m.id,
              fileType: filetype || splits[splits.length - 1],
              filename,
              url,
            });
            setDocViewerOpen(true);
          }}
          sx={{
            borderRadius: 3,
            borderTopLeftRadius: senderType === "client" ? 0 : null,
            borderTopRightRadius: senderType === "client" ? null : 0,
            maxWidth: "300px",
            textAlign: "left",
            "word-break": "break-all",
            // ml: 4,
          }}
        >
          <Stack
            sx={{
              alignItems: "center",
              background: "rgb(255, 0, 13, 0.08)",
              borderRadius: 3,
              borderTopLeftRadius: senderType === "client" ? 0 : null,
              borderTopRightRadius: senderType === "client" ? null : 0,
              boxShadow: "5px 5px 24px 1px rgba(0, 0, 0, 0.01)",
              p: 1.5,
            }}
            direction="row"
          >
            <BsFileEarmarkPdfFill color="red" />
            <Typography sx={{ ml: 1 }}>
              {document_m.filename.replace(".pdf", "")}
            </Typography>
            <Chip
              sx={{ alignSelf: "flex-start", ml: 1 }}
              size="small"
              label="pdf"
            />
          </Stack>
        </ButtonBase>
      )}
      {document_m.mime_type !== "application/pdf" && (
        <ButtonBase
          onClick={() => {
            let filename = document_m.filename;
            let splits = filename.split(".");
            setDoc({
              _id: document_m.id,
              fileType: splits[splits.length - 1],
            });
            setDocViewerOpen(true);
          }}
          sx={{
            borderRadius: 3,
            borderTopLeftRadius: senderType === "client" ? 0 : null,
            borderTopRightRadius: senderType === "client" ? null : 0,
            maxWidth: "300px",
            textAlign: "left",
          }}
        >
          <Stack
            sx={{
              alignItems: "center",
              background: "rgba(127, 198, 255, 0.19)",
              borderRadius: 3,
              borderTopLeftRadius: senderType === "client" ? 0 : null,
              borderTopRightRadius: senderType === "client" ? null : 0,
              boxShadow: "5px 5px 24px 1px rgba(0, 0, 0, 0.01)",
              p: 1.5,
            }}
            direction="row"
          >
            <FcDocument />
            <Typography sx={{ ml: 1 }}>{document_m.filename}</Typography>
          </Stack>
        </ButtonBase>
      )}
      <IconButton
        size="small"
        sx={{ alignSelf: "flex-start", mt: 0.5 }}
        onClick={() => {
          fetch(
            filePath || `${constants.apiUrl}/documents/${document_m.id}`
          ).then((response) => {
            response.blob().then((blob) => {
              // Creating new object of PDF file
              const fileURL = window.URL.createObjectURL(blob);

              let alink = document.createElement("a");
              alink.href = fileURL;
              alink.download = document_m.filename;
              alink.click();
            });
          });
        }}
      >
        <BsDownload size={16} />
      </IconButton>
      {docViewerOpen && (
        <DocViewer
          filename={doc.filename}
          open={docViewerOpen}
          setOpen={setDocViewerOpen}
          id={doc._id}
          url={url}
          fileType={doc.fileType}
        />
      )}
    </>
  );
}
