import React from 'react';
import Stack from "@mui/material/Stack";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";

import { MdGetApp } from "react-icons/md";
import { ArrowForward } from "@mui/icons-material";

function numberWithCommas(x) {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

function days_between(date1, date2) {
  const ONE_DAY = 1000 * 60 * 60 * 24;

  const differenceMs = Math.abs(date1 - date2);

  return Math.round(differenceMs / ONE_DAY);
}

export default function Row({ item, showClient }) {
  return (
    <Stack
      sx={{
        mb: 1,
        p: 2,
        boxShadow: "5px 5px 24px 1px rgba(0, 0, 0, 0.02)",
        alignItems: "center",
      }}
      direction="row"
      spacing={3}
    >
      <Typography sx={{ flex: 1 }}>
        {item.appActive && (
          <MdGetApp style={{ marginRight: 8 }} color="blue" size={16} />
        )}
        {item.Name}
      </Typography>

      <Typography sx={{ flex: 1 }}>{item.car_make_and_model
      }</Typography>

      <Typography
        sx={{ flex: 1, color: item.wallet_balance < 0 ? "red" : "black" }}
      >
        R{numberWithCommas(parseFloat(item.wallet_balance).toFixed(2))}
      </Typography>
      <Typography sx={{ flex: 1 }}>{item.trips
      }</Typography>
      <Typography sx={{ flex: 1 }}>{item.user_id}</Typography>
      <Stack sx={{ flex: 1, alignItems: "center" }}>
        <IconButton
          onClick={() => {
            showClient(item.user_id);
          }}
        >
          <ArrowForward />
        </IconButton>
      </Stack>
    </Stack>
  );
}
