/* eslint-disable react-hooks/exhaustive-deps */
import * as React from "react";
import Dialog from "@mui/material/Dialog";
import Stack from "@mui/material/Stack";
import Slide from "@mui/material/Slide";
import Badge from "@mui/material/Badge";
import EditorContainer from "./editor";
import Divider from "@mui/material/Divider";
import { HeaderCC } from "./cc";
import { HeaderTo } from "./to";
import { IoAttachOutline } from "react-icons/io5";
import IconButton from "@mui/material/IconButton";
import Button from "@mui/material/Button";
import { FiSend } from "react-icons/fi";
import Avatar from "@mui/material/Avatar";
import { Typography } from "@mui/material";
import DragDropFile from "./attachments/view";
import { styled } from "@mui/material/styles";
import { ClickAwayListener } from "@mui/base";
import { useSelector } from "react-redux";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import LinearProgress from "@mui/material/LinearProgress";
import { constants } from "../../../../../../config/constants";

const HtmlTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    background: "white",
    fontSize: theme.typography.pxToRem(12),
    boxShadow: "5px 5px 24px 1px rgba(0, 0, 0, 0.1)",
    color: "black",
    padding: 16,
    borderRadius: 12,
    zIndex: 2000,
    maxWidth: null,
    width: "400px",
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function MailCompose({ open = false, setOpen, closeMail }) {
  const [attachOpen, setAttachOpen] = React.useState(false);
  const [files, setFiles] = React.useState([]);

  const uploading = files.findIndex((a) => a.uploading === true) !== -1;

  const [mail, setMail] = React.useState({
    to: [],
    cc: [],
    body: null,
    subject: null,
    attachments: [],
  });

  React.useEffect(() => {
    let temp = [];
    files.forEach((file) => {
      if (file.dbRef) {
        temp.push(file.dbRef);
      }
    });
    setMail((mail) => ({
      ...mail,
      attachments: temp,
    }));
  }, [files]);

  const [loading, setLoading] = React.useState(false);

  const composeData = useSelector((state) => state.compose);

  const sendEmail = () => {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      attachments: mail.attachments,
      subject: mail.subject,
      to: mail.to.join(),
      content: mail.body,
      inReplyTo: composeData.inReplyTo || "",
      cc: mail.cc.join(),
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    setLoading(true);

    fetch(`${constants.apiUrl}/email`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        setLoading(false);
        closeMail();
      })
      .catch((error) => {
        alert("Sending email failed");
        setLoading(false);
        console.log("error", error);
      });
  };


  React.useEffect(() => {
    setMail((mail) => ({
      ...mail,
      subject: composeData.subject,
    }));
  }, [composeData]);

  return (
    <Dialog
      open={open}
      fullWidth
      maxWidth="sm"
      PaperProps={{
        style: { borderRadius: "16px" },
      }}
      TransitionComponent={Transition}
      onClose={() => {
        setOpen(false);
      }}
    >
      <Stack
        sx={{
          p: 2,
          pt: 1,
        }}
      >
        <Stack sx={{ mt: 2 }}>
          <HeaderTo
            setRecipients={(recipients) => {
              setMail((mail) => ({
                ...mail,
                to: [...recipients],
              }));
            }}
          />
          <HeaderCC
            setCc={(recipients) => {
              setMail((mail) => ({
                ...mail,
                cc: [...recipients],
              }));
            }}
          />
        </Stack>

        <Divider sx={{ mb: 2, opacity: 0.4 }} />
        <Stack sx={{ pl: 2, pr: 2 }}>
          <input
            onChange={(e) => {
              setMail((mail) => ({
                ...mail,
                subject: e.target.value,
              }));
            }}
            value={mail.subject}
            style={{
              outline: "none",
              border: "0px",
              flex: 1,
              fontSize: 16,
            }}
            placeholder="Subject"
          />
        </Stack>
        <Divider sx={{ mb: 2, opacity: 0.4, mt: 2 }} />
        <EditorContainer
          body={mail.body}
          setBody={(data) => {
            setMail((mail) => ({
              ...mail,
              body: data,
            }));
          }}
        />
      </Stack>
      <Stack
        sx={{
          background: "rgb(11, 132, 236, 0.03)",
          borderRadius: "16px",

          borderTopLeftRadius: 0,
          borderTopRightRadius: 0,
        }}
      >
        {(uploading || loading) && <LinearProgress color="info" />}
        <Stack
          direction="row"
          sx={{
            alignItems: "center",
            //  boxShadow: "5px 5px 24px 1px rgba(0,0, 0, 0.08)",
            justifyContent: "space-between",
            p: 2,
          }}
        >
          <Stack direction="row" sx={{ alignItems: "center" }}>
            <Avatar
              size="small"
              sx={{
                fontSize: 12,
                mr: 1,
                background: "black",
                color: "white",
              }}
            >
              D
            </Avatar>
            <Typography sx={{ fontSize: 13 }} variant="caption">
            sales@drivetoown.co.za
            </Typography>
          </Stack>

          <Stack direction="row" sx={{ alignItems: "center" }}>
            <ClickAwayListener
              onClickAway={() => {
                setAttachOpen(false);
              }}
            >
              <div>
                <HtmlTooltip
                  placement="top-start"
                  open={attachOpen}
                  title={<DragDropFile files={files} setFiles={setFiles} />}
                >
                  <IconButton
                    onClick={() => {
                      setAttachOpen(true);
                    }}
                    sx={{ mr: 2 }}
                  >
                    <Badge
                      color="info"
                      variant="standard"
                      badgeContent={files.length}
                    >
                      <IoAttachOutline />
                    </Badge>
                  </IconButton>
                </HtmlTooltip>
              </div>
            </ClickAwayListener>

            <Button
              variant="contained"
              color="info"
              onClick={sendEmail}
              disabled={
                uploading ||
                loading ||
                mail.body === "" ||
                mail.body === null ||
                mail.to.length === 0
              }
              disableElevation
              sx={{ borderRadius: 32, textTransform: "none" }}
              size="medium"
              startIcon={<FiSend />}
            >
              Send
            </Button>
          </Stack>
        </Stack>
      </Stack>
    </Dialog>
  );
}
