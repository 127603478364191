/* eslint-disable react-hooks/exhaustive-deps */
import * as React from "react";
import { styled, alpha } from "@mui/material/styles";
import InputBase from "@mui/material/InputBase";
import { HiMagnifyingGlass, HiPlusCircle } from "react-icons/hi2";

import Stack from "@mui/material/Stack";
import IconButton from "@mui/material/IconButton";
import { BsFilter } from "react-icons/bs";
import { useDispatch } from "react-redux";

import Badge from "@mui/material/Badge";

import { useSelector } from "react-redux";

const Search = styled("div")(({ theme }) => ({
  position: "relative",
  borderRadius: 8,
  backgroundColor: alpha(theme.palette.common.black, 0.02),
  "&:hover": {
    backgroundColor: alpha(theme.palette.common.black, 0.03),
  },
  width: "100%",
}));

const SearchIconWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: "100%",
  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "inherit",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: "20ch",
    },
  },
}));

export default function SearchComponent({ setSearch }) {
  return (
    <div style={{ alignSelf: "center" }}>
      <Search>
        <SearchIconWrapper>
          <HiMagnifyingGlass size={20} />
        </SearchIconWrapper>
        <StyledInputBase
          onChange={(e) => {
            setSearch(e.target.value);
          }}
          placeholder="Search…"
          inputProps={{ "aria-label": "search" }}
        />
      </Search>
    </div>
  );
}
