import * as React from "react";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import LinearProgress from "@mui/material/LinearProgress";
import TextField from "@mui/material/TextField";
import {
  doc,
  updateDoc,
  getFirestore,
  collection,
  getDocs,
  query,
  where,
} from "firebase/firestore";

export default function AssignCar({ handleTooltipClose, userID }) {
  const [reg, setReg] = React.useState("");
  const [loading, setLoading] = React.useState(false);

  const db = getFirestore();

  const assignCar = async () => {
    try {
      setLoading(true);
      let url = "https://server.drivetoown.co.za/cartrack/details/" + reg;
      const response = await fetch(url);
      const json = await response.json();
      let carinfo = json;

      const docRef = doc(db, "clients", userID);
      if (carinfo.car && carinfo.car.manufacturer) {
        await updateDoc(docRef, {
          car:
            carinfo.car.manufacturer +
            " " +
            carinfo.car.model +
            " " +
            carinfo.car.model_year,
          carReg: reg.toUpperCase(),
          carActive: true,
        });
        setLoading(false);
      } else {
        const invRef = collection(db, "inventory");
        const q = query(
          invRef,
          where("registrations", "array-contains", reg.toUpperCase())
        );

        const querySnapshot = await getDocs(q);

        let result = {};
        querySnapshot.forEach((doc) => {
          result = doc.data();
        });

        await updateDoc(docRef, {
          car: result.name + " " + result.model,
          carReg: reg.toUpperCase(),
          carActive: false,
        });

        setLoading(false);
      }
    } catch (e) {
      setLoading(false);
      alert("Sorry, an error was encountered");
    }
  };

  return (
    <React.Fragment>
      <Typography variant="h6">
        <b>Assign a car</b>
      </Typography>
      {loading && <LinearProgress sx={{ mt: 1, borderRadius: 32 }} />}

      <TextField
        fullWidth
        value={reg}
        onChange={(e) => {
          setReg(e.target.value);
        }}
        sx={{
          "& fieldset": {
            borderRadius: 32,
          },
          "& .MuiOutlinedInput-root": {
            "& fieldset": {
              borderColor: "rgba(0, 0, 0, 0.05)",
            },
          },
          mt: 2,
        }}
        placeholder="Car registration"
      />

      <Stack sx={{ mt: 2, mb: 2 }}>
        <Button
          color="info"
          onClick={assignCar}
          disabled={reg.trim() === "" || loading}
          disableElevation
          variant="contained"
          sx={{ mr: 1, borderRadius: 32, height: 45 }}
        >
          Save
        </Button>
        <Button
          onClick={handleTooltipClose}
          disableElevation
          color="warning"
          variant="outlined"
          sx={{ mr: 1, borderRadius: 32, mt: 1, height: 45 }}
        >
          Cancel
        </Button>
      </Stack>
    </React.Fragment>
  );
}
