import * as React from "react";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import { LinearProgress } from "@mui/material";
import Collapse from "@mui/material/Collapse";
import { IoPencil } from "react-icons/io5";
import { getFirestore, doc, updateDoc } from "firebase/firestore";
import IconButton from "@mui/material/IconButton";
import EditCollectionDate from "./collection_date";
import EditReservationNumber from "./reservation_number";

import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import { styled } from "@mui/material/styles";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { ClickAwayListener } from "@mui/base";

const locations = [
  {
    title: "Airports",
    data: [
      "Johannesburg OR Tambo International Airport",
      "Cape Town International Airport",
      "King Shaka International Airport",
      "Lanseria Airport",
      "Bloemfontein Airport",
      "East London Airport",
      "George Airport",
      "Kimberley Airport",
      "Kruger Mpumalanga Int Airport",
      "Polokwane Airport",
      "Pietermaritzburg Airport",
      "Port Elizabeth Airport",
      "Richards Bay Airport",
      "Umtata Airport",
      "Upington Airport",
      "Wonderboom Airport",
    ],
  },
  {
    title: "Gauteng",
    data: [
      "Braamfontein",
      "Centurion",
      "Vanderbijlpark",
      "Fourways",
      "Braamfontein-Parkstation",
      "Kramerville",
      "Meadowdale",
      "Mogale City (Krugersdorp Agency)",
      "Hatfield",
      "Sandton",
    ],
  },
  {
    title: "Western Cape",
    data: [
      "Mossel Bay",
      "Cape Town City",
      "N1 City Goodwood",
      "Saldanha",
      "Stellenbosch",
    ],
  },
  {
    title: "KwaZulu-Natal",
    data: [
      "Durban Downtown",
      "Ladysmith",
      "Newcastle",
      "Pinetown",
      "Umhlanga",
      "Port Shepstone",
    ],
  },
  {
    title: "Free State",
    data: ["Bloemfontein Downtown"],
  },
  {
    title: "Eastern Cape",
    data: [
      "Queenstown",
      "Port Elizabeth Downtown",
      "East London Down Town",
      "Jeffreys Bay",
    ],
  },
  {
    title: "Limpopo",
    data: ["Hoedspruit", "Venda"],
  },
  {
    title: "Mpumalanga",
    data: ["Nelspruit Downtown", "Secunda", "Witbank", "Ermelo"],
  },
  {
    title: "North West",
    data: ["Klerksdorp", "Mafikeng", "Rustenburg"],
  },
  {
    title: "Northern Cape",
    data: ["Kathu"],
  },
];

let transformed = [];

locations.forEach((a) => {
  a.data.forEach((x) => {
    transformed.push({ title: x, category: a.title });
  });
});


const HtmlTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    background: "white",
    width: 250,
    fontSize: theme.typography.pxToRem(12),
    boxShadow: "5px 5px 24px 1px rgba(0, 0, 0, 0.25)",
    color: "black",
    padding: 16,
    borderRadius: 12,
  },
}));

export default function CollectionDetails({ user }) {
  const [loading, setLoading] = React.useState(false);

  const [openTooltip, setOpenTooltip] = React.useState({
    item: null,
  });

  const db = getFirestore();

  return (
    <Stack
      sx={{
        p: 2,
        mt: 2,
        borderRadius: 2,
        borderColor: "silver",
        borderStyle: "dotted",
      }}
    >
      <h4 style={{ margin: 0 }}>Collection Details</h4>

      <Stack sx={{ mt: 2, alignItems: "center" }} direction="row">
        <ClickAwayListener
          onClickAway={() => {
            if (openTooltip.item === "start_date")
              setOpenTooltip({ item: null });
          }}
        >
          <div>
            <HtmlTooltip
              placement="bottom-start"
              open={openTooltip.item === "start_date"}
              title={
                <EditCollectionDate
                  handleTooltipClose={() => {
                    setOpenTooltip({ item: null });
                  }}
                  userID={user.applicationId}
                />
              }
            >
              <IconButton
                onClick={() => {
                  setOpenTooltip({ item: "start_date" });
                }}
                color="info"
                disabled={loading}
                sx={{ textTransform: "none", mr: 1 }}
              >
                <IoPencil size={15} />
              </IconButton>
            </HtmlTooltip>
          </div>
        </ClickAwayListener>
        <Stack>
          <Typography sx={{ mt: 2 }}>Collection Date</Typography>
          <Typography sx={{ fontSize: 13 }} variant="caption">
            {typeof user.collectionDate === "string" ||
            user.collectionDate instanceof String
              ? user.collectionDate
              : "Not available"}
          </Typography>
        </Stack>
        <Stack sx={{ mr: 3, ml: 3 }}>
          <Typography sx={{ mt: 2 }}>Collection Time</Typography>
          <Typography sx={{ fontSize: 13 }} variant="caption">
            {user.collectionTime || "Not available"}
          </Typography>
        </Stack>
      </Stack>
      <Stack sx={{ mt: 2, alignItems: "center" }} direction="row">
        <ClickAwayListener
          onClickAway={() => {
            if (openTooltip.item === "sub_expires")
              setOpenTooltip({ item: null });
          }}
        >
          <div>
            <HtmlTooltip
              placement="bottom-start"
              open={openTooltip.item === "sub_expires"}
              title={
                <EditReservationNumber
                  handleTooltipClose={() => {
                    setOpenTooltip({ item: null });
                  }}
                  userID={user.applicationId}
                />
              }
            >
              <IconButton
                onClick={() => {
                  setOpenTooltip({ item: "sub_expires" });
                }}
                color="info"
                disabled={loading}
                sx={{ textTransform: "none", mr: 1 }}
              >
                <IoPencil size={15} />
              </IconButton>
            </HtmlTooltip>
          </div>
        </ClickAwayListener>
        <Stack>
          <Typography sx={{ mt: 1 }}>Reservation Number</Typography>

          <Typography sx={{ fontSize: 13 }} variant="caption">
            {typeof user.reservationNumber === "string" ||
            user.reservationNumber instanceof String
              ? user.reservationNumber
              : "Not available"}
          </Typography>
        </Stack>
      </Stack>
      <Autocomplete
        options={transformed}
        groupBy={(option) => option.category}
        getOptionLabel={(option) => option.title}
        fullWidth
        defaultValue={transformed.find((a) => a.title === user.location)}
        sx={{ mt: 3 }}
        onChange={async (event, newValue) => {
          if (newValue != null) {
            const docRef = doc(db, "applications", user.applicationId);
            await updateDoc(docRef, {
              location: newValue.title,
            });
          }
        }}
        renderInput={(params) => (
          <TextField {...params} label="Collection Location" />
        )}
      />

      <Collapse in={loading} sx={{ mt: 2 }}>
        <LinearProgress color="info" />
      </Collapse>
    </Stack>
  );
}
