import * as React from "react";
import Stack from "@mui/material/Stack";
import ButtonBase from "@mui/material/ButtonBase";
import Chip from "@mui/material/Chip";
import LinearProgress from "@mui/material/LinearProgress";
import { templates } from "./data";
import { Typography } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import Grow from "@mui/material/Grow";
import { updateTemplates } from "../../../../../../data/slices/templates";

export default function TemplateSelector({ selected, setSelected }) {
  const checked = true;

  const dispatch = useDispatch();
  const list = useSelector((state) => state.templates);

  var requestOptions = {
    method: "GET",
    redirect: "follow",
  };

  fetch(
    "https://graph.facebook.com/v20.0/292083027328452/message_templates?access_token=EAAOR17qWmZBIBO13v2MJZCHheVW2ZAEMv46jeOicvTSW53x1av2agMhzMzH9NpibIx6D1TzWSEwxC4IgOP3r8kyyGytYxvOloOYyBudEQnqdTyZAZChr4coMlfliYVJrhYVgT83kPo0yMAnWEEr9OK8ORZAGjGWCa9Njua1bkfjPrZATVqrJhyfsKPyZAd3R33Gk",
    requestOptions
  )
    .then((response) => response.json())
    .then((result) => {
      console.log(result.data);
      result.data &&
        dispatch(
          updateTemplates(
            result.data.filter((a) => a.name.indexOf("sample_") === -1)
          )
        );
    })
    .catch((error) => console.log("error", error));
  return (
    <Stack sx={{ mt: 2, width: 390 }}>
      {list.map((template) => {
        return (
          <Grow
            in={checked}
            style={{ transformOrigin: "0 0 0" }}
            {...(checked ? { timeout: 1000 } : {})}
          >
            <ButtonBase
              onClick={() => {
                setSelected(template);
              }}
              disabled={template.status !== "APPROVED"}
              sx={{
                mb: 2,
                p: 2,
                borderColor:
                  selected && selected.name === template.name
                    ? "blue"
                    : "white",
                borderRadius: 2,
                transition: "all .5s ease",
                WebkitTransition: "all .5s ease",
                MozTransition: "all .5s ease",

                boxShadow: "5px 5px 24px 1px rgba(0,0, 0, 0.07)",
                textAlign: "left",
                fontSize: 15,
                borderStyle: "solid",
                borderWidth: 1,
              }}
            >
              <Stack
                sx={{
                  textAlign: "left",
                  flex: 1,
                  opacity: template.status !== "APPROVED" ? 0.5 : 1,
                }}
              >
                {template.components.map((e) => {
                  if (e.type === "HEADER") {
                    return (
                      <p>
                        <b>{e.text}</b>
                        <br />
                        <br />
                      </p>
                    );
                  } else if (e.type === "BODY") {
                    return (
                      <p>
                        {e.text}
                        <br />
                        <br />
                      </p>
                    );
                  } else if (e.type === "FOOTER") {
                    return <Typography variant="caption">{e.text}</Typography>;
                  }
                  return null;
                })}
                <Chip
                  sx={{ alignSelf: "flex-start", mt: 1 }}
                  label={template.status}
                />
              </Stack>
            </ButtonBase>
          </Grow>
        );
      })}
    </Stack>
  );
}
