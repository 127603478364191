const filter = (data, search) => {
  if (search.trim() === "") {
    return true;
  }
  if (data.name && data.name.toLowerCase().indexOf(search.toLowerCase()) !== -1)
    return true;
  if (data.car && data.car.toLowerCase().indexOf(search.toLowerCase()) !== -1)
    return true;

  if (data.email.toLowerCase().indexOf(search.toLowerCase()) !== -1)
    return true;

  if (data.invoiceNumber.toLowerCase().indexOf(search.toLowerCase()) !== -1)
    return true;

  if (
    data.carReg &&
    data.carReg.toLowerCase().indexOf(search.toLowerCase()) !== -1
  )
    return true;

  if (
    data.userID &&
    data.userID.toLowerCase().indexOf(search.toLowerCase()) !== -1
  )
    return true;

  return false;
};

export { filter };
