import Stack from "@mui/material/Stack";
import * as React from "react";
import { useDispatch } from "react-redux";
import Grow from "@mui/material/Grow";
import LinearProgress from "@mui/material/LinearProgress";
import { getAuth } from "firebase/auth";
import { useNavigate } from "react-router-dom";
import { updateAuth } from "../../../data/slices/auth";

const Logout = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [signingOut, setSigningOut] = React.useState(true);

  React.useEffect(() => {
    const signOut = async () => {
      try {
        const auth = getAuth();
        await auth.signOut();
        dispatch(updateAuth({ name: null, email: null }));
        navigate("/signin"); // Redirect to sign-in page after logout
      } catch (error) {
        console.error("Sign out error:", error);
        // Optionally handle the error or show a message
      } finally {
        setSigningOut(false); // Update state after the sign-out operation
      }
    };

    signOut();
  }, [dispatch, navigate]);

  return (
    <Stack
      sx={{
        p: 4,
        pt: 2,
        flex: 1,
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Grow
        in={signingOut}
        style={{ transformOrigin: "0 0 0" }}
        {...(signingOut ? { timeout: 1000 } : {})}
      >
        <div
          style={{
            alignItems: "center",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <h3>Signing you out...</h3>
          <LinearProgress sx={{ borderRadius: 3, width: 100, mb: 1 }} />
        </div>
      </Grow>
    </Stack>
  );
};

export default Logout;
