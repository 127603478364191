/* eslint-disable react-hooks/exhaustive-deps */
import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Divider from "@mui/material/Divider";
import Slide from "@mui/material/Slide";
import { Typography, LinearProgress, Chip, Stack } from "@mui/material";
import NewInvoice from "../../clients/modal/invoice";
import Collapse from "@mui/material/Collapse";
import CircularProgress from "@mui/material/CircularProgress";
import { doc, getDoc, getFirestore, onSnapshot } from "firebase/firestore";
// eslint-disable-next-line react-hooks/exhaustive-deps

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const commaSeparateWhole = (number) => {
  const fixedNumber = Math.floor(number);
  return String(fixedNumber).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

export default function InvoiceDialog({ open, setOpen, _id, getInvoices }) {
  const handleClose = () => {
    setOpen(false);
  };

  const [invoice, setInvoice] = React.useState(null);
  const [loading, setLoading] = React.useState(false);

  const getInvoice = async () => {
    setLoading(true);
    var requestOptions = {
      method: "GET",
      redirect: "follow",
    };

    fetch(`https://server.drivetoown.co.za/invoices/${_id}`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        setInvoice(result);

        setLoading(false);
      })
      .catch((error) => {
        console.log("error", error);

        setLoading(false);
      });
  };

  const deleteInvoice = async () => {
    setLoading(true);
    var requestOptions = {
      method: "DELETE",
      redirect: "follow",
    };

    fetch(`https://server.drivetoown.co.za/invoices/${_id}`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        setLoading(false);
        getInvoices();
        handleClose();
      })
      .catch((error) => {
        console.log("error", error);
        setLoading(false);
      });
  };

  React.useEffect(() => {
    _id && getInvoice();
  }, [_id]);

  const [user, setUser] = React.useState(null);

  const db = getFirestore();

  const [loadingUser, setLoadingUser] = React.useState(false);

  const [showInvoice, setShowInvoice] = React.useState(false);
  const [missingUser, setMissingUser] = React.useState(false);

  const getUser = async () => {
    setLoadingUser(true);
    const docRef = doc(
      db,
      invoice.applicationId ? "applications" : "clients",
      invoice.applicationId ? invoice.applicationId : invoice.userID
    );
    const docSnap = await getDoc(docRef);

    setLoadingUser(false);

    if (docSnap.exists()) {
      setUser(docSnap.data());
      setShowInvoice(true);
    } else {
      setMissingUser(true);
    }
  };

  var dobArr = new Date(invoice ? invoice.dueDate : null)
    .toDateString()
    .split(" ");
  var dobFormat = dobArr[2] + " " + dobArr[1] + " " + dobArr[3];

  return (
    <Dialog TransitionComponent={Transition} open={open} onClose={handleClose}>
      <Collapse in={loading}>
        <LinearProgress />
      </Collapse>
      <DialogTitle>Manage Invoice</DialogTitle>

      <DialogContent sx={{ width: 400 }}>
        <Collapse in={invoice}>
          {invoice && (
            <>
              <Stack sx={{}}>
                <Stack
                  direction="row"
                  sx={{ alignItems: "center", justifyContent: "space-between" }}
                >
                  <Typography>
                    <b>{invoice.invoiceNumber}</b>
                  </Typography>
                  <Typography variant="subtitle">Due {dobFormat}</Typography>
                </Stack>
                <Chip
                  size="small"
                  color={invoice.status === "pending" ? "warning" : "info"}
                  sx={{ alignSelf: "flex-start" }}
                  label={invoice.status}
                ></Chip>

                <Divider sx={{ mt: 2, mb: 2, opacity: 0.4 }} />

                <Stack sx={{}}>
                  {invoice.items.map((lineItem) => {
                    return (
                      <Stack>
                        <Typography>
                          {lineItem.description} - R
                          {commaSeparateWhole(
                            (lineItem.cost * lineItem.quantity).toFixed(2)
                          )}{" "}
                          {`(${commaSeparateWhole(lineItem.quantity)}  units)`}
                        </Typography>
                      </Stack>
                    );
                  })}
                </Stack>

                <Divider sx={{ mt: 2, mb: 2, opacity: 0.4 }} />

                <Stack
                  direction="row"
                  sx={{
                    alignItems: "center",
                    justifyContent: "space-between",
                    mt: 2,
                  }}
                >
                  <Typography sx={{}}>
                    Total:{" "}
                    <b>
                      R
                      {commaSeparateWhole(
                        (invoice.total ? invoice.total : 0).toFixed(2)
                      )}
                    </b>
                  </Typography>

                  {false && (
                    <Button
                      onClick={() => {
                        window.open(invoice.paymentLink, "_self");
                      }}
                      disabled={
                        invoice.status === "paid" || !invoice.paymentLink
                      }
                    >
                      Mark as paid
                    </Button>
                  )}
                </Stack>

                <Typography sx={{ mt: 1 }}>
                  {invoice.name}
                  {invoice.userID && " (" + invoice.userID + ")"}
                </Typography>
                <Typography>
                  {invoice.car}
                  {invoice.carReg && " (" + invoice.carReg + ")"}
                </Typography>
              </Stack>
            </>
          )}
        </Collapse>
      </DialogContent>
      <DialogActions>
        {invoice && (
          <>
            <Button
              disabled={
                invoice.status !== "pending" ||
                loadingUser ||
                invoice.applicationId
              }
              disableElevation
              sx={{ width: 80, borderRadius: 32, textTransform: "none" }}
              onClick={() => {
                getUser();
              }}
            >
              {loadingUser && <CircularProgress sx={{ mr: 1 }} size={12} />}
              Edit
            </Button>
            <Button
              color="warning"
              disableElevation
              disabled={invoice.status === "paid" || loading}
              sx={{ width: 80, borderRadius: 32, textTransform: "none" }}
              onClick={() => {
                deleteInvoice();
              }}
            >
              Delete
            </Button>
          </>
        )}
        <Button
          color="inherit"
          sx={{ width: 80, textTransform: "none", borderRadius: 32 }}
          onClick={handleClose}
        >
          Close
        </Button>
      </DialogActions>
      {showInvoice && (
        <NewInvoice
          getInvoice={getInvoice}
          invoiceData={invoice}
          setOpen={() => {
            setShowInvoice(false);
          }}
          user={user}
          open={showInvoice}
        />
      )}
    </Dialog>
  );
}
