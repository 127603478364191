/* eslint-disable react-hooks/exhaustive-deps */
import * as React from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Divider from "@mui/material/Divider";
import Slide from "@mui/material/Slide";
import Collapse from "@mui/material/Collapse";
import UserProfile from "./profile";
import CarDetails from "./car";
import LinearProgress from "@mui/material/LinearProgress";
import UserDocuments from "./documents";
import { doc, getDoc, getFirestore, onSnapshot } from "firebase/firestore";
import UserNotes from "./notes";
import NewInvoice from "./invoice";
import { HiPlusCircle } from "react-icons/hi2";
// eslint-disable-next-line react-hooks/exhaustive-deps

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function ClientDialog({ open, setOpen, docId }) {
  const handleClose = () => {
    setOpen(false);
  };
  const db = getFirestore();

  const [loading, setLoading] = React.useState(false);

  const [showInvoice, setShowInvoice] = React.useState(false);

  const [user, setUser] = React.useState(null);

  const getUser = async () => {
    setUser(null);
    setLoading(true);
    const docRef = doc(db, "clients", docId);
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
      setUser(docSnap.data());
    } else {
      // doc.data() will be undefined in this case
      console.log("No such document!");
    }
    setLoading(false);
  };

  React.useEffect(() => {
    const unsub = onSnapshot(doc(db, "clients", docId), (doc) => {
      setUser(doc.data());
    });
    return unsub;
  }, []);

  React.useEffect(() => {
    getUser();
  }, []);

  return (
    <>
      <Dialog
        maxWidth="md"
        TransitionComponent={Transition}
        open={Boolean(open)}
        onClose={handleClose}
      >
        <Collapse in={Boolean(loading)}>
          <LinearProgress />
        </Collapse>
        <DialogTitle>Manage Client</DialogTitle>

        <DialogContent sx={{ minWidth: 350 }}>
          <Collapse in={Boolean(user)}>
            {user ? (
              <div>
                <UserProfile user={user} />
                <CarDetails user={user} />
                <UserDocuments user={user} />
                <UserNotes user={user} />
              </div>
            ) : (
              <div />
            )}
          </Collapse>
        </DialogContent>
        <DialogActions sx={{}}>
          <Button
            disabled={loading || !user}
            startIcon={<HiPlusCircle />}
            color="info"
            onClick={() => {
              setShowInvoice(true);
            }}
            sx={{ textTransform: "none" }}
          >
            New Invoice
          </Button>
          <Button
            color="warning"
            sx={{ width: 80, textTransform: "none" }}
            onClick={handleClose}
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
      {showInvoice && (
        <NewInvoice
          setOpen={() => {
            setShowInvoice(false);
          }}
          user={user}
          open={showInvoice}
        />
      )}
    </>
  );
}
