/* eslint-disable react-hooks/exhaustive-deps */
import Stack from "@mui/material/Stack";
import * as React from "react";
import { constants } from "../../../config/constants";
import SearchComponent from "./components/search";
import ClientType from "./components/filters";
import LinearProgress from "@mui/material/LinearProgress";
import Grow from "@mui/material/Grow";
import Snackbar from "@mui/material/Snackbar";
import Row from "./row";
import { filter } from "./filter";
import MailCompose from "../home/conversation/email/compose/view";
import { Typography } from "@mui/material";
import ClientDialog from "./modal/view";
function numberWithCommas(x) {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

function reducer(accumulator = 0, currentValue, index) {
  const returns = accumulator + currentValue.total;
  return returns;
}

const Payments = () => {
  const StatBox = ({ title, subtitle, status, count, bg, items }) => (
    <Stack
      sx={{
        mr: 2,
        flex: 1,
        borderRadius: 2,
        background: bg,
        p: 3,
        justifyContent: "space-between",
        alignItems: "center",
      }}
      direction="row"
    >
      <Stack sx={{}}>
        <h3 style={{ margin: 0 }}>
          <b>{title}</b>
        </h3>
        <p>{subtitle}</p>
      </Stack>

      <Stack sx={{}}>
        <Typography>{items.length}</Typography>
        <Typography sx={{}}>
          <b>R{numberWithCommas(items.reduce(reducer, 0).toFixed(2))}</b>
        </Typography>
      </Stack>
    </Stack>
  );
  const [loading, setLoading] = React.useState(false);

  const [invoices, setInvoices] = React.useState([]);

  const [showInvoice, setshowInvoice] = React.useState({
    show: false,
    _id: null,
  });

  const getInvoices = () => {
    setLoading(true);
    var requestOptions = {
      method: "GET",
      redirect: "follow",
    };

    fetch(`${constants.apiUrl}/invoices`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        setLoading(false);
        if (result) {
          let temp = [...result];
          temp.reverse();
          setInvoices(temp);
        }
      })
      .catch((error) => {
        setLoading(false);
        console.log("error", error);
      });
  };

  React.useEffect(() => {
    getInvoices();
  }, []);

  const [search, setSearch] = React.useState("");

  const [clientType, setClientType] = React.useState(0);

  const [composeOpen, setComposeOpen] = React.useState(false);

  const [openSnack, setOpenSnack] = React.useState(false);

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnack(false);
  };

  const closeMail = () => {
    setComposeOpen(false);
    setOpenSnack(true);
  };

  const checked = true;

  return (
    <Stack sx={{ p: 4, pt: 2, height: "100vh", overflow: "scroll" }}>
      <Grow
        in={checked}
        style={{ transformOrigin: "0 0 0" }}
        {...(checked ? { timeout: 1000 } : {})}
      >
        <Stack
          direction="row"
          sx={{
            boxShadow: "5px 5px 24px 1px rgba(0, 137, 254, 0.07)",
            p: 2,
            borderRadius: 2,
            // background: "rgba(0, 236, 255, 0.04)",
            justifyContent: "space-between",
            pt: 1,
            pb: 1,
            alignItems: "center",
            mt: 1,
          }}
        >
          <h1 style={{ margin: 0 }}>Revenue Center </h1>
          <SearchComponent setSearch={setSearch} />
          <ClientType
            getInvoices={getInvoices}
            setClientType={setClientType}
            clientType={clientType}
          />
        </Stack>
      </Grow>
      <Stack direction="row" sx={{ mt: 2, mb: 1 }}>
        <StatBox
          bg="rgb(217, 240, 246)"
          title="Pending"
          subtitle="Invoices"
          items={invoices.filter((a) => {
            return a.status === "pending";
          })}
        />
        <StatBox
          bg="#FEE5D2"
          title="Paid"
          subtitle="Invoices"
          items={invoices.filter((a) => {
            return a.status === "paid";
          })}
        />

        <StatBox
          bg="#ECEFF6"
          title="Overdue"
          subtitle="Invoices"
          status="signed"
          items={invoices.filter((a) => {
            return new Date(a.dueDate) < new Date() && a.status === "pending";
          })}
        />
      </Stack>

      {loading && [].length === 0 && <LinearProgress />}

      <Stack
        spacing={3}
        direction="row"
        sx={{
          boxShadow: "5px 5px 24px 1px rgba(0, 0, 0, 0.04)",
          mt: 2,
          p: 2,
        }}
      >
        <Typography sx={{ flex: 1, fontWeight: "bold" }}>Invoice #</Typography>
        <Typography sx={{ flex: 1, fontWeight: "bold" }}>Client</Typography>
        <Typography sx={{ flex: 1, fontWeight: "bold" }}>Car</Typography>
        <Typography sx={{ flex: 1, fontWeight: "bold" }}>Amount</Typography>
        <Typography sx={{ flex: 1, fontWeight: "bold" }}>Due Date</Typography>
        <Typography sx={{ flex: 1, fontWeight: "bold" }}>Status</Typography>
        <Typography sx={{ flex: 1, fontWeight: "bold", textAlign: "center" }}>
          Action
        </Typography>
      </Stack>

      <Stack sx={{ mt: 2, pb: 4 }}>
        {invoices
          .filter((a) => filter(a, search.toLowerCase()))
          .map((item) => (
            <Row
              key={item._id}
              showInvoice={(_id) => {
                setshowInvoice({ show: true, _id });
              }}
              item={item}
            />
          ))}
      </Stack>
      {composeOpen && (
        <MailCompose
          closeMail={closeMail}
          open={composeOpen}
          setOpen={setComposeOpen}
        />
      )}
      <Snackbar
        open={openSnack}
        autoHideDuration={6000}
        onClose={handleClose}
        message="Email Sent"
      />

      {showInvoice._id && (
        <ClientDialog
          getInvoices={getInvoices}
          open={showInvoice.show}
          setOpen={() => {
            setshowInvoice({ show: false, _id: null });
          }}
          _id={showInvoice._id}
        />
      )}
    </Stack>
  );
};

export default Payments;
