import React from "react";
import Dialog from "@mui/material/Dialog";
import Stack from "@mui/material/Stack";
import FileViewer from "react-file-viewer";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import CloseIcon from "@mui/icons-material/Close";
import Slide from "@mui/material/Slide";
import { BsDownload } from "react-icons/bs";
import { constants } from "../../../../../config/constants";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const DocViewer = ({
  open,
  setOpen,
  id,
  fileType,
  filename,
  url,
  firestoreLink = null,
}) => {
  return (
    <Dialog
      open={open}
      fullScreen
      TransitionComponent={Transition}
      onClose={() => setOpen(false)}
    >
      <AppBar sx={{ position: "relative" }}>
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            onClick={() => setOpen(false)}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
          <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
            {filename}
          </Typography>
          <IconButton
            onClick={() => {
              fetch(url).then((response) => {
                response.blob().then((blob) => {
                  const fileURL = window.URL.createObjectURL(blob);
                  let alink = document.createElement("a");
                  alink.href = fileURL;
                  alink.download = filename;
                  alink.click();
                });
              });
            }}
          >
            <BsDownload />
          </IconButton>
        </Toolbar>
      </AppBar>
      <Stack sx={{ height: "100%", width: "100%" }}>
        {firestoreLink ? (
          <iframe
            width="100%"
            title="Document"
            height="100%"
            src={firestoreLink}
          ></iframe>
        ) : (
          <FileViewer
            style={{ height: "70vh", width: "750px" }}
            fileType={fileType}
            filePath={url}
            errorComponent={<p>An error occurred</p>}
          />
        )}
      </Stack>
    </Dialog>
  );
};

export default DocViewer;
