const filter = (data, search) => {
  if (search.toString().trim() === "") {
    return true;
  }
  if (
    data.firstName.toLowerCase().indexOf(search.trim()) !== -1 ||
    data.email.toLowerCase().indexOf(search.trim()) !== -1 ||
    data.lastName.toLowerCase().indexOf(search.trim()) !== -1
  ) {
    return true;
  }

  return false;
};

export { filter };
