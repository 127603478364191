/* eslint-disable react-hooks/exhaustive-deps */
import * as React from "react";
import Stack from "@mui/material/Stack";
import Grow from "@mui/material/Grow";
import { LinearProgress } from "@mui/material";
import { useSelector } from "react-redux";
import { constants } from "../../../../../config/constants";

function rep(text = "") {
  // Put the URL to variable $1 after visiting the URL
  var Rexp =
    /((http|https|ftp):\/\/[\w?=&.\/-;#~%-]+(?![\w\s?&.\/;#~%"=-]*>))/g;

  // Replace the RegExp content by HTML element
  return text.replace(
    Rexp,
    "<a style='color:blue;text-decoration-line:underline' href='$1' target='_blank'>'$1'</a>"
  );
}

export default function FacebookPost() {
  const checked = true;

  const conversation = useSelector((state) => state.conversation);

  const [loading, setLoading] = React.useState(true);
  const [post, setPost] = React.useState(null);

  const getPost = async () => {
    setLoading(true);
    var requestOptions = {
      method: "GET",
      redirect: "follow",
    };

    fetch(
      `${constants.apiUrl}/facebook/post/${conversation.post_id}`,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        setPost(result);
        console.log(post);
        setLoading(false);
      })
      .catch((error) => {
        console.log("error", error);
        setLoading(false);
      });
  };

  React.useEffect(() => {
    getPost();
  }, [conversation]);

  return (
    <Grow
      in={checked}
      style={{ transformOrigin: "0 0 0" }}
      {...(checked ? { timeout: 1000 } : {})}
    >
      <Stack
        sx={{
          width: "280px",
          height: "100vh",
          mr: 1,
          mt: 1,
          background: "white",
          p: 1,
          borderRadius: 2,
          overflow: "scroll",
          pb: 4,
        }}
      >
        {loading && <LinearProgress />}

        {post && (
          <Stack sx={{ p: 2 }}>
            <h4 style={{ margin: 0, marginBottom: 16 }}>Post Details</h4>

            {post.attachments && post.attachments.data.length > 0 && (
              <>
                {post.attachments.data[0].media &&
                  post.attachments.data[0].media.image && (
                    <img
                      style={{ width: "250px", borderRadius: "16px" }}
                      alt=""
                      src={post.attachments.data[0].media.image.src}
                    />
                  )}
                <Stack
                  sx={{
                    marginTop: 3,
                    p: 2,
                    borderRadius: 2,
                    background: "rgba(194, 220, 255, 0.3)",
                  }}
                >
                  <div
                    style={{}}
                    dangerouslySetInnerHTML={{
                      __html: rep(post.attachments.data[0].description),
                    }}
                  ></div>
                </Stack>
              </>
            )}
          </Stack>
        )}
      </Stack>
    </Grow>
  );
}
