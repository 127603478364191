/* eslint-disable react-hooks/exhaustive-deps */
import Stack from "@mui/material/Stack";
import * as React from "react";
import { useDispatch, useSelector } from "react-redux";
import { constants } from "../../../config/constants";
import { bulkAddContacts } from "../../../data/slices/contacts";
import { IoMail } from "react-icons/io5";
import { IoLogoWhatsapp } from "react-icons/io";
import Grid from "@mui/material/Grid";
import Chip from "@mui/material/Chip";
import SearchComponent from "./components/search";
import ClientType from "./components/filters";
import { filter } from "./filter";
import LinearProgress from "@mui/material/LinearProgress";
import Grow from "@mui/material/Grow";
import Snackbar from "@mui/material/Snackbar";

import { updateCompose } from "../../../data/slices/compose";
import MailCompose from "../home/conversation/email/compose/view";
import { Typography } from "@mui/material";

const Contacts = () => {
  const contacts = useSelector((state) => state.contacts);

  const dispatch = useDispatch();
  const [loading, setLoading] = React.useState(false);

  React.useEffect(() => {
    setLoading(true);
    var requestOptions = {
      method: "GET",
      redirect: "follow",
    };

    fetch(`${constants.apiUrl}/contacts`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        setLoading(false);
        console.log('contacts',result)
        result && result.length > 0 && dispatch(bulkAddContacts(result));
      })
      .catch((error) => {
        setLoading(false);
        console.log("error", error);
      });
  }, []);

  const [search, setSearch] = React.useState("");

  const [clientType, setClientType] = React.useState(0);

  const [composeOpen, setComposeOpen] = React.useState(false);

  const [openSnack, setOpenSnack] = React.useState(false);

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnack(false);
  };

  const closeMail = () => {
    setComposeOpen(false);
    setOpenSnack(true);
  };

  const checked = true;

  const filteredContacts = contacts
  .filter((a) => {
    if (clientType === 0) {
      return true; // Show all contacts
    }
    if (clientType === 1 && a.Account_type === "Driver") {
      return true; // Show only drivers
    }
    if (clientType === 2 && a.Account_type === "Rider") {
      return true; // Show only riders
    }
    return false;
  })
  .filter((a) => filter(a, search.toLowerCase())); // Apply search filter

  return (
    <Stack sx={{ p: 4, pt: 2 }}>
      <Grow
        in={checked}
        style={{ transformOrigin: "0 0 0" }}
        {...(checked ? { timeout: 1000 } : {})}
      >
        <Stack
          direction="row"
          sx={{
            boxShadow: "5px 5px 24px 1px rgba(0, 137, 254, 0.07)",
            p: 2,
            borderRadius: 2,
            justifyContent: "space-between",
            pt: 1,
            pb: 1,
            alignItems: "center",
          }}
        >
          <h1 style={{ margin: 0 }}>
            Contacts{" "}
            <span style={{ color: "#004449" }}>
              {" (" + filteredContacts.length + ")"}
            </span>
          </h1>
          <SearchComponent setSearch={setSearch} />
          <ClientType setClientType={setClientType} clientType={clientType} />
        </Stack>
      </Grow>
  
      {loading && filteredContacts.length === 0 && <LinearProgress />}
  
      <Stack sx={{ height: "90vh", overflow: "scroll", mt: 2 }}>
        <Grid container columns={{ xs: 12, sm: 12, md: 12 }}>
          {filteredContacts.map((contact, index) => (
            <Grid item sx={{ p: 1.5 }} xs={12} sm={4} md={4} key={index}>
              <Grow
                in={checked}
                style={{ transformOrigin: "0 0 0" }}
                {...(checked ? { timeout: 1000 } : {})}
              >
                <Stack
                  sx={{
                    boxShadow: "5px 5px 24px 1px rgba(0, 137, 254, 0.07)",
                    p: 3,
                    borderRadius: 2,
                    flex: 1,
                    mb: 2,
                    mr: 1,
                  }}
                >
                  <Stack sx={{ flex: 1, mb: 1 }}>
                    <h4 style={{ margin: 0 }}>{contact.Name}</h4>
                  </Stack>
                  <Stack
                    direction="row"
                    sx={{ mt: 0.5, alignItems: "center" }}
                  >
                    <IoLogoWhatsapp
                      size={18}
                      color="#25D366"
                      style={{ marginRight: 8 }}
                    />{" "}
                    <p>{contact.user_id}</p>
                  </Stack>
                  <Stack
                    direction="row"
                    sx={{ mt: 0.5, alignItems: "center" }}
                  >
                    <IoMail
                      size={16}
                      color="#00808B"
                      style={{ marginRight: 8 }}
                    />{" "}
                    <Typography
                      onClick={() => {
                        setComposeOpen(true);
                        dispatch(
                          updateCompose({
                            to: [contact.Email],
                          })
                        );
                      }}
                      style={{
                        "word-break": "break-all",
                        cursor: "pointer",
                      }}
                      sx={{
                        "&: hover": {
                          textDecorationLine: "underline",
                        },
                      }}
                    >
                      {contact.Email}
                    </Typography>
                  </Stack>
                  <Stack sx={{}}>
                    <Chip
                      label={contact.Account_type}
                      sx={{ alignSelf: "flex-start", mt: 0.5 }}
                    />
                  </Stack>
                </Stack>
              </Grow>
            </Grid>
          ))}
        </Grid>
      </Stack>
  
      {composeOpen && (
        <MailCompose
          closeMail={closeMail}
          open={composeOpen}
          setOpen={setComposeOpen}
        />
      )}
  
      <Snackbar
        open={openSnack}
        autoHideDuration={6000}
        onClose={handleClose}
        message="Email Sent"
      />
    </Stack>
  );
  
};

export default Contacts;
