import React, { useState, useEffect } from "react";
import { MapContainer, TileLayer, Marker, Popup, CircleMarker } from "react-leaflet";
import L from "leaflet";
import "leaflet/dist/leaflet.css";
import "@turf/turf"; // Import Turf.js
import * as turf from "@turf/turf"; // Import Turf.js

// Import constants and other components as needed
import { constants } from "../../../config/constants";

// Custom icon for cars
const carIcon = new L.Icon({
  iconUrl: "https://app.swiftly.africa/images/car_icon_0.png", // Replace with your car icon URL
  iconSize: [40, 40],
});

// Custom icon for clusters
const clusterIcon = (count) => {
  return L.divIcon({
    html: `<div style="
      background-color: #4169E1;
      color: white;
      border-radius: 50%;
      width: 40px;
      height: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 14px;
      border: 2px solid white;
    ">${count}</div>`,
    className: "",
    iconSize: [40, 40],
    iconAnchor: [20, 20],
  });
};

const Maps = () => {
  const [carLocations, setCarLocations] = useState([]);
  const [clusters, setClusters] = useState([]);
  const [loading, setLoading] = useState(true);

  const getCarLocations = () => {
    setLoading(true);

    fetch(`${constants.apiUrl}/car-locations`) // Replace with your car locations API
      .then((response) => response.json())
      .then((result) => {
        const parsedLocations = result.map((car) => {
          const latestLocation = car.locations[0]; // Assuming the first location is the latest
          const { coordinates } = latestLocation.coords;
          const heading = latestLocation.additionalData?.heading || 0;

          return {
            Name: car.Name,
            car_make_and_model: car.car_make_and_model,
            user_id: car.user_id,
            latitude: coordinates[1],
            longitude: coordinates[0],
            heading: heading,
          };
        });
        setCarLocations(parsedLocations); // Set parsed locations
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching car locations:", error);
        setLoading(false);
      });
  };

  useEffect(() => {
    getCarLocations();
  }, []);

  useEffect(() => {
    if (carLocations.length === 0) {
      setClusters([]);
      return;
    }

    // Convert car locations to Turf.js GeoJSON features
    const points = carLocations.map((car) =>
      turf.point([car.longitude, car.latitude], { ...car })
    );

    const featureCollection = turf.featureCollection(points);

    // Perform clustering using Turf.js
    // We'll use the 'clusters' approach by iterating through points and grouping them
    // within 50km radius. Turf.js does not have a direct clustering function based on distance,
    // so we'll implement a simple clustering mechanism.

    const clusters = [];
    const visited = new Set();

    points.forEach((point, idx) => {
      if (visited.has(idx)) return;

      const cluster = [point];
      visited.add(idx);

      points.forEach((p, i) => {
        if (i === idx || visited.has(i)) return;
        const distance = turf.distance(point, p, { units: "kilometers" });
        if (distance <=100) {
          cluster.push(p);
          visited.add(i);
        }
      });

      clusters.push(cluster);
    });

    // Prepare cluster data
    const preparedClusters = clusters.map((cluster) => {
      if (cluster.length === 1) {
        const { latitude, longitude, Name, car_make_and_model, user_id, heading } = cluster[0].properties;
        return {
          type: "single",
          latitude,
          longitude,
          data: cluster[0].properties,
        };
      } else {
        // Calculate the centroid of the cluster
        const centroid = turf.centroid(turf.featureCollection(cluster));
        return {
          type: "cluster",
          latitude: centroid.geometry.coordinates[1],
          longitude: centroid.geometry.coordinates[0],
          count: cluster.length,
        };
      }
    });

    setClusters(preparedClusters);
  }, [carLocations]);

  // Estimated zoom level to fit a 1000 km radius
  const zoomLevel = 6;

  return (
    <div style={{ height: "100vh", width: "100%", position: "relative" }}>
      {/* Car Count in Top Right Corner */}
      <div style={styles.carCount}>
        <p>Total Cars: {carLocations.length}</p>
      </div>

      {loading ? (
        <p>Loading map...</p>
      ) : (
        <MapContainer center={[-29.087217, 26.154898]} zoom={zoomLevel} style={{ height: "100%", width: "100%" }}>
          <TileLayer
            url="https://{s}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}{r}.png"
            attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors &copy; <a href="https://carto.com/attributions">CARTO</a>'
          />

          {clusters.map((cluster, index) => {
            if (cluster.type === "single") {
              const { latitude, longitude, Name, car_make_and_model, user_id, heading } = cluster.data;
              return (
                <Marker
                  key={`car-${index}`}
                  position={[latitude, longitude]}
                  icon={new L.Icon({
                    iconUrl: "https://app.swiftly.africa/images/car_icon_0.png",
                    iconSize: [40, 40],
                  })}
                  rotationAngle={heading} // Apply rotation based on heading
                  rotationOrigin="center"
                >
                  <Popup>
                    <strong>Driver ID:</strong> {user_id} <br />
                    <strong>Driver Name:</strong> {Name} <br />
                    <strong>Car Details:</strong> {car_make_and_model} <br />
                  </Popup>
                </Marker>
              );
            } else {
              // Render cluster marker
              return (
                <Marker
                  key={`cluster-${index}`}
                  position={[cluster.latitude, cluster.longitude]}
                  icon={clusterIcon(cluster.count)}
                >
                  <Popup>
                    <strong>Total Cars:</strong> {cluster.count}
                  </Popup>
                </Marker>
              );
            }
          })}
        </MapContainer>
      )}
    </div>
  );
};

const styles = {
  carCount: {
    position: "absolute",
    top: "10px",
    right: "10px",
    backgroundColor: "rgba(255, 255, 255, 0.8)",
    padding: "10px",
    fontWeight: 600,
    borderRadius: "8px",
    boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
    zIndex: 1000, // Ensure it stays above the map
  },
};

export default Maps;
